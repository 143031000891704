import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HotToastService } from '@ngneat/hot-toast';
import { ApiService } from '../../shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PasswordMatchValidator } from './password-match.validator';
import { PasswordValidator } from '../password-validator';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  isShowresetPassword:boolean = false;
  resetPasswordForm!: FormGroup;
  passwordVisible: boolean = false;
  confirmPasswordVisible: boolean = false;
  userId:any;
  showTooltip = false;
  showConfirmPasswordTooltip = false;
  isMinLengthSatisfied: boolean = false;
  hasUppercase: boolean = false;
  hasLowercase: boolean = false;
  hasNumber: boolean = false;
  hasSpecialChar: boolean = false;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private toast: HotToastService,
    private router:Router,
    private apiService: ApiService,
    private authService: AuthService

  ) {
      if(authService.isAuthenticated()) {
        this.authService.redirectedToUserPages()
    }
  }

  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group({
      newPassword: ['', [Validators.required, PasswordValidator.strongPassword()]],
      confirmPassword: ['', [Validators.required, PasswordValidator.strongPassword()]],
    }, {
      validators: PasswordMatchValidator() // Apply the custom validator here
    });
    this.route.queryParams.subscribe(params => {
      const token = params['token'];
      if (token) {
        this.verifyToken(token);
      } else {
        this.router.navigate(['/login'])
      }
    });
  }

  verifyToken(token: string): void {
    const token_data={
      'token':token,
      'is_password_reset' : true
    }
    this.apiService.verifyEmail(token_data).subscribe(response=>{
      if (!response['error']) {
        this.isShowresetPassword = true;
        this.userId = response.user
        this.toastr.success('Token Verification Success');
        
      }
      else{
        this.toastr.error('Token Verification Failed')
        this.router.navigate(['/login'])
      }
    })
  }

  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible;
  }

  toggleConfirmPasswordVisibility(): void {
    this.confirmPasswordVisible = !this.confirmPasswordVisible;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!event.target || !(event.target as HTMLElement).closest('.password-tooltip, .password-input')) {
      this.showTooltip = false;
      this.showConfirmPasswordTooltip = false;
    }
  }

  checkPassword(key:any) {
    const password = this.resetPasswordForm.get(key)?.value || '';
    if(key == 'newPassword') {
      this.showTooltip = password.length > 0;
    } else {
      this.showConfirmPasswordTooltip = password.length > 0;
    }

    this.isMinLengthSatisfied = password.length >= 8;
    this.hasUppercase = /[A-Z]/.test(password);
    this.hasLowercase = /[a-z]/.test(password);
    this.hasNumber = /[0-9]/.test(password);
    this.hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (this.isMinLengthSatisfied && this.hasUppercase && this.hasLowercase && this.hasNumber && this.hasSpecialChar) {
      if(key == 'newPassword') {
        this.showTooltip = false;
      } else {
        this.showConfirmPasswordTooltip = false;
      }
    }
  }

  onSubmit() {
    if (this.resetPasswordForm.valid) {
      let data = {
        user : this.userId,
        password : this.resetPasswordForm.get('newPassword')?.value
      }
      console.log(data)
      const toastRef = this.toast.loading('Updating Your Password...');
      this.apiService.resetPassword(data).subscribe(response=>{
        if (!response['error']) {
          this.isShowresetPassword = false;
          this.router.navigate(['/login'])
          this.toastr.success('Password Reset Successfully');
          
        }
        else{
          this.isShowresetPassword = true;
          this.toastr.error('User not found')
        }
      })
    }
  }

}

import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { response } from 'express';

@Injectable({
  providedIn: 'root',
})
export class DataService  {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  format_response(response:any) {
    return response;
  }

  getAll(url:any, headers = {}) {
    return this.httpClient.get(url, {headers: headers}).pipe(
        map(response => this.format_response(response)),
        catchError(this.handleError));
  }

  get(url:any, params:any, headers = {}) {
      return this.httpClient.get(url, {params, headers: headers}).pipe(
        map(response => this.format_response(response)),
        catchError(this.handleError));
  }

  create(url:any, resource:any, headers = {}) {
    return this.httpClient.post(url, resource, {headers: headers}).pipe(
      map(response => this.format_response(response)
      ),
      catchError(this.handleError));
  }

  update(url:any, resource:any, headers = {}) {
    return this.httpClient.put(url, resource, {headers: headers}).pipe(
      map(response => this.format_response(response)),
      catchError(this.handleError));
  }

  delete(url:any, headers = {},params = {}) {
    return this.httpClient.delete(url, {headers: headers, params: params }).pipe(
      map(response => this.format_response(response)),
      catchError(this.handleError));
  }

  private handleError(error: Response) {
    return Promise.resolve(error);
  }
}

import { Component, OnInit, TemplateRef, ViewChild, HostListener } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/shared/services/api.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

declare var Razorpay: any;

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.scss']
})
export class PropertiesComponent implements OnInit{

  properties_list:any[]=[];
  isImagesLoading:boolean=false;
  property_images:any[]=[];
  is_kyc_completed:boolean=false
  numShares: any = 0;
  investmentValue: number = 0;
  selectedPropertyId: string = '';
  selectedProperty:any;
  remainingShares: number = 0;
  isSoldOut: boolean = false;
  isActive: boolean = false;
  private inactivityTimeout: any;

  @ViewChild('investModal') investModal!: TemplateRef<any>;
  constructor(
    private modalService: NgbModal,
    private apiService:ApiService,
    private router:Router,
    private auth: AuthService,
    private toastr:ToastrService,
    private titleService: Title,
    private metaService: Meta

  ){}


  ngOnInit(): void {
    this.updateMetaTags();
    this.startInactivityTimer();
    // Fetch and render properties first
    this.apiService.ListAllProperties().subscribe(response => {
      this.properties_list = response


      // Set loading state to true for images
      this.isImagesLoading = true;
      // Fetch property images separately
      this.apiService.GetAllPropertyImages(false).subscribe(property_images => {
        this.property_images = property_images; // Fetch all images

        // Map images to the appropriate property
        this.properties_list = this.mapImagesToProperties(this.properties_list, this.property_images);

        // Set loading state to false once images are mapped
        this.isImagesLoading = false;
      }, error => {
        console.error('Error fetching property images:', error);
        this.isImagesLoading = false; // Ensure loading state is reset even on error
      });
    });
  }

  private updateMetaTags(): void {
    // Page Title
    this.titleService.setTitle('Explore Fractional Real Estate Investment Premium Properties');

    // Remove any existing meta tags
    this.metaService.removeTag('name="description"');
    this.metaService.removeTag('property="og:title"');
    this.metaService.removeTag('property="og:description"');
    this.metaService.removeTag('property="og:url"');
    this.metaService.removeTag('property="og:type"');
    
    

    // Basic Meta Tags
    this.metaService.addTags([
      {
        name: 'description',
        content: 'Browse A Curated Selection Of Online Fractional Real Estate Investing Properties. Diversify Your Portfolio With Real Estate Option Designed For Smart Investors.'
      },
      {
        name: 'keywords',
        content: 'real estate investment, fractional ownership, property investment, real estate properties, 22Yards, investment opportunities'
      }
    ]);

    // Open Graph Tags
    this.metaService.addTags([
      {
        property: 'og:title',
        content: 'Explore Fractional Real Estate Investment Premium Properties'

      },
      {
        property: 'og:description',
        content: 'Browse A Curated Selection Of Online Fractional Real Estate Investing Properties. Diversify Your Portfolio With Real Estate Option Designed For Smart Investors.'
      },
      {
        property: 'og:url',
        content: 'https://22yardsprop.com/properties'
      }
     
     
    ]);
    
    // Canonical URL
    this.metaService.updateTag({
      rel: 'canonical',
      href: 'https://22yardsprop.com/properties'
    });
  }
 
  @HostListener('document:mousemove', ['$event'])
    onMouseMove(): void {
      this.showIcons();
    }
  
    private showIcons(): void {
      this.isActive = true;
      this.resetInactivityTimer();
    }
  
    private startInactivityTimer(): void {
      this.inactivityTimeout = setTimeout(() => {
        this.isActive = false;
      }, 3000); // Adjust inactivity duration here (in milliseconds)
    }
  
    private resetInactivityTimer(): void {
      clearTimeout(this.inactivityTimeout);
      this.startInactivityTimer();
    }

  // Function to map images to properties
  mapImagesToProperties(properties: any[], images: any[]): any[] {
    return properties.map(property => {
      // Find the corresponding images object based on property_id
      const matchingImages = images.find(img => img.property_id === property.property_id);
      
      // Return a new object with the images mapped
      return {
        ...property, // Spread the original property to retain its data
        images: matchingImages ? matchingImages.property_images : []
      };
    });
  }

  // Function to get property images by specific name
  getPropertyImages(property: any) {
    return property.images.filter((image: any) => image.name === 'propertyImages');
  }

  routeToInvestment(property_id:any) {
    if(this.auth.isAuthenticated()){
      this.apiService.getVerificationStatus( this.auth.getData().user_id).subscribe(response => {
        this.is_kyc_completed = response['is_kyc_completed']
        if(this.is_kyc_completed){
          // Open the modal using Bootstrap's JavaScript API
            this.modalService.open(this.investModal, { centered: true });
          }
          else{
            this.router.navigate(['kyc'], {
              queryParams: { property: property_id }
            });          
          }
      });
    } else  {
      localStorage.setItem('property',property_id)
      this.router.navigate(['login']);    }
  }

  closeModal(){
    this.modalService.dismissAll()
  }

  onSubmitInvestmentForm(event: Event) {
    event.preventDefault();
    if(this.numShares == 0) {
      this.toastr.error('Buy Atleast  1 share');
      return
    }
    // this.apiService.createPaymentOrder(this.investmentValue, this.auth.getData().user_id,this.selectedPropertyId).subscribe(
    //   (order) => {
    //     if(!order.error) {
    //       this.launchRazorpay(order.id, order.amount);
    //       this.modalService.dismissAll();
    //     } else {
    //       this.toastr.error('Something went wrong.Please try again later');
    //     }
        

    //   },
    //   (error) => {
    //     console.error('Payment order creation failed:', error);
    //   }
    // );
    // // Calculate the investment value (implement your logic here)
    // console.log('Investment confirmed for property ID:', this.selectedPropertyId);
    // console.log('Number of shares:', this.numShares);
    // console.log('Investment value:', this.investmentValue);
    // // Close the modal after submission
  }

  launchRazorpay(orderId: string, amount: number) {
    const options = {
      key: environment.RAZORPAY_KEY_ID, // Replace with your Razorpay Key ID
      amount: amount * 100, // in paise
      currency: 'INR',
      name: '22Yards',
      description: 'Investment on property '+this.selectedProperty.name,
      order_id: orderId,
      handler: (response: { razorpay_payment_id: any; }) => {
        this.onPaymentConfirmation(response.razorpay_payment_id);
      },
      prefill: {
        name: 'User Name',
        email: 'user@example.com',
        contact: '9999999999'
      },
      notes: {
        property_id: this.selectedPropertyId,
        user_id: this.auth.getData().user_id, 
        shares: this.numShares.toString(),
      },
      theme: {
        color: '#3399cc'
      }
    };

    const rzp = new Razorpay(options);
    rzp.open();
  }

  onPaymentConfirmation(paymentId: string) {
    this.apiService.verifyPayment(paymentId).subscribe(response => {
      if (response.status === 'success') {
        this.toastr.success('Payment Success')
        this.router.navigate(['dashboard'])
      } else {
        this.toastr.error('Payment Failure')
        this.router.navigate(['properties'])
      }
    });
  }

  onInvestNowClick(event: MouseEvent, property: any): void {
    event.stopPropagation(); // Prevent the event from bubbling up
    this.selectedPropertyId = property.property_id;
    this.selectedProperty = property;
    this.remainingShares = this.selectedProperty.remaining_shares;
    this.isSoldOut = this.selectedProperty.is_sold_out;
    this.routeToInvestment(property.property_id);
}

  routeToDetails(propertyId:any) {
    this.router.navigate(['property', propertyId]);
  }

  calculateInvestmentValue(): void {
    this.investmentValue = this.numShares * this.selectedProperty.value_of_each_share;
  }

  onSharesChange(): void {
    // Ensure numShares does not exceed remainingShares
    if (this.numShares > this.remainingShares) {
      this.numShares = this.remainingShares;
      // Optionally, show a message to the user
      alert('You cannot invest more shares than are available.');
    } else {
      this.calculateInvestmentValue();
    }
  }

}

import { Component, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent{
  
  visionMissionCards = [
    {
      
      title: 'Our Vision',
      description: 'To transform the real estate industry by offering innovative investment opportunities where investor can co-own a property and delivering exceptional value to all stakeholders. Our focus is on hotels, , resorts, service apartments and renewable energy projects, all designed to diversify investment opportunities and maximize the utility of every piece of land we touch',
      color: '#4A90E2'
    },
    {
      
      title: 'Our Mission',
      description: 'To revolutionize fractional ownership market in real estate and renewable energy investments by empowering individuals from all walks of life to participate, fostering financial inclusion, and driving sustainable growth for a brighter future.',
      color: '#50C878'
    },
    {
      
      title: 'Our Approach',
      description: 'We partner with property managers to ensure smooth operations and quality service, benefiting landowners, investors, managers, and communities with improved value, income, and infrastructure.',
      color: '#FF6B6B'
    }
  ];
  


  arrow = 'assets/images/arrow.png';  // Path to the arrow image
  marketTwoIllustration = 'assets/images/market-two-illustration.png';  // Path to the market illustration
  
  constructor(
    private titleService: Title,
    private metaService: Meta)
 {
    this.visionSection = {} as ElementRef;
    this.significanceSection = {} as ElementRef;
     this.ourStorySection = {} as ElementRef;
     this.teamSection = {} as ElementRef;
     

   }

   ngOnInit() {
    this.updateMetaTags();
  }

  private updateMetaTags() {
    // Page Title
    const pageTitle = 'About Us | Leading Fractional Real Estate Investment Platform';
    this.titleService.setTitle(pageTitle);

    // Remove existing meta tags
    this.metaService.removeTag('name="description"');
    this.metaService.removeTag('property="og:title"');
    this.metaService.removeTag('property="og:description"');
    this.metaService.removeTag('property="og:url"');

    // Update meta tags
    this.metaService.addTags([
      // Basic Meta Tags
      { 
        name: 'description', 
        content: 'Learn About Our Mission, Values, And Expertise In Fractional Real Estate Investment. Discover How We Make Property Investing And Accessible For Everyone.' 
      },
      
      // Open Graph Tags
      { 
        property: 'og:title', 
        content: pageTitle 
      },
      { 
        property: 'og:description', 
        content: 'Learn About Our Mission, Values, And Expertise In Fractional Real Estate Investment. Discover How We Make Property Investing And Accessible For Everyone.' 
      },
      { 
        property: 'og:url', 
        content: 'https://22yardsprop.com/about-us' 
      }
      
    
    ]);

    // Canonical URL
    this.metaService.updateTag({
      rel: 'canonical',
      href: 'https://22yardsprop.com/about-us'
    });
  }

  @ViewChild('visionSection') visionSection: ElementRef;
  @ViewChild('significanceSection') significanceSection: ElementRef;  
  @ViewChild('ourStorySection') ourStorySection: ElementRef;
  @ViewChild('teamSection') teamSection: ElementRef;

  scrollToVision() {
    this.visionSection.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  scrollToSignificance() {
    this.significanceSection.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
  
  scrollToOurStory() {
    this.ourStorySection.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  scrollToTeam() {
    this.teamSection.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
  
}
  

  


<app-banner 
  [title]="'Cookie Policy'"
  [link]="'/cookie-policy'"
  [item]="'Cookie Policy'">
</app-banner>

 
 
<section class="section__space">
    <div class="container">
      <div class="terms-area">
        <div class="terms__single">
          <h3 class="neutral-top">
            1. Introduction
          </h3>
          <p>
            At 22 Yards, we are committed to ensuring transparency about the use of cookies and other tracking technologies on our platforms, including our website, mobile applications, and other services. This Cookie Policy explains what cookies are, how we use them, and your choices regarding their use.
          </p>
        </div>
        <hr />
        <div class="terms__single">
          <h3 class="neutral-top">
            2. What Are Cookies?
          </h3>
          <p>
            Cookies are small text files stored on your device (computer, tablet, or smartphone) by a website or mobile application. They enable the platform to recognize your device, remember your preferences, and improve your browsing experience.
          </p>
        </div>
        <hr />
        <div class="terms__single">
          <h3 class="neutral-top">
            3. How We Use Cookies
          </h3>
          <p>
            We use cookies for a variety of purposes, including:
          </p>
          <ul>
            <li>To ensure the proper functioning of our website and mobile applications.</li>
            <li>To enhance your user experience by remembering your preferences.</li>
            <li>To analyze usage patterns and improve the performance and content of our services.</li>
            <li>To deliver personalized content and targeted advertisements.</li>
          </ul>
        </div>
        <hr />
        <div class="terms__single">
          <h3 class="neutral-top">
            4. Types of Cookies We Use
          </h3>
          <h5>4.1 Essential Cookies</h5>
          <p>
            These cookies are necessary for our website and applications to function correctly. Without these cookies, certain features may not be available.
          </p>
          <h5 style="padding-top: 20px;">4.2 Performance and Analytics Cookies</h5>
          <p>
            These cookies collect information about how users interact with our platforms, such as the pages visited and time spent on the site. This helps us improve the performance and usability of our services.
          </p>
          <h5 style="padding-top: 20px;">4.3 Functional Cookies</h5>
          <p>
            Functional cookies allow us to remember your preferences and choices, such as language settings, to provide a more personalized experience.
          </p>
          <h5 style="padding-top: 20px;">4.4 Advertising Cookies</h5>
          <p>
            Advertising cookies are used to deliver relevant ads based on your interests and browsing behavior. They may also limit the number of times you see an ad and help measure the effectiveness of advertising campaigns.
          </p>
        </div>
        <hr />
        <div class="terms__single">
          <h3 class="neutral-top">
            5. Managing Cookies
          </h3>
          <p>
            You can control and manage cookies through your browser settings. Most browsers allow you to:
          </p>
          <ul>
            <li>View what cookies are stored on your device.</li>
            <li>Delete all or specific cookies.</li>
            <li>Block cookies from certain websites.</li>
            <li>Block third-party cookies.</li>
            <li>Enable notifications before a cookie is stored.</li>
          </ul>
          <p>
            Please note that disabling cookies may impact the functionality of our services and your user experience.
          </p>
        </div>
        <hr />
        <div class="terms__single">
          <h3 class="neutral-top">
            6. Third-Party Cookies
          </h3>
          <p>
            We may allow third-party service providers, such as analytics or advertising partners, to place cookies on your device. These cookies help us analyze website usage and deliver targeted advertisements. We do not control these cookies, and their use is subject to the privacy policies of the third parties.
          </p>
        </div>
        <hr />
        <div class="terms__single">
          <h3 class="neutral-top">
            7. Updates to This Cookie Policy
          </h3>
          <p>
            We may update this Cookie Policy from time to time to reflect changes in technology, legal requirements, or our practices. We will notify you of significant updates by posting the revised policy on our website with an updated effective date.
          </p>
        </div>
      </div>
    </div>
  </section>
  

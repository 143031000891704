<app-header *ngIf="auth.isAuthenticated()"></app-header>
<app-auth-header *ngIf="!auth.isAuthenticated()"></app-auth-header>
<router-outlet></router-outlet>
<app-footer></app-footer>

<!-- ==== #footer section end ==== -->

    <!-- Scroll To Top -->
    <a href="javascript:void(0)" class="scrollToTop">
      <i class="fa-solid fa-angles-up"></i>
  </a>

 



  
<app-banner 
  [title]="'About Us'"
  [link]="'/about-us'"
  [item]="'About Us'">
</app-banner>

<section id="vision" #visionSection class="vision-mission-section">
  <div class="container">
    <div class="section-header text-center">   
      <p>
        <b>22Yards</b> is a fractional real estate development company redefining the future of property investment. 
        We're committed in creating vibrant communities and sustainable growth through 
        our diverse portfolio of multi-use properties.
      </p>
      <p>By investing in 22Yards, you're not just investing in real estate; you're investing in a brighter future. Join us on our journey to create sustainable,
         high-yield investments that enrich communities and drive economic growth.</p>
    </div>
    <div class="vision-mission-cards">
      <div class="card" *ngFor="let card of visionMissionCards" [style.border-top]="'4px solid ' + card.color">
        <div class="card-content">
          <h3 class="card-title">{{ card.title }}</h3>
          <p class="card-description">{{ card.description }}</p>
        </div>  
      </div>
    </div>
  </div> 
</section>

<section id="significane" #significanceSection class="section__space" style="background-color: #f3f8ff;">
  <div class="container">
    <!-- Logo Significance Section -->
    <div class="content-block text-center">
      <h2 style="font-size: 2.5rem; color: #333; margin-bottom: 20px;">Our Significance</h2> 
      <p>The pentagon in our logo is more than just a shape—it’s a symbol of values, aspirations, and collective strength. 
        It symbolizes the core principles of our fractional investment model in real estate: unity, balance, strength, 
        interconnectedness, and human aspiration.The five equal sides represent harmony among diverse investors, fostering a resilient ownership structure.
The precise angles, each measuring 108 degrees and collectively totalling 540 degrees, reflect fairness and transparency,
 while the stable and enduring shape signifies trust and security, forming a dependable platform for long-term growth.
Additionally, its interconnected sides symbolize the community we build- connecting a network of investors linked through
 shared ownership, aligned objectives, and a collective vision. Aligned with human progress, the pentagon emphasizes efficiency, 
 resource optimization, and sustainability, embodying our mission to 
empower individuals through innovative and inclusive real estate investments.
      </p>
      <div style="margin: 30px auto; max-width: 400px;">
        <img src="assets/images/logo.png" alt="22 Yards Logo" style="width: 100%; max-height: 200px; object-fit: contain;">
      </div>
    </div>
  </div>
</section>
<section id="story" #ourStorySection class="our-story-section">
  <div class="container">
    <!-- Story Header -->
    <div class="story-header text-center">
      <h2 class="section-title">Our Story</h2>
      <h3 style="text-align: center; margin: 0 auto;">From Friendship to Frontier: The 22 Yards Story</h3>
      <p class="lead">22 Yards—Where precision meets passion, and dreams find their foundation.</p>
    </div>

    <!-- Founders Story -->
    <div class="founders-story">
      <div class="story-content">
        <p>Yashwant Ram Meesala and Vikas Vardha, two friends bound by their shared passion for real estate, took on this journey in the corners of their shared apartment in the NewJersey,USA. Late-night discussions and shared aspirations formed a deep bond between them.</p>
        
        <p>The story of 22 Yards is a proof to the extraordinary power of friendship, vision, and collaboration. It's a narrative that underscores how a shared dream, nurtured by two ambitious minds, can transform into a reality that reshapes an industry.</p>

        <div class="founders-info">
          <div class="founder">
            <h4>Yashwant</h4>
            
            <p>A seasoned construction entrepreneur, had already made his mark with Ampliy Infra, completing nearly 50 projects in Hyderabad.</p>
          </div>
          
          <div class="founder">
            <h4>Vikas</h4>
            <p>A successful fractional real estate investor, had amassed a $60 million portfolio in the USA.</p>
          </div>
        </div>

        <p>When they reunited in 2021, their conversations ignited a spark, leading them to recognize the immense potential of India's budding fractional ownership market. Inspired by the global success of similar models, they envisioned a platform that would democratize real estate investment in India, making it accessible to a wider audience and offering superior returns.</p>
        
        <p>After two years of meticulous planning, research, and relentless groundwork, 22 Yards was born in December 2023. Leveraging their collective expertise and unwavering commitment, they are poised to revolutionize the Indian real estate landscape.</p>
      </div>
    </div>

    <!-- Pitch Perfect Section -->
    <div class="pitch-perfect">
      <h3 class="section-title">Pitch Perfect Investments: The 22Yards Way</h3>
      <h4 class="subtitle">Bowled Over by Real Estate? Welcome to 22Yards</h4>
      
      <div class="pitch-content">
        <p>Inspired from iconic 22-yard pitch in cricket, which is a symbol of unity and passion that resonates with millions across India. It's not just a sport, but a shared heart beat and a bond that exceeds our nations diversity. The 22-yard pitch which is curated carefully, stands as testament to thoughtful planning and unwavering strength. In similar way we strive to create robust foundation for fractional real estate investments, combining meticulous strategy with a vision to fulfil the dreams of our investors.</p>
        
        <p>At 22 Yards, every opportunity begins with a vision—just like a cricket pitch is the starting point of every great match. We bring this analogy to life in real estate, offering a platform where aspirations meet innovation through our cutting-edge fractional ownership model. We redefine real estate investing by making prime commercial properties accessible to all. By co-owning with us, investors are not just purchasing stakes but embarking on a shared journey of growth, prosperity, and collective success.</p>
      </div>
    </div>
  </div>
</section>

<section id="team" #teamSection class="team section__space__bottom">
  <div class="container">
    <div class="team__area">
      <div class="content-block">
        <h2>Our Team</h2> 
        <h5>Meet Our Incredible Team</h5>
      </div>
      
      <div class="shadow__effect">
        <!-- Left Column: Image and Basic Info -->
        <div class="col-4">
          <div class="team__thumb">
            <img src="assets/images/yeshwant.png" alt="Yashwant Ram">
          </div>
          <h5>Yashwant Ram</h5>
          <p>Co-Founder & CEO</p>
          <p>A seasoned construction entrepreneur with over 50 successful projects under Amplify Infra, driving innovation and sustainable growth in real estate.</p>
        </div>

        <!-- Right Column: Detailed Bio -->
        <div class="col-8">
          <h4>Yashwant Ram Meesala: Building the Future</h4>
          <p>Yashwant Ram Meesala has turned his vision into reality in engineering, construction, and real estate. With a background in mechanical engineering, he pursued higher education with a Master’s degree in the U.S. and an MBA from the prestigious Indian School of Business (ISB).</p>
          <p>With over 14 years of experience, Yashwant has successfully completed large-scale projects, including 200 MW of solar power facilities and 250 kilometers of high-tension transmission lines, demonstrating his commitment to sustainable energy.</p>
          <p>Under his leadership, Amplify Infra—a Hyderabad-based construction company—has completed 52 buildings, transforming the city’s skyline. His innovative approach has positioned Amplify Infra as a leader in efficient and high-quality infrastructure development.</p>
          <p>Yashwant’s work reflects meticulous attention to detail, a commitment to sustainability, and a drive for excellence. His journey stands as a testament to progress and innovation, inspiring change in the EPC and real estate sectors while creating a lasting impact through sustainable infrastructure.</p>
        </div>
      </div>

      <div class="shadow__effect">
        <!-- Left Column: Detailed Bio -->
        <div class="col-8">
          <h4>Vikas Vardha: Connecting Industries, Transforming Opportunities</h4>
          <p>Vikas Vardha started his career as a safety scientist, his journey is a testament to his adaptability and entrepreneurial spirit. Originally trained as a pharmacist, he embarked on a journey that has led him through various industries, from pharmaceutical safety to IT consulting and real estate investment. Over the course of 14 years, he built a career that bridges multiple industries, consistently delivering impactful results.</p>
          <p>In recent years, Vikas has revolutionized the real estate investment landscape with his pioneering approach to fractional real estate. He has successfully raised over $60 million in investments, securing partnerships with renowned tenants like T-Mobile, Applebee's, and Domino's. By making real estate investment more accessible, Vikas has empowered a new generation of investors to participate in lucrative opportunities.</p>
          <p>His ability to navigate diverse industries and deliver exceptional results is a testament to his strategic thinking, entrepreneurial drive, and unwavering commitment to excellence. His story inspires others to embrace change, pursue their passions, and make a significant impact on the world.</p>
        </div>

        <!-- Right Column: Image and Basic Info -->
        <div class="col-4">
          <div class="team__thumb">
            <img src="assets/images/vikas.png" alt="Vikas Vardha">
          </div>
          <h5>Venkat Vikas Vardha (Vikas)</h5>
          <p>Co-Founder & COO</p>
          <p>A fractional real estate pioneer, managing a $60M portfolio in the USA, and championing accessible and high-return investments in India.</p>
        </div>
      </div>

    </div>  
  </div>
</section>


<section class="market market--two market--three section__space__bottom">
  <div class="container">
    <div class="market__area market__area--two section__space bg__img">
      <div class="row d-flex align-items-center">
        <div class="col-lg-6">
          <div class="content">
            
            <h2>You Dream, 22 Yards Redeems</h2>
            <p>
              Choose 22 Yards Properties for an investment experience that combines affordability, premium opportunities, and secure asset management. Join us now to unlock the full potential of real estate investments.
            </p>
            <a href="/properties" class="button button--effect">
              Start Exploring
            </a>
            <img  [src]="arrow" alt="Go to" />
          </div>
        </div>
      </div>
      <img
        [src]="marketTwoIllustration"
        alt="Explore the Market"
        class="d-none d-lg-block market__two__thumb"
      />
    </div>
  </div>
</section>


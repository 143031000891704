import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/shared/services/api.service';
import { HttpClient } from '@angular/common/http';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input-gg';

@Component({
  selector: 'app-crm-form',
  templateUrl: './crm-form.component.html',
  styleUrls: ['./crm-form.component.scss']
})
export class CrmFormComponent {

  webToLeadForm!: FormGroup;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.India,CountryISO.UnitedStates,CountryISO.Canada];
  constructor(
    private fb: FormBuilder, 
    private apiService:ApiService,
    private http: HttpClient
  ) {
    this.webToLeadForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', Validators.required],
      countryCode:['+91', Validators.required],
      description: [''],
    });
  }

  get formControls() {
    return this.webToLeadForm.controls;
  }

  onSubmit() {
    if (this.webToLeadForm.valid) {
      const formData = new FormData();
      const formValue = this.webToLeadForm.value;
      const phoneNumberControl = this.webToLeadForm.get('phone_number');
      if (phoneNumberControl && phoneNumberControl.valid) {
        formData.append('Phone',phoneNumberControl.value.e164Number);
      }

      formData.append('Last Name', formValue.lastName);
      formData.append('First Name', formValue.firstName);
      formData.append('Email', formValue.email);
      formData.append('Description', formValue.description);
      formData.append('xnQsjsdp', '80d18fd72cae67b84157c1a0e5690d9da555528a5704d573e16ef8bf6c36c2d9');
      formData.append('xmIwtLD', '701f1c31fd01c3601f35e67a8955235ce87a8c7e1c16629ffddbb4829a364fa4a80bfc47649e1da6fd178b048774075f');
      formData.append('actionType', 'TGVhZHM=');

      this.http
        .post('https://crm.zoho.in/crm/WebToLeadForm', formData)
        .subscribe({
          next: (response) => {
            alert('Form submitted successfully!');
            this.webToLeadForm.reset();
          },
          error: (err) => {
            console.error(err);
            alert('An error occurred while submitting the form.');
          },
        });
    } else {
      alert('Please fill all the required fields!');
    }
  }

  onReset() {
    this.webToLeadForm.reset();
  }
}

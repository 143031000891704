
<div class="dashboard section__space__bottom">
    <div class="container">
        <div class="dashboard__area">
            <div class="row">
                <div class="col-xxl-3">
                    <app-navigation></app-navigation>
                </div>
                <div class="col-xxl-9">
                    <div class="main__content">
                        <div class="collapse__sidebar">
                            <h4>Dashboard</h4>
                            <a href="javascript:void(0)" class="collapse__sidebar__btn">
                                <i class="fa-solid fa-bars-staggered"></i>
                            </a>
                        </div>
                        <div class="main__content-dashboard" *ngIf="details">
                            <div class="row">
                                <div class="col-lg-5">
                                    <div class="main__content-dashboard__chart">
                                        <div class="balance-report__wrapper dashboard-single__box">
                                            <div class="balance-report">
                                                <div>
                                                    <h4><i class="fa-solid fa-inr"></i>{{details.average_earnings}}</h4>
                                                    <p class="secondary">Avaerage Monthly Income</p>
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="group">
                                                <div class="group-inner">
                                                    <p>Amount Invested</p>
                                                    <h6><img src="assets/images/icons/invested.png"
                                                            alt="Invested" /><i class="fa-solid fa-inr"></i>{{details.total_investment}}</h6>
                                                </div>
                                                <div class="group-inner">
                                                    <p>Total Earnings</p>
                                                    <h6><img src="assets/images/icons/earned.png"
                                                            alt="Earned" /><i class="fa-solid fa-inr"></i>{{details.total_earnings}}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="dashboard-single__box">
                                            <h5 class="investo">Investment Chart</h5>
                                            <div id="investmentChart"></div>
                                        </div> -->
                                        <div class="dashboard-single__box">
                                            <div class="intro">
                                                <h5>My Investments</h5>
                                                <a href="/investments">Show All<i
                                                        class="fa-solid fa-arrow-right-long"></i></a>
                                            </div>
                                            <div class="new-invest" *ngIf="details.investment_properties.length === 0">
                                                <p>No Investments Found</p>
                                            </div>
                                            <div class="property-wrap" *ngFor="let property of details.investment_properties">
                                                <div class="poster">
                                                    <a href="investment.html">
                                                        <p-carousel
                                                        [value]="getPropertyImages(property)"
                                                        [numVisible]="1" 
                                                        [autoplayInterval]="3000"
                                                        [numScroll]="1"
                                                        [showIndicators]="false"
                                                        [showNavigators]="false"
                                                        orientation="horizontal"
                                                        styleClass="custom-carousel">
                                                        <ng-template pTemplate="item" let-item>
                                                          <img [src]="item.url" [alt]="item.name" class="carousel-image" />
                                                        </ng-template>
                                                      </p-carousel>
                                                    </a>
                                                </div>
                                                <h4><a href="property/{{property.property_id}}">{{property.name}}</a></h4>
                                                <p><i class="fa-solid fa-location-dot"></i>{{property.location}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="main__content-dashboard__sidebar">
                                      
                                        <div class="dashboard-single__box">
                                            <div class="intro">
                                                <h5>Last Income</h5>
                                                <a href="transactions">Show All<i
                                                        class="fa-solid fa-arrow-right-long"></i></a>
                                            </div>
                                            <div class="new-invest last-income" *ngIf="details.income_transactions.length === 0">
                                                <p>No Trasactions Found</p>
                                            </div>
                                            <div class="last-income" *ngFor="let trasaction of details.income_transactions">
                                                <div class="group">
                                                    <div>
                                                        <h6>{{trasaction.property_name}}</h6>
                                                        <p class="secondary">{{trasaction.transaction_date | date:'short'}}</p>
                                                    </div>
                                                </div>
                                                <h6><i class="fa-solid fa-inr"></i>{{trasaction.amount}}</h6>
                                            </div>
                                        </div>
                                        <div class="dashboard-single__box">
                                            <div class="intro">
                                                <h5>New Investments</h5>
                                                <a href="properties">Show All<i
                                                        class="fa-solid fa-arrow-right-long"></i></a>
                                            </div>
                                            <div class="new-invest" *ngIf="details.remaining_properties.length === 0">
                                                <p>No Properties Found</p>
                                            </div>
                                            <div class="new-invest" *ngFor="let property of remaining_properties">
                                                <div class="poster">
                                                    <a href="property/{{property.property_id}}">
                                                        <p-carousel
                                                        [value]="getPropertyImages(property)"
                                                        [numVisible]="1" 
                                                        [autoplayInterval]="3000"
                                                        [numScroll]="1" 
                                                        [showIndicators]="false"
                                                        [showIndicators]="false"
                                                        orientation="horizontal"
                                                        styleClass="custom-carousel">
                                                        <ng-template pTemplate="item" let-item>
                                                          <img [src]="item.url" [alt]="item.name" class="carousel-image" />
                                                        </ng-template>
                                                      </p-carousel>
                                                    </a>
                                                </div>
                                                <div class="invest-content">
                                                    <div class="item__head__left">
                                                        <h6>{{property.name}}</h6>
                                                        <div class="progress__type progress__type--two">
                                                            <div class="progress">
                                                                <div class="progress-bar" role="progressbar"
                                                                    aria-valuenow="25" aria-valuemin="0"
                                                                    aria-valuemax="100"></div>
                                                            </div>
                                                            <div class="project__info">
                                                                <p class="project__has"><span
                                                                        class="project__has__investors">{{property.total_investors}}
                                                                        Investors</span> | <span
                                                                        class="project__has__investors__amount"><i
                                                                            class="fa-solid fa-inr"></i>
                                                                            {{property.total_investment}}</span> <span
                                                                        class="project__has__investors__percent">({{property.investor_percentage}}%)</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import * as $ from 'jquery';
import { PropertyModule } from './property/property.module';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HotToastModule } from '@ngneat/hot-toast';
import { GalleriaModule } from 'primeng/galleria';
import { CarouselModule } from 'primeng/carousel';
import { DialogModule } from 'primeng/dialog';
import { ImageModule } from 'primeng/image';
import { StepperModule } from 'primeng/stepper';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AppComponent } from './app.component';
import { NavigationComponent } from './elements/navigation/navigation.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './elements/header/header.component';
import { MyInvestmentsComponent } from './my-investments/my-investments.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { MyprofileComponent } from './myprofile/myprofile.component';
import { LandingComponent } from './landing/landing.component';
import { VerifyEmailComponent } from './auth/verify-email/verify-email.component';
import { AuthHeaderComponent } from './elements/auth-header/auth-header.component';
import { FooterComponent } from './elements/footer/footer.component';
import { UsersComponent } from './users/users.component';
import { UpdateUserComponent } from './update-user/update-user.component';
import { ChannelpartnerReferlsComponent } from './channelpartner-referls/channelpartner-referls.component';
import { UpdateChannelpartnerReferalComponent } from './update-channelpartner-referal/update-channelpartner-referal.component';
import { UpdatePropertyComponent } from './update-property/update-property.component';
import { PropertiesListComponent } from './properties-list/properties-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';

import { KycComponent } from './kyc/kyc.component';
import { PrivacyComponent } from './staticPages/privacy/privacy.component';
import { TermsComponent } from './staticPages/terms/terms.component';
import { ShippingComponent } from './staticPages/shipping/shipping.component';
import { CancelRefundComponent } from './staticPages/cancel-refund/cancel-refund.component';
import { ContactusComponent } from './staticPages/contact-us/contact-us.component';
import { FAQSComponent } from './staticPages/faqs/faqs.component';
import { KeyRisksComponent } from './staticPages/key-risks/key-risks.component';
import { AboutUsComponent } from './staticPages/about-us/about-us.component';
import { BannerComponent } from './staticPages/banner/banner.component';
import { HowItWorksComponent } from './staticPages/how-it-works/how-it-works.component';
import { CookiePolicyComponent } from './staticPages/cookie-policy/cookie-policy.component';
import { BlogComponent } from './staticPages/blog/blog.component';
import { BlogDetailComponent } from './staticPages/blog-detail/blog-detail.component';
import { CrmFormComponent } from './elements/crm-form/crm-form.component';






@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    DashboardComponent,
    HeaderComponent,
    MyInvestmentsComponent,
    TransactionsComponent,
    MyprofileComponent,
    LandingComponent,
    VerifyEmailComponent,
    AuthHeaderComponent,
    FooterComponent,
    UsersComponent,
    UpdateUserComponent,
    ChannelpartnerReferlsComponent,
    UpdateChannelpartnerReferalComponent,
    UpdatePropertyComponent,
    PropertiesListComponent,
    KycComponent,
    PrivacyComponent,
    TermsComponent,
    ShippingComponent,
    CancelRefundComponent,
    ContactusComponent,
    FAQSComponent,
    KeyRisksComponent,
    AboutUsComponent,
    BannerComponent,
    HowItWorksComponent,
    CookiePolicyComponent,
    BlogComponent,
    BlogDetailComponent,
    CrmFormComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    NgbCarouselModule,
    SweetAlert2Module.forRoot(),
    ToastrModule.forRoot({ 
      positionClass: 'toast-top-right',
      timeOut: 3000,
      preventDuplicates: true,
    }),
    NgxIntlTelInputModule,
    AppRoutingModule,
    PropertyModule,
    HotToastModule.forRoot({
      duration: 5000,
      position: 'top-right',
      style: {
        background: 'white',
        color: 'black',
      },
    }),
    GalleriaModule,
    CarouselModule,
    FontAwesomeModule,
    DialogModule,
    ImageModule,
    StepperModule,
    ButtonModule,
    InputTextModule,
    IconFieldModule,
    InputIconModule,

    
  ],

  
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }

import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  private scripts: { [key: string]: string } = {};

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.scripts = {
      'jquery-3.6.0.min.js': 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js',
      'bootstrap.bundle.min.js': 'assets/vendor/bootstrap/js/bootstrap.bundle.min.js',
      'jquery.nice-select.min.js': 'assets/vendor/nice-select/js/jquery.nice-select.min.js',
      'jquery.magnific-popup.min.js': 'assets/vendor/magnific-popup/js/jquery.magnific-popup.min.js',
      'slick.js': 'assets/vendor/slick/js/slick.js',
      'shuffle.min.js': 'assets/vendor/shuffle/shuffle.min.js',
      'jquery.downCount.js': 'assets/vendor/downcount/jquery.downCount.js',
      'jquery.waypoints.min.js': 'assets/vendor/waypoints-js/jquery.waypoints.min.js',
      'jquery.counterup.min.js': 'assets/vendor/counter-js/jquery.counterup.min.js',
      'wow.min.js': 'assets/vendor/wow/wow.min.js',
      'plugin.js': 'assets/js/plugin.js',
      'apexcharts.min.js': 'assets/vendor/apex-chart/apexcharts.min.js',
      'main.js': 'assets/js/main.js'
      // Add more script definitions as needed
    };
  }

  async loadScript(name: string, defer: boolean = false): Promise<void> {
    if (!isPlatformBrowser(this.platformId)) {
      return Promise.resolve(); // Skip on the server
    }
    
    if (!this.scripts[name]) {
      return Promise.reject(new Error(`Script ${name} not configured.`));
    }

    // Remove existing script if already loaded
    this.removeScript(name);

    return new Promise<void>((resolve, reject) => {
      const script = document.createElement('script');
      script.src = this.scripts[name];
      script.defer = defer;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Failed to load script ${name}`));
      document.body.appendChild(script);
    });
  }

  removeScript(name: string): void {
    const existingScript = document.body.querySelector(`script[src="${this.scripts[name]}"]`);
    if (existingScript) {
      existingScript.remove();
    }
  }

  loadScriptsInOrder(scriptNames: string[]): Promise<void> {
    return scriptNames.reduce((promiseChain, scriptName) => {
      return promiseChain.then(() => this.loadScript(scriptName, true));
    }, Promise.resolve());
  }
}

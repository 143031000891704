<!-- ==== blog single banner start ==== -->
<div *ngIf="blogData?.article_media?.[0]" 
     class="blog__single__banner clear__top bg__img"
     [style.backgroundImage]="'url(' + (cmsUrl + blogData?.article_media?.[0]?.url) + ')'">
</div>
<section class="blog__single__post">
<div class="container" *ngIf="blogData">
    <div class="blog__single__post__content">
        <div class="blog__details__head">
            <div class="blog__item__info">
                <div class="post__date">
                    <div class="dat">
                        <img src="assets/images/blog/calendar.png" alt="Post Date" />
                        <p>{{ blogData.posted_on }}</p>
                    </div>
                </div>
                <div class="post__read__time">
                    <div class="dat">
                        <img src="assets/images/blog/watch.png" alt="Post Read Time" />
                        <p>{{ blogData.read_time }} Read</p>
                    </div>
                </div>
            </div>
            <div class="blog__social">
                <div class="social text-start text-lg-end">
                    <a target="_blank" href="https://www.facebook.com/people/22Yards/61563374311398/">
                        <i class="fa-brands fa-facebook-f"></i>
                    </a>
                    <a target="_blank" href="https://www.youtube.com/@22_Yards_Proptech">
                        <i class="fa-brands fa-youtube"></i>
                    </a>
                    <a target="_blank" href="https://www.instagram.com/22_yards_proptech/">
                        <i class="fa-brands fa-instagram"></i>
                    </a>
                    <a target="_blank" href="https://www.linkedin.com/company/22yardsprop/">
                        <i class="fa-brands fa-linkedin-in"></i>
                    </a>
                </div>
            </div>
        </div>
        <h4>{{ blogData.title }}</h4>
        <div style="margin-top: 2rem;" [innerHTML]="blogData.content"></div>

    </div>
</div>
</section>
<!-- ==== #blog single banner end ==== -->

<!-- ==== browse all post section start ==== -->
<section class="latest__post__features section__space bg__img">
<div class="container">
    <div class="latest__post__features__area wow fadeInUp">
        <div class="title__with__cta">
            <div class="row d-flex align-items-center">
                <div class="col-lg-8">
                    <h2>Latest Posts</h2>
                </div>
                <div class="col-lg-4">
                    <div class="text-start text-lg-end">
                        <a href="/blogs" class="button button--secondary button--effect">Browse All
                            Posts</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row altr">
            <div class="col-md-6 col-xl-4" *ngFor="let blog of blogs">
                <div class="featured__large__post">
                    <a [routerLink]="['/blogs/',blog.slug]" class="thumbnail">
                        <img [src]="cmsUrl+blog.article_media[0].url" alt="Poster" />
                    </a>
                    <div class="blog__content">
                        <h5><a [routerLink]="['/blogs/',blog.slug]">{{ blog.title }}</a></h5>
                        <div class="multiline-text--fallback" [innerHTML]="blog.content"></div>
                        <a style="margin-top: 10px;" [routerLink]="['/blogs/',blog.slug]">Read full Blog <i
                                class="fa-solid fa-arrow-right-long"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
<!-- ==== #browse all post section end ==== -->
import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
  private loader: Loader;
  private apiLoaded: Promise<typeof google>;

  constructor() {
    // Initialize the loader with your API key and any other options
    this.loader = new Loader({
      apiKey: environment.googleMapsApiKey, // Replace with your actual API key
      libraries: ['places'], // Specify any libraries you need
    });

    // Load the Google Maps API
    this.apiLoaded = this.loader.load();
  }

  loadApi(): Promise<typeof google> {
    return this.apiLoaded;
  }
}

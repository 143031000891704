import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/shared/services/api.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'; // Import DomSanitizer and SafeResourceUrl
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';

declare var Razorpay: any;


@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss']
})
export class PropertyDetailsComponent implements OnInit {
  property_id: any;
  property: any;
  lat: any;
  lng: any;
  is_kyc_completed:boolean=false
  property_files: any[] = [];
  apiKey: string = environment.googleMapsApiKey;
  mapSrc: SafeResourceUrl | undefined; // Use SafeResourceUrl to mark the URL as safe
  mapLink: string | undefined;
  bankDetails:any = {};
  @ViewChild('investModal') investModal!: TemplateRef<any>;
  @ViewChild('investConfirmationModal') investConfirmationModal!: TemplateRef<any>;
  numShares: any = 0;
  investmentValue: number = 0;
  selectedPropertyId: any;
  selectedProperty: any;
  remainingShares: number = 0;
  isSoldOut: boolean = false;
  images:any;
Math: any;

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private apiService: ApiService,
    private sanitizer: DomSanitizer ,
    private auth: AuthService,
    private router:Router,
    private toastr:ToastrService,
    private titleService: Title,
    private metaService: Meta

  ) {}

  ngOnInit(): void {
    this.property_id = this.route.snapshot.paramMap.get('id');
    const params = {
      'property_id': this.property_id,
    };
    this.apiService.PropertyDetails(params).subscribe(response => {
      this.property = response;
      this.updateMetaTags();

      this.property.remaining_investment_value = 
          this.property.value - 
          (this.property.shares[0].total_sold_shares * this.property.value_of_each_share);
      if (typeof this.property?.address === 'string') {
        this.property.address = JSON.parse(this.property.address);
      }
      if (this.property?.address) {
        this.lat = this.property.address.lat;
        this.lng = this.property.address.lng;
        this.updateMapUrls(); // Update URLs when data is loaded
      }
    });
    
    this.apiService.GetPropertyFiles(params,this.auth.isAuthenticated()).subscribe(property_files => {
      this.property_files = property_files.property_images;
      this.images = this.getPropertyImages(this.property_files)
    });
  }
  
  
  private updateMetaTags(propertyDetails?: any) {
    // Dynamic page title based on property
    const pageTitle = propertyDetails 
      ? `${propertyDetails.name} - Property Details | 22Yards` 
      : 'Property Details | 22Yards';
    this.titleService.setTitle(pageTitle);

    // Remove existing meta tags
    this.metaService.removeTag('name="description"');
    this.metaService.removeTag('property="og:title"');
    this.metaService.removeTag('property="og:description"');
    this.metaService.removeTag('property="og:url"');
    this.metaService.removeTag('property="og:type"');


    // Default description if no property details
    const description = propertyDetails 
      ? `Explore details of ${propertyDetails.name} - ${propertyDetails.shortDescription}` 
      : 'View detailed information about our premium real estate properties.';

    // Update meta tags
    this.metaService.addTags([
      { 
        name: 'description', 
        content: description 
      },
      { 
        property: 'og:title', 
        content: pageTitle 
      },
      { 
        property: 'og:description', 
        content: description 
      },
      { 
        property: 'og:url', 
        content: window.location.href 
      },
      { 
        property: 'og:type', 
        content: 'website' 
      },
    
    ]);

    // Canonical URL
    this.metaService.updateTag({
      rel: 'canonical',
      href: window.location.href
    });
  }

  // Method to update tags when property details are loaded
  updatePropertyMetaTags(propertyDetails: any) {
    this.updateMetaTags(propertyDetails);
  }

  

  // Function to update map URLs
  updateMapUrls(): void {
    const url = `https://www.google.com/maps/embed/v1/place?key=${this.apiKey}&q=${this.lat},${this.lng}&zoom=15`;
    this.mapSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url); // Sanitize the URL
    this.mapLink = `https://www.google.com/maps?q=${this.lat},${this.lng}`;
  }

  // Function to get property images by specific name
  getPropertyImages(property_files: any) {
    return property_files.filter((image: any) => image.name === 'propertyImages');
  }

  // Function to get the URL of a specific file by name or redirect to login page if not available
  getFileUrl(fileName: string): string {
    const file = this.property_files.find(f => f.name === fileName);
    return file ? file.url : '/login'; // Return file URL or login page URL
  }

  scrollToGallery(event: Event): void {
    event.preventDefault(); // Prevent default anchor navigation
    const gallerySection = document.querySelector('#gallery');
    if (gallerySection) {
      gallerySection.scrollIntoView({ behavior: 'smooth' });
    }
  }

  routeToInvestment(property_id:any) {
    if(this.auth.isAuthenticated()){
      this.apiService.getVerificationStatus( this.auth.getData().user_id).subscribe(response => {
        this.is_kyc_completed = response['is_kyc_completed']
        if(this.is_kyc_completed){
          // Open the modal using Bootstrap's JavaScript API
            this.modalService.open(this.investModal, { centered: true });
          }
          else{

            this.router.navigate(['kyc'], {
              queryParams: { property: property_id }
            });          
          }
      });
    } else  {
      localStorage.setItem('property',property_id)
      this.router.navigate(['login']);    }
  }

  closeModal(){
    this.modalService.dismissAll()
  }

  onSubmitInvestmentForm(event: Event) {
    event.preventDefault();
  
    if (this.numShares == 0) {
      this.toastr.error('Buy at least 1 share');
      return;
    }
    console.log(this.selectedProperty)
    // Calculate the investment value
    this.investmentValue = this.numShares * this.selectedProperty.value_of_each_share;
  
    // Fetch bank details dynamically (API or static values)
    this.bankDetails = this.selectedProperty.bank_account[0]
    this.modalService.dismissAll()
    // Open the modal
    this.modalService.open(this.investConfirmationModal, { centered: true });

    // // Ensure proper focus management
    // modalRef.result
    //   .then(() => {
    //     // Modal closed
    //     document.body.removeAttribute('aria-hidden');
    //   })
    //   .catch(() => {
    //     // Modal dismissed
    //     document.body.removeAttribute('aria-hidden');
    //   });
  }

  copyDetails() {
    const details = `
      Account Name: ${this.bankDetails.account_name}
      Account Number: ${this.bankDetails.account_number}
      IFSC Code: ${this.bankDetails.ifsc_code}
      Investment Value: ₹${this.investmentValue}
    `;
    navigator.clipboard.writeText(details).then(() => {
      this.toastr.success('Details copied to clipboard!');
    });
  }

  downloadDetails() {
    const details = `
      Account Name: ${this.bankDetails.account_name}
      Account Number: ${this.bankDetails.account_number}
      IFSC Code: ${this.bankDetails.ifsc_code}
      Investment Value: ₹${this.investmentValue}
    `;
    const blob = new Blob([details], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Investment_Details.txt';
    link.click();
  }

  confirmTransaction(){
    
  }
  
  

  launchRazorpay(orderId: string, amount: number) {
    const options = {
      key: environment.RAZORPAY_KEY_ID, // Replace with your Razorpay Key ID
      amount: amount * 100, // in paise
      currency: 'INR',
      name: '22Yards',
      description: 'Investment on property '+this.selectedProperty.name,
      order_id: orderId,
      handler: (response: { razorpay_payment_id: any; }) => {
        this.onPaymentConfirmation(response.razorpay_payment_id);
      },
      prefill: {
        name: 'User Name',
        email: 'user@example.com',
        contact: '9999999999'
      },
      notes: {
        property_id: this.selectedPropertyId,
        user_id: this.auth.getData().user_id, 
        shares: this.numShares.toString(),
      },
      theme: {
        color: '#3399cc'
      }
    };

    const rzp = new Razorpay(options);
    rzp.open();
  }

  onPaymentConfirmation(paymentId: string) {
    this.apiService.verifyPayment(paymentId).subscribe(response => {
      if (response.status === 'success') {
        this.toastr.success('Payment Success')
        this.router.navigate(['dashboard'])
      } else {
        this.toastr.error('Payment Failure')
        this.router.navigate(['properties'])
      }
    });
  }

  onInvestNowClick(event: MouseEvent, property: any): void {
    event.stopPropagation(); // Prevent the event from bubbling up
    this.selectedPropertyId = property.property_id;
    this.selectedProperty = property;
    this.remainingShares = this.selectedProperty.remaining_shares;
    this.isSoldOut = this.selectedProperty.is_sold_out;
    this.routeToInvestment(property.property_id);
  }

calculateInvestmentValue(): void {
  this.investmentValue = this.numShares * this.selectedProperty.value_of_each_share;
}

onSharesChange(): void {
  // Ensure numShares does not exceed remainingShares
  if (this.numShares > this.remainingShares) {
    this.numShares = this.remainingShares;
    // Optionally, show a message to the user
    alert('You cannot invest more shares than are available.');
  } else {
    this.calculateInvestmentValue();
  }
}

getProgressPercentage(): number {
  // Ensure all properties exist and are valid numbers
  const totalShares = this.property.no_of_shares || 0; // Total available shares
  const soldShares = this.property.shares[0]?.total_sold_shares || 0; // Sold shares

  // Calculate the percentage of shares sold
  const progress = (soldShares / totalShares) * 100;

  // Return a value between 0 and 100
  return Math.min(progress, 100); 
}


}

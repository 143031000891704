<div class="dashboard section__space__bottom">
    <div class="container">
        <div class="dashboard__area">
            <div class="row">
                <div class="col-xxl-3">
                    <app-navigation></app-navigation>
                </div>
                <div class="col-xxl-9">
                    <div class="main__content">
                        <div class="collapse__sidebar">
                            <h4>Add User</h4>
                            <a href="javascript:void(0)" class="collapse__sidebar__btn">
                                <i class="fa-solid fa-bars-staggered"></i>
                            </a>
                        </div>
                        <div class="main__content-dashboard">
                            <div class="breadcrumb-dashboard">
                                <h5><span *ngIf="!userId">Add</span>
                                    <span *ngIf="userId">Update</span> User</h5>
                                <div>
                                    <a href="users">Users</a>
                                    <i class="fa-solid fa-arrow-right-long"></i>
                                    <a href="javascript:void(0)"><span *ngIf="!userId">Add</span>
                                        <span *ngIf="userId">Update</span> User</a>
                                </div>
                            </div>
                            <div class="row">
                            <div class="investment-table">
                                 <section >
                                <div class="container">
                                  <div class="registration__area">
                                    <h4 class="neutral-top" *ngIf="!userId">Add User</h4>
                                    <h4 class="neutral-top" *ngIf="userId">Update User</h4>
                                    <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
                                      <div class="regi__type">
                                        <label for="type">Role? <span class="requried">*</span></label>
                                        <select class="nice-select type__select" formControlName="role">
                                          <option value="admin">Admin</option>
                                          <option value="support">Support</option>
                                        </select>
                                      </div>
                                      <div class="row">
                                        <div class="col-sm-6">
                                          <div class="input input--secondary">
                                            <label for="firstname">First Name<span class="requried">*</span></label>
                                            <input type="text" formControlName="firstname" placeholder="First Name" required />
                                          </div>
                                        </div>
                                        <div class="col-sm-6">
                                          <div class="input input--secondary">
                                            <label for="lastname">Last Name<span class="requried">*</span></label>
                                            <input type="text" formControlName="lastname" placeholder="Last Name" required />
                                          </div>
                                        </div>
                                      </div>
                                      <div class="input input--secondary">
                                        <label for="email">Email<span class="requried">*</span></label>
                                        <input type="email" formControlName="email" placeholder="Enter your email" required />
                                      </div>
                                      <div class="input input--secondary">
                                        <label for="phone">Phone Number<span class="required">*</span></label>
                                        <ngx-intl-tel-input
                                        [cssClass]="'form-control'"
                                        [preferredCountries]="preferredCountries"
                                        [enableAutoCountrySelect]="true"
                                        [enablePlaceholder]="true"
                                        [searchCountryFlag]="true"
                                        [searchCountryField]="[
                                          SearchCountryField.Iso2,
                                          SearchCountryField.Name
                                        ]"
                                        [selectFirstCountry]="false"
                                        [selectedCountryISO]="CountryISO.India"
                                        [maxLength]="15"
                                        [phoneValidation]="true"
                                        [separateDialCode]="separateDialCode"
                                        [numberFormat]="PhoneNumberFormat.National"
                                        name="phone"
                                        formControlName="phone_number"
                                      >
                                      </ngx-intl-tel-input>
                                        <div class="passwords-not-matched-error" *ngIf="userForm.get('phone_number')?.invalid && userForm.get('phone_number')?.touched">
                                          <div *ngIf="userForm.get('phone_number')?.errors"><small class="text-danger">Enter a Valid Number</small></div>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-sm-12">
                                          <div class="input input--secondary password-field">
                                            <label for="password">Password<span class="required">*</span></label>
                                            <input
                                              [type]="passwordVisible ? 'text' : 'password'"
                                              formControlName="password"
                                              placeholder="Password"
                                              (input)="checkPassword('password')"
                                            />
                                            <button type="button" (click)="togglePasswordVisibility()">
                                              <i [ngClass]="passwordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                            </button>
                                            <div class="password-tooltip" *ngIf="showTooltip">
                                              <ul class="error-list">
                                                <li [ngClass]="{'strikethrough': isMinLengthSatisfied}">
                                                  Minimum 8 characters required.
                                                </li>
                                                <li [ngClass]="{'strikethrough':hasUppercase}">
                                                  At least one uppercase letter required.
                                                </li>
                                                <li [ngClass]="{'strikethrough':hasLowercase}">
                                                  At least one lowercase letter required.
                                                </li>
                                                <li [ngClass]="{'strikethrough':hasNumber}">
                                                  At least one number required.
                                                </li>
                                                <li [ngClass]="{'strikethrough': hasSpecialChar}">At least one special character</li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>                        
                                      <div class="input__button">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <button class="button cancel-button" (click)="cancel()">
                                                    Cancel
                                                </button>
                                            </div>
                                            <div class="col-sm-6">
                                                <button type="submit" class="button button--effect" [disabled]="userForm.invalid">
                                                    Submit
                                                  </button>
                                            </div>
                                            
                                        </div>
                                
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </section> 
                            </div>
                        </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
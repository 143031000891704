import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

import { AuthService } from 'src/app/shared/services/auth.service';
import { ScriptService } from './shared/services/script.service';
import { Router, NavigationEnd } from '@angular/router';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';

// Define the expected roles
type UserRole = 'admin' | 'channelpartner' | 'support';

// Assuming the decoded token structure
interface DecodedToken {
  role: UserRole;
  // Add other properties if needed
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit {
  title = 'app';
  session: any = {};  // Specify type as needed

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public auth: AuthService,
    private scriptService: ScriptService,
    private router: Router,
    private viewportScroller: ViewportScroller
  ) {
    if (this.auth.isAuthenticated() && this.auth.getData().role == 'investor') {
      let propertyId = localStorage.getItem('property')
      if (propertyId) {
        this.router.navigate(['property', propertyId]);
        localStorage.removeItem('property')
      }
    }
  }

  async ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.viewportScroller.scrollToPosition([0, 0]);
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      await this.scriptService.loadScriptsInOrder([
        'jquery-3.6.0.min.js',
        'bootstrap.bundle.min.js',
        'jquery.nice-select.min.js',
        'jquery.magnific-popup.min.js',
        'slick.js',
        'shuffle.min.js',
        'jquery.downCount.js',
        'jquery.waypoints.min.js',
        'jquery.counterup.min.js',
        'wow.min.js',
        'apexcharts.min.js',
        'plugin.js',
        'main.js'
      ]);
    }

  }


}




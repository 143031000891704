import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../shared/services/auth.service';
import { GoogleMapsService } from 'src/app/shared/services/google-maps.service';
import { faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

interface PropertyFile {
  id: number;
  url: string;
  name: string;
  property: string;
}

@Component({
  selector: 'app-update-property',
  templateUrl: './update-property.component.html',
  styleUrls: ['./update-property.component.scss']
})



export class UpdatePropertyComponent implements OnInit {

  @ViewChild('mapContainer', { static: false }) gmap!: ElementRef;
  map!: google.maps.Map;
  marker!: google.maps.Marker;
  autocomplete!: google.maps.places.Autocomplete;
  latitude: number = 17.4065;
  longitude: number = 78.4772;
  mapOptions!: google.maps.MapOptions

  propertyForm!: FormGroup;
  propertyId:string;
  faTrash = faTrash; 
  faEye = faEye;
  eachShareValue!:number;
  displayDialog: boolean = false;
  selectedImageUrl: string | null = null;
  selectedDocumentUrl: string | null = null;
  
  // Separate variables to store existing files
  existingPropertyImages: any[] = [];
  existingLandDocuments: any[] = [];
  existingLegalOpinions: any[] = [];
  existingFloorPlans: any[] = [];
  existingRenders: any[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toast: HotToastService,
    private apiService:ApiService, 
    private toastr:ToastrService,
    private authService: AuthService,
    private googleMapsService: GoogleMapsService,
  ){
    this.propertyId = this.route.snapshot.paramMap.get('id')!;
    if(this.propertyId) {
      this.getPropertyDetails();
    }
    else{
      this.initializeMap(this.latitude,this.longitude)
    }
     if(authService.isAuthenticated() && (authService.getData().role !== 'support' && authService.getData().role !== 'admin')) {
      this.authService.redirectedToUserPages()
    }
  }


  ngOnInit(): void {
    this.propertyForm = this.fb.group({
      type:['RETAIL', Validators.required],
      name:['',Validators.required],
      location:['',Validators.required],
      description:['',Validators.required],
      land_area_sq_yards:[null, Validators.required],
      built_up_area_sq_yards:[null],
      no_of_shares:[{ value: 100, disabled: true },Validators.required],
      invester_referal_commision:[0],
      property_referal_commision:[0],
      uds_per_share:[null],
      built_up_per_share:[null],
      tenure:[null, Validators.required],
      value:[null, Validators.required],
      value_of_each_share:[{ value: this.eachShareValue, disabled: true }, Validators.required],
      tenant_details:['', Validators.required],
      lease_term:[{value:null, disabled:true}, Validators.required],
      lock_in_period:[null, Validators.required],
      lease_start_date:['', Validators.required],
      lock_in_end_date:['', Validators.required],
      lease_end_date:['', Validators.required],
      released_shares:[{ value: null, disabled: true }, Validators.required],
      blocked_shares_by_admin:[null, Validators.required],
      ROI_min:[null,Validators.required],
      ROI_max:[null,Validators.required],
      IRR_min:[null,Validators.required],
      IRR_max:[null,Validators.required],
      account_name:['',Validators.required],
      account_number:[null,Validators.required],
      ifsc_code:['',Validators.required],
      account_number_conf:[null,Validators.required],
      address: this.fb.group({
        lat: ['', Validators.required],  // Initialize with default value and add validator
        lng: ['', Validators.required]  // Initialize with default value and add validator
      }),
      landDocuments: this.fb.array([],Validators.required),
      legalOpinion: this.fb.array([],Validators.required),
      propertyImages: this.fb.array([],Validators.required),
      floorPlans: this.fb.array([], Validators.required),
      renders: this.fb.array([], Validators.required)

    })
  }
  


  // Getters for FormArrays
  get landDocuments(): FormArray {
    return this.propertyForm.get('landDocuments') as FormArray;
  }

  get legalOpinion(): FormArray {
    return this.propertyForm.get('legalOpinion') as FormArray;
  }

  get propertyImages(): FormArray {
    return this.propertyForm.get('propertyImages') as FormArray;
  }

  get floorPlans(): FormArray {
    return this.propertyForm.get('floorPlans') as FormArray;
  }

  get renders(): FormArray {
    return this.propertyForm.get('renders') as FormArray;
  }

  // Method to handle file input
  handleFileInput(event: Event, controlName: string): void {
    const input = event.target as HTMLInputElement;

    if (input.files) {
      const filesArray = Array.from(input.files);
      const formArray = this.propertyForm.get(controlName) as FormArray;
      // Clear previous files in the FormArray
      formArray.clear();

      // Add new files to the FormArray
      filesArray.forEach(file => formArray.push(this.fb.control(file)));
    }
  }


  initializeMap( lat:any, lng:any) {
    this.googleMapsService.loadApi().then(() => {
      this.mapOptions = {
        center: { lat: lat, lng: lng },
        zoom: 15,
      };

      this.mapInitializer();
      this.initializeAutocomplete();
    }).catch(error => {
      console.error('Error loading Google Maps API:', error);
    });
  }


  mapInitializer() {
    // Initialize the map
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);

    // Initialize the marker
    this.marker = new google.maps.Marker({
      position: { lat: this.latitude, lng: this.longitude },
      map: this.map,
      draggable: true,
    });

    // Event listener for map click
    this.map.addListener('click', (event: google.maps.MapMouseEvent) => {
      if (event.latLng) {
        this.latitude = event.latLng.lat();
        this.longitude = event.latLng.lng();
        this.marker.setPosition(event.latLng);
        this.propertyForm.patchValue({
          address:{
            lat:this.latitude,
            lng:this.longitude
          }
        })
      }
    });

    // Event listener for marker drag end
    this.marker.addListener('dragend', (event: google.maps.MapMouseEvent) => {
      if (event.latLng) {
        this.latitude = event.latLng.lat();
        this.longitude = event.latLng.lng();
        this.propertyForm.patchValue({
          address:{
            lat:this.latitude,
            lng:this.longitude
          }
        })
      }
    });
  }




  initializeAutocomplete() {
    // Initialize the autocomplete input field
    const input = document.getElementById('location') as HTMLInputElement;
    this.autocomplete = new google.maps.places.Autocomplete(input);

    // Set the bounds of the autocomplete to the current map bounds
    this.autocomplete.bindTo('bounds', this.map);

    // Listen for place selection
    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();

      if (!place.geometry || !place.geometry.location) {
        console.error('No details available for input: ' + place.name);
        return;
      }

      // Update map center to the selected place
      this.map.setCenter(place.geometry.location);
      this.map.setZoom(15);

      // Update marker position
      this.marker.setPosition(place.geometry.location);

      // Update component latitude and longitude
      this.latitude = place.geometry.location.lat();
      this.longitude = place.geometry.location.lng();
      this.propertyForm.patchValue({
        address:{
          lat:this.latitude,
          lng:this.longitude
        }
      })
    });
  }

  cancel(){
    this.router.navigate(['/all-properties'])
  }

  getPropertyDetails(){
    this.apiService.getProperty(this.propertyId).subscribe(response=>{
      if(!response.error){
        this.latitude=response.data.address.lat
        this.longitude=response.data.address.lng
        this.initializeForm(response.data)
        this.initializeMap(this.latitude,this.longitude)
      }
    })
  }

  initializeForm(propertyData: any): void {
    this.propertyForm = this.fb.group({
      type:[propertyData.type, Validators.required],
      name:[propertyData.name,Validators.required],
      location:[propertyData.location,Validators.required],
      description:[propertyData.description,Validators.required],
      land_area_sq_yards:[propertyData.land_area_sq_yards, Validators.required],
      built_up_area_sq_yards:[propertyData.built_up_area_sq_yards],
      no_of_shares:[propertyData.no_of_shares,Validators.required],
      invester_referal_commision:[propertyData.invester_referal_commision],
      property_referal_commision:[propertyData.property_referal_commision],
      uds_per_share:[propertyData.uds_per_share],
      built_up_per_share:[propertyData.built_up_per_share],
      tenure:[propertyData.tenure, Validators.required],
      value:[propertyData.value, Validators.required],
      value_of_each_share:[{ value: propertyData.value_of_each_share, disabled: true }, Validators.required],
      tenant_details:[propertyData.property_tenancy[0].tenant_details, Validators.required],
      lease_term:[{value:propertyData.property_tenancy[0].lease_term, disabled:true}, Validators.required],
      lock_in_period:[propertyData.property_tenancy[0].lock_in_period, Validators.required],
      lease_start_date:[propertyData.property_tenancy[0].lease_start_date, Validators.required],
      lock_in_end_date:[propertyData.property_tenancy[0].lock_in_end_date, Validators.required],
      lease_end_date:[propertyData.property_tenancy[0].lease_end_date, Validators.required],
      released_shares:[{ value: propertyData.shares[0].released_shares, disabled: true }, Validators.required],
      blocked_shares_by_admin:[propertyData.shares[0].blocked_shares_by_admin, Validators.required],
      ROI_min:[propertyData.ROI_min,Validators.required],
      ROI_max:[propertyData.ROI_max,Validators.required],
      IRR_min:[propertyData.IRR_min,Validators.required],
      IRR_max:[propertyData.IRR_max,Validators.required],
      account_name:[propertyData.property_account_details[0].account_name,Validators.required],
      account_number:[propertyData.property_account_details[0].account_number,Validators.required],
      ifsc_code:[propertyData.property_account_details[0].ifsc_code,Validators.required],
      account_number_conf:[propertyData.property_account_details[0].account_number,Validators.required],
      address: this.fb.group({
        lat: [propertyData.address.lat, Validators.required],  // Initialize with default value and add validator
        lng: [propertyData.address.lng, Validators.required]  // Initialize with default value and add validator
      }),
      landDocuments: this.fb.array([]),
      legalOpinion: this.fb.array([]),
      propertyImages: this.fb.array([]),
      floorPlans: this.fb.array([]),
      renders: this.fb.array([]),
    })
    this.existingPropertyImages = propertyData.files.filter((file: PropertyFile) => file.name === 'propertyImages');
    this.existingLandDocuments = propertyData.files.filter((file: PropertyFile) => file.name === 'landDocuments');
    this.existingLegalOpinions = propertyData.files.filter((file: PropertyFile) => file.name === 'legalOpinion');
    this.existingFloorPlans = propertyData.files.filter((file: PropertyFile) => file.name === 'floorPlans');
    this.existingRenders = propertyData.files.filter((file: PropertyFile) => file.name === 'renders');

    // Initialize FormArrays if needed
    this.populateFilesFormArray('propertyImages', this.existingPropertyImages);
    this.populateFilesFormArray('landDocuments', this.existingLandDocuments);
    this.populateFilesFormArray('legalOpinion', this.existingLegalOpinions);
    this.populateFilesFormArray('floorPlans', this.existingFloorPlans);
    this.populateFilesFormArray('renders', this.existingRenders);
  }


  populateFilesFormArray(type: string, files: PropertyFile[]): void {
    const formArray = this.propertyForm.get(type) as FormArray;
    formArray.clear(); // Clear existing controls, if any
    files.forEach(file => formArray.push(this.fb.control(file)));
  }

  removeFile(arrayName: string, index: number): void {
    switch (arrayName) {
      case 'propertyImages':
        this.existingPropertyImages.splice(index, 1);
        break;
      case 'landDocuments':
        this.existingLandDocuments.splice(index, 1);
        break;
      case 'legalOpinion':
        this.existingLegalOpinions.splice(index, 1);
        break;
      case 'floorPlans':
        this.existingFloorPlans.splice(index, 1);
        break;
      case 'renders':
        this.existingRenders.splice(index, 1);
        break;
    }
  }


  viewImage(imageUrl: string): void {
    this.selectedImageUrl = imageUrl;
    this.displayDialog = true;
  }

  viewDocument(documentUrl: string): void {
    this.selectedDocumentUrl = documentUrl;
    this.displayDialog = true;
  }

  updateEachShareValue(){
    const value=this.propertyForm.get('value')?.value;
    const shares = this.propertyForm.get('no_of_shares')?.value;
    this.propertyForm.patchValue({
      value_of_each_share:value/shares
    });
    this.updateReleasedShares()
    
  }

  updateReleasedShares(){
    if(this.propertyForm.get('blocked_shares_by_admin')?.value > this.propertyForm.get('no_of_shares')?.value){
      this.toast.error('Blocked Shares should be < or =  No. of Shares')
      this.propertyForm.patchValue({
        released_shares: null,
        blocked_shares_by_admin:null
      })
    }
    else{
      this.propertyForm.patchValue({
        released_shares: this.propertyForm.get('no_of_shares')?.value - this.propertyForm.get('blocked_shares_by_admin')?.value
      })
    }
  }

  updateLeaseterm(){
    this.propertyForm.patchValue({
      lease_term:this.propertyForm.get('tenure')?.value
    })
    
  }
  updateLeaseEndAndLockinEndDate() {
    const lease_term = this.propertyForm.get('lease_term')?.value; // Lease term in months
    const lease_start_date = this.propertyForm.get('lease_start_date')?.value; // Start date in 'yyyy-MM-dd' format
    const lock_in_period= this.propertyForm.get('lock_in_period')?.value;

    if(lock_in_period>lease_term){
      this.toast.error('lock in period should be < or = tenure')
      this.propertyForm.patchValue({
        lock_in_period:null
      })
    }

    if (lease_start_date && lease_term) {
      const new_lease_start_date = new Date(lease_start_date);
  
      // Calculate the new lease end date by adding the lease term (months)
      const lease_end_date = new Date(new_lease_start_date);
      lease_end_date.setMonth(new_lease_start_date.getMonth() + lease_term);
  
      // Check if the resulting date is valid; adjust if necessary
      if (lease_end_date.getDate() !== new_lease_start_date.getDate()) {
        lease_end_date.setDate(0); // Adjust to the last valid date of the month
      }
  
      // Convert the lease_end_date to the format 'yyyy-MM-dd'
      const formattedDate = lease_end_date.toISOString().split('T')[0];
  
      // Update the form control with the correctly formatted date
      this.propertyForm.patchValue({
        lease_end_date: formattedDate
      });

      if(lease_start_date && lease_term && lock_in_period){

        const new_lease_start_date = new Date(lease_start_date);
  
        // Calculate the new lease end date by adding the lease term (months)
        const lock_in_end_date = new Date(new_lease_start_date);
        lock_in_end_date.setMonth(new_lease_start_date.getMonth() + lock_in_period);
        
        const formattedLockinEndDate = lock_in_end_date.toISOString().split('T')[0];

        this.propertyForm.patchValue({
          lock_in_end_date:formattedLockinEndDate
        })
      }
    }
  }
  

  validateAccountNumber(){
    if(this.propertyForm.get('account_number')?.value !== this.propertyForm.get('account_number_conf')?.value){
      this.toast.error('Account Number Does not match please check!')
      this.propertyForm.patchValue({
        account_number:null,
        account_number_conf:null
      })
    }
  }

  validateBuiltupArea(){
    if(this.propertyForm.get('built_up_area_sq_yards')?.value> this.propertyForm.get('land_area_sq_yards')?.value){
      this.toast.error('Built Up Area should be < or = Land Area')
      this.propertyForm.patchValue({
        built_up_area_sq_yards:null
      })
    }
  }
  


  // Helper function to append existing files to FormData
  appendExistingFilesToFormData(formData: FormData, fieldName: string, existingFiles: PropertyFile[]): void {
    existingFiles.forEach((file, index) => {
      formData.append(`${fieldName}[existing][${index}]`, JSON.stringify(file));
    });
  }


  onSubmit() {
    if (this.propertyForm.valid) {
      // Convert FormGroup to FormData
      const formData = new FormData();
      const formValue = this.propertyForm.getRawValue();
  
      // Append regular form fields to FormData
      for (const key in formValue) {
        if (formValue.hasOwnProperty(key)) {
          const value = formValue[key];

          if (typeof value === 'object' && !Array.isArray(value)) {
            // If the value is an object (like an address), stringify it
            formData.append(key, JSON.stringify(value));
          } else if (!Array.isArray(value)) {
            // If the value is a simple type (not an object or array), append it directly
            formData.append(key, value);
          }
        }
      }
  
      // Append file arrays to FormData
      ['landDocuments', 'legalOpinion', 'propertyImages', 'floorPlans', 'renders'].forEach(field => {
        const filesArray = this.propertyForm.get(field) as FormArray;
        filesArray.controls.forEach((control, index) => {
          formData.append(`${field}[${index}]`, control.value);
        });
      });
      
      if(this.propertyId){
        // Append existing files data to FormData
        this.appendExistingFilesToFormData(formData, 'propertyImages', this.existingPropertyImages);
        this.appendExistingFilesToFormData(formData, 'landDocuments', this.existingLandDocuments);
        this.appendExistingFilesToFormData(formData, 'legalOpinion', this.existingLegalOpinions);
        this.appendExistingFilesToFormData(formData, 'floorPlans', this.existingFloorPlans);
        this.appendExistingFilesToFormData(formData, 'renders', this.existingRenders);
        const toastRef = this.toast.loading('Updating Property! Please wait...',{
          autoClose: false
        });
        this.apiService.updateProperty(this.propertyId,formData).subscribe(response => {
          if (!response.error) {
            toastRef.close();
            this.router.navigate(['/all-properties']);
            this.toast.success('Property Added Successfully!');
          } else {
            this.toast.error('Something went wrong. Please try again later.');
          }
        });
      }
      else{
        const toastRef = this.toast.loading('Onboarding Property! Please wait...',{
          autoClose: false
        });
        this.apiService.addProperty(formData).subscribe(response => {
          if (!response.error) {
            toastRef.close();
            this.router.navigate(['/all-properties']);
            this.toast.success('Property Added Successfully!');
          } else {
            this.toast.error('Something went wrong. Please try again later.');
          }
        });
      }
    }
  }  
}

<div class="dashboard section__space__bottom">
    <div class="container">
        <div class="dashboard__area">
            <div class="row">
                <div class="col-xxl-3">
                    <app-navigation></app-navigation>
                </div>
                <div class="col-xxl-9">
                    <div class="main__content">
                        <div class="collapse__sidebar">
                            <h4>Dashboard</h4>
                            <a href="javascript:void(0)" class="collapse__sidebar__btn">
                                <i class="fa-solid fa-bars-staggered"></i>
                            </a>
                        </div>
                        <div class="main__content-dashboard">
                            <div class="breadcrumb-dashboard">
                                <h5>Transactions<span *ngIf="trasactions">({{trasactions.length}})</span></h5>
                                <div>
                                    <a href="dashboard" *ngIf="role=='investor'">Dashboard</a>
                                    <a href="users" *ngIf="role=='admin'">Users</a>
                                    <a href="all-properties" *ngIf="role=='support'">Properties</a>
                                    <i class="fa-solid fa-arrow-right-long"></i>
                                    <a href="javascript:void(0)">Transactions</a>
                                </div>
                            </div>
                            <div class="investment-table investment-table-two">
                                <div class="intro" *ngIf="role=='admin'">
                                    <h5></h5>
                                    <button class="button button--effect" (click)="addTrasaction()">Add Transaction</button>
                                </div>
                                <div class="table-wrapper">
                                    <table>
                                        <tr>
                                            <th>Project</th>
                                            <th>Amount</th>
                                            <th>Transaction Type</th>
                                            <th>Payment Type</th>
                                            <th>Date / Time</th>
                                        </tr>
                                        <tr *ngIf="trasactions && trasactions.length === 0">
                                            <td colspan="5" class="text-center">No transactions found</td>
                                        </tr>
                                        <tr *ngFor="let trasaction of trasactions">
                                            <td>
                                                {{trasaction.property_name}}
                                            </td>
                                            <td><i class="fa-solid fa-inr"></i>{{trasaction.amount}}</td>
                                            <td>{{trasaction.trasaction_type | titlecase}}</td>
                                            <td>{{trasaction.method | titlecase}}</td>
                                            <td>{{trasaction.transaction_date | date : 'medium'}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #trasactionAddModal let-modal>
    <div class="modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title model-header-text">Add Transaction</h5>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="transactionForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="investor">Investor</label>
                        <select id="investor" class="form-control" formControlName="investor" (change)="onInvestorChange($event)">
                            <option *ngFor="let investor of investors" [value]="investor.id" class="pointer">
                              {{investor.firstname}} {{investor.lastname}}
                            </option>
                          </select>
                          <p class="error-text">** Select Investor to get Investment properties</p>
                    </div>
                    <div class="form-group">
                        <label for="property">Property</label>
                        <select id="property" formControlName="property" class="form-control">
                            <option *ngFor="let prop of properties" [value]="prop.property_id">
                                {{prop.name}}
                            </option>
                        </select>
                    </div>

                    

                    <div class="form-group">
                        <label for="amount">Amount</label>
                        <input type="number" id="amount" formControlName="amount" class="form-control">
                    </div>
                    <div class="form-group">
                        <label for="method">Payment Method</label>
                        <input type="text" id="method" formControlName="method" class="form-control">
                    </div>
                    <div class="form-group">
                        <label for="method">Reference ID</label>
                        <input type="text" id="method" formControlName="reference_id" class="form-control">
                    </div>
                    <div class="button-container">
                        <button type="submit" class="button button--effect"
                            [disabled]="!transactionForm.valid">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../shared/services/api.service';
import { AuthService } from '../shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-channelpartner-referls',
  templateUrl: './channelpartner-referls.component.html',
  styleUrls: ['./channelpartner-referls.component.scss']
})
export class ChannelpartnerReferlsComponent {

  referalls:any;

  constructor(
    private router:Router,
    private apiService:ApiService, 
    private authService: AuthService,
    private toastr:ToastrService
  ){
      if(authService.isAuthenticated() && authService.getData().role !== 'channelpartner') {
        this.authService.redirectedToUserPages()
      }
      this.getReferls()
  }


  getReferls() {
    this.apiService
    .getChannelpartnerreferls()
    .subscribe(resp => {
      if(resp['status']) {
        this.referalls = resp['data']
      }
    });
  }

  addReferal() {
    this.router.navigate(['add-referral'])
  }

  updateReferal(referralId:any) {
    this.router.navigate(['update-referral', referralId]);
  }

  deleteReferal(referralId:any) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        this.apiService.deleteReferel(referralId).subscribe(response => {
          this.getReferls();
        });
      }
    });
  }

}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {
  @Input() title: string = '';         // Title input
  @Input() link: string = '';          // Link input
  @Input() item: string = '';          // Path input
}

<div class="fixed-icons" [class.active]="isActive">
  <a href="whatsapp://send?phone=917275252528&text=Welcome to 22Yards!" target="_blank" title="Share on whatsapp"><img src="../../assets/images/whatsapp.png"></a>
  <a href="https://www.instagram.com/22_yards_proptech/" title="Instagram" target="_blank"><img src="../../assets/images/instagram.png"></a>
  <a href="https://www.youtube.com/@22_Yards_Proptech" title="Youtube" target="_blank"><img src="../../assets/images/youtube.png"></a>
  <a href="https://www.facebook.com/people/22Yards/61563374311398/" title="Facebook" target="_blank"><img src="../../assets/images/facebook.png"></a>
  <a [routerLink]="['/contact-us']" title="help" target="_blank"><img src="../../assets/images/help-desk.png"></a>
</div>

<section class="support__banner contact__banner bg__img clear__top">
  <div class="container">
    <div class="support__banner__area">
      <div class="support__banner__inner">
        <h1 class="neutral-top">How can we help?</h1>
        <h5 class="neutral-top">Got a question?</h5>
      </div>
    </div>
  </div>
</section>

<section class="contact__overview">
  <div class="container">
    <div class="contact__overview__area">
      <div class="row" style="justify-content: center;">
        <div *ngFor="let item of contactOverviewData" class="col-md-6 col-xl-4">
          <div class="contact__overview__single column__space--secondary shadow__effect">
            <img [src]="item.icon" alt="contact icon" />
            <h5>{{ item.tilte }}</h5>
            <p>{{ item.desc }}</p>
            <hr />
            <p class="neutral-bottom" style="height: 50px;">
              <a [href]="item.link">{{ item.contact }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section class="ask section__space bg__img">
  <div class="container">
    <div class="ask__area">
      <div class="alert__newsletter__area">
        <div class="section__header">
          <h2 class="neutral-top">Ask a Question</h2>
        </div>
        <!-- <form name="askFrom" (ngSubmit)="onSubmit()" [formGroup]="askForm">
          <div class="row">
            <div class="col-sm-6">
              <div class="input input--secondary">
                <label for="askFirstName"><span style="color: red;">*</span>First Name</label>
                <input
                  type="text"
                  name="ask__first__name"
                  id="askFirstName"
                  placeholder="Enter Your First Name"
                  required="required"
                  formControlName="firstName"
                />
              </div>
            </div>
            <div class="col-sm-6">
              <div class="input input--secondary">
                <label for="askLastName"><span style="color: red;">*</span>Last Name</label>
                <input
                  type="text"
                  name="ask__last__name"
                  id="askLastName"
                  placeholder="Enter Your Last Name"
                  required="required"
                  formControlName="lastName"
                />
              </div>
            </div>
          </div>
          <div class="input input--secondary">
            <label for="askRegistrationMail"><span style="color: red;">*</span>Email</label>
            <input
              type="email"
              name="ask__registration__email"
              id="askRegistrationMail"
              placeholder="Enter your email"
              required="required"
              formControlName="email"
            />
          </div>
          <div class="input input--secondary input__alt">
            <label for="askNumber"><span style="color: red;">*</span>Phone</label>
            <div class="input-group">
              <div class="input-group-prepend current select">
                <select
                  class="number__code__select fw-semibold"
                  id="numberCodeSelectAlert"
                  formControlName="countryCode"
                >
                  <option value="1">+91</option>
                </select>
              </div>
              <input
                type="number"
                name="ask__number"
                id="askNumber"
                required="required"
                placeholder="345-323-1234"
                formControlName="phoneNumber"
              />
            </div>
          </div>
          <div class="input input--secondary">
            <label for="askSubject"><span style="color: red;">*</span>Subject</label>
            <input
              type="text"
              name="ask__subject"
              id="askSubject"
              placeholder="Write Subject"
              required="required"
              formControlName="subject"
            />
          </div>
          <div class="input input--secondary">
            <label for="askMessage"><span style="color: red;">*</span>Message</label>
            <textarea
              name="ask_message"
              id="askMessage"
              required="required"
              placeholder="Write Message"
              formControlName="message"
            ></textarea>
          </div>
          <div class="input__button">
            <button type="submit" class="button button--effect">
              Submit your Query!
            </button>
          </div>
        </form> -->
        <app-crm-form></app-crm-form>
      </div>
    </div>
  </div>
</section>

<section class="faq section__space border">
  <div class="container">
    <div class="faq__group">
      <div class="accordion">

          
  <div class="accordion-item" *ngFor="let item of faqs; let i = index">
    <h5 class="accordion-header" [attr.id]="'headingFundOne' + i">
      <span class="icon_box">
        <img src="assets/images/icons/message.png" alt="message" />
      </span>
      <button
        class="accordion-button"
        [ngClass]="{'collapsed': i !== 0}"
        type="button"
        [attr.data-bs-toggle]="'collapse'"
        [attr.data-bs-target]="'#collapseFundOne' + i"
        [attr.aria-expanded]="i === 0"
        [attr.aria-controls]="'collapseFundOne' + i"
      >
        {{ item.title }}
      </button>
    </h5>
    <div
      [attr.id]="'collapseFundOne' + i"
      class="accordion-collapse collapse"
      [ngClass]="{'show': i === 0}"
      [attr.aria-labelledby]="'headingFundOne' + i"
    >
      <div class="accordion-body">
        <p>{{ item.details }}</p>
      </div>
    </div>
  </div>

</div>
</div>
</div>
</section>
import { Component } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {  
  role:any;
  is_kyc_completed:any;
  constructor(
    private auth: AuthService,
    private apiService:ApiService, 

  ){
    this.role = this.auth.getData().role;
    this.apiService.getVerificationStatus( this.auth.getData().user_id).subscribe(response => {
      this.is_kyc_completed = response['is_kyc_completed']
      localStorage.setItem('is_kyc_completed',this.is_kyc_completed);
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit{
  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router:Router,
    private apiService: ApiService,
    private authService: AuthService

  ) {
      if(authService.isAuthenticated()) {
        this.authService.redirectedToUserPages()
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const token = params['token'];
      if (token) {
        this.verifyEmail(token);
      }
    });
  }

  verifyEmail(token: string): void {
    const token_data={
      'token':token
    }
    this.apiService.verifyEmail(token_data).subscribe(response=>{
      if (response.access_token && response.refresh_token) {
        localStorage.setItem('access_token', response.access_token);
        localStorage.setItem('refresh_token', response.refresh_token);
        localStorage.setItem('last_login',response.user_last_login);
        const decodedToken = this.authService.decodeToken(response.access_token);
        if (decodedToken.role === 'admin') {
          this.router.navigate(['users']);
        } else if (decodedToken.role === 'channelpartner') {
          this.router.navigate(['referrals']);
        } else if (decodedToken.role === 'support') {
          this.router.navigate(['all-properties']);
        } else {
          this.router.navigate(['dashboard'])
        }
      }
      else{
        this.toastr.error(response.message,'Email Verification Failed')
      }
    })
  }
}

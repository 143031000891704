<div class="dashboard section__space__bottom">
    <div class="container">
        <div class="dashboard__area">
            <div class="row">
                <div class="col-xxl-3">
                    <app-navigation></app-navigation>
                </div>
                <div class="col-xxl-9">
                    <div class="main__content">
                        <div class="collapse__sidebar">
                            <h4>Dashboard</h4>
                            <a href="javascript:void(0)" class="collapse__sidebar__btn">
                                <i class="fa-solid fa-bars-staggered"></i>
                            </a>
                        </div>
                        <div class="main__content-dashboard">
                            <div class="breadcrumb-dashboard">
                                <h5>Account</h5>
                                <div>
                                    <a href="dashboard">Dashboard</a>
                                    <i class="fa-solid fa-arrow-right-long"></i>
                                    <a href="javascript:void(0)">Account</a>
                                </div>
                            </div>
                            <div class="account-info">
                                <div class="account-info__btn-wrapper">
                                    <a href="#general" class="account-info__btn account-info__btn-active button button--effect">Personal Information</a>
                                    <a href="#address" class="account-info__btn button button--effect">Address</a>
                                    <a *ngIf="is_kyc_completed" href="#kyc" class="account-info__btn button button--effect">KYC Details</a>
                                    <a *ngIf="is_kyc_completed" href="#bank" class="account-info__btn button button--effect">Bank Details</a>
                                </div>
                                <div class="account-content_wrapper">
                                    <div class="account-content" id="general">
                                        <form [formGroup]="personalDetailsForm" (ngSubmit)="onSubmitPersonalDetails()" name="save__from" class="save__form">
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="input input--secondary regi__type">
                                                        <label for="firstname">First Name<span class="requried">*</span></label>
                                                        <input type="text" formControlName="firstname" placeholder="Enter your First Name" required />
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="input input--secondary regi__type">
                                                        <label for="lastname">Last Name<span class="requried">*</span></label>
                                                        <input type="text" formControlName="lastname" placeholder="Enter your Last Name" required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input input--secondary regi__type">
                                                <label for="father_name">Father Name<span class="requried">*</span></label>
                                                <input type="text" formControlName="father_name" placeholder="Enter your Father Name" required />
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="input input--secondary regi__type">
                                                        <label for="email">Email<span class="requried">*</span></label>
                                                        <input type="email" formControlName="email" placeholder="Enter your Email" required />
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="input input--secondary regi__type">
                                                        <label for="phone_number">Phone Number<span class="requried">*</span></label>
                                                        <br/>
                                                        <ngx-intl-tel-input
                                                        [cssClass]="'form-control'"
                                                        [preferredCountries]="preferredCountries"
                                                        [enableAutoCountrySelect]="true"
                                                        [enablePlaceholder]="true"
                                                        [searchCountryFlag]="true"
                                                        [searchCountryField]="[
                                                            SearchCountryField.Iso2,
                                                            SearchCountryField.Name
                                                        ]"
                                                        [selectFirstCountry]="false"
                                                        [selectedCountryISO]="CountryISO.India"
                                                        [maxLength]="15"
                                                        [phoneValidation]="true"
                                                        [separateDialCode]="separateDialCode"
                                                        [numberFormat]="PhoneNumberFormat.National"
                                                        name="phone"
                                                        formControlName="phone_number"
                                                        >
                                                        </ngx-intl-tel-input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="input input--secondary regi__type">
                                                        <label for="date_of_birth">Date of Birth<span class="requried">*</span></label>
                                                        <input type="date" formControlName="date_of_birth" placeholder="Enter your Date of Birth" required />
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="input input--secondary regi__type">
                                                        <label for="gender">Gender<span class="requried">*</span></label>
                                                        <select class="nice-select type__select" formControlName="gender">
                                                            <option value="" disabled selected>Slect your Gender</option>
                                                            <option value="male">Male</option>
                                                            <option value="female">Female</option>
                                                          </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input__button">
                                                <button type="submit" class="button button--effect">Save Changes</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="account-content" id="address">
                                        <form [formGroup]="addressDetailsForm" (ngSubmit)="onSubmitAddressDetails()" name="save__from" class="save__form">
                                            <div class="input input--secondary regi__type">
                                                <label for="area">D.No., Street and Area<span class="requried">*</span></label>
                                                <input type="text" formControlName="area" placeholder="Address - Area and Street" required/>
                                              </div>
                                              <div class="row">
                                                <div class="col-sm-6">
                                                  <div class="input input--secondary regi__type">
                                                    <label for="country">Country<span class="requried">*</span></label>
                                                    <select class="nice-select type__select" formControlName="country" (change)="onCountryChange($event)" required>
                                                      <option value="">Select Country</option>
                                                      <option *ngFor="let country of countries" [value]="country.isoCode">{{ country.name }}</option>
                                                    </select>
                                                  </div>
                                                </div>
                                                <div class="col-sm-6">
                                                  <div class="input input--secondary regi__type">
                                                    <label for="state">State / Province / Region<span class="requried">*</span></label>
                                                    <select class="nice-select type__select" formControlName="state" (change)="onStateChange($event)" required>
                                                      <option value="">Select State</option>
                                                      <option *ngFor="let state of states" [value]="state.isoCode">{{ state.name }}</option>
                                                    </select>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="row">
                                                <div class="col-sm-6">
                                                  <div class="input input--secondary regi__type">
                                                    <label for="city">City<span class="requried">*</span></label>
                                                    <select class="nice-select type__select" formControlName="city" required (change)="onCityChange($event)">
                                                      <option value="">Select City</option>
                                                      <option *ngFor="let city of cities" [value]="city.name">{{ city.name }}</option>
                                                      <option value="Other">Other</option> <!-- Add this option -->
                                                    </select>
                                                  </div>
                                                </div>
                                                <div class="col-sm-6">
                                                  <div class="input input--secondary regi__type">
                                                    <label for="pincode">Pincode<span class="requried">*</span></label>
                                                    <input type="number" formControlName="pincode" placeholder="Enter Pincode" required />
                                                  </div>
                                                </div>
                                                
                                                <div *ngIf="isOtherCitySelected" class="input input--secondary">
                                                    <label for="otherCity">Enter City Name<span class="requried">*</span></label>
                                                       <input type="text" formControlName="cityInput" id="otherCity" placeholder="Enter your city name" required/>
                                                    </div>
                                              </div>
                                            <div class="input__button">
                                                <button type="submit" class="button button--effect">Save
                                                    Changes</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div *ngIf="is_kyc_completed  && profileDetails" class="account-content" id="kyc">
                                        
                                        <div class="input input--secondary regi__type kyc">
                                            <div class="kyc-verified">
                                                <img src="../../assets/images/success.png">
                                                <p>You're all set! Your KYC is complete and verified.</p>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-4">
                                                <div class="input input--secondary regi__type">
                                                    <p>Document Type</p>
                                                    <label>{{ profileDetails.document_type }}</label>
                                                </div>
                                            </div>

                                            <div class="col-sm-4">
                                                <div class="input input--secondary regi__type">
                                                    <p>Document Number</p>
                                                    <label>{{ profileDetails.document_number }}</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="input input--secondary regi__type">
                                                    <p>Pan Number</p>
                                                    <label>{{ profileDetails.pan_number }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <div class="input input--secondary regi__type">
                                                    <p>Document Front</p>
                                                    <label>{{document_front.name}} &ensp;<fa-icon [icon]="faEye" (click)="viewImage(document_front.url)"></fa-icon></label>
                                                    <p-dialog header="Image View" [(visible)]="displayDialog" [modal]="true" [style]="{height: '30vw'}">
                                                        <img [src]="selectedImageUrl" alt="Image" />
                                                    </p-dialog>
                                                </div>
                                            </div>

                                            <div class="col-sm-4">
                                                <div class="input input--secondary regi__type">
                                                    <p>Document Back</p>
                                                    <label>{{document_back.name}} &ensp;<fa-icon [icon]="faEye" (click)="viewImage(document_back.url)"></fa-icon></label>
                                                    <p-dialog header="Image View" [(visible)]="displayDialog" [modal]="true" [style]="{height: '30vw'}">
                                                        <img [src]="selectedImageUrl" alt="Image" />
                                                    </p-dialog>
                                                </div>
                                            </div>

                                            <!-- <div class="col-sm-4">
                                                <div class="input input--secondary regi__type">
                                                    <p>Pan Card</p>
                                                    <label>{{pan_card.name}}&ensp;<fa-icon [icon]="faEye" (click)="viewImage(pan_card.url)"></fa-icon></label>
                                                    <p-dialog header="Image View" [(visible)]="displayDialog" [modal]="true" [style]="{height: '30vw'}">
                                                        <img [src]="selectedImageUrl" alt="Image" />
                                                    </p-dialog>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div *ngIf="is_kyc_completed && profileDetails" class="account-content" id="bank">
                                        <div class="input input--secondary regi__type kyc">
                                            <div class="kyc-verified">
                                                <img src="../../assets/images/success.png">
                                                <p>You're all set! Your Bank is verified!</p>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="input input--secondary regi__type">
                                                    <p>Account Name</p>
                                                    <label>{{ profileDetails.account_name }}</label>
                                                </div>
                                            </div>

                                            <div class="col-sm-6">
                                                <div class="input input--secondary regi__type">
                                                    <p>Account Number</p>
                                                    <label>{{ profileDetails.account_number }}</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="input input--secondary regi__type">
                                                    <p>Bank Name</p>
                                                    <label>{{ profileDetails.bank_name }}</label>
                                                </div>
                                            </div>

                                            <div class="col-sm-6">
                                                <div class="input input--secondary regi__type">
                                                    <p>IFSC Code</p>
                                                    <label>{{ profileDetails.ifsc_code }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-banner 
  [title]="'Shipping & Delivery polocies'"
  [link]="'/shipping-and-delivery'"
  [item]="'Shipping & Delivery'">
</app-banner>

<section class="support__banner clear__top">
    <div class="container" >
      <div class="support__banner__area">
        <h2 style="font-size: 50px; text-align: center;">Shipping and Delivery</h2>
        <div class="support__banner__inner banner_inner1">
         
        
        <p  style="padding-bottom: 50px;">Since 22Yards deals with real estate investment and not physical goods, there are no shipping and delivery policies applicable. All relevant documents and contracts are transferred electronically or handled through secure platforms as per legal requirements.
</p>
        </div>
        
      </div>
    </div>
  </section>
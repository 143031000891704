<div class="container" style="background-color: white; color: black; max-width: 600px;">
    <form [formGroup]="webToLeadForm" (ngSubmit)="onSubmit()" novalidate>
        <div class="row" style="margin-top: 2rem;">
            <div class="col-sm-6">
              <div class="input input--secondary">
                <label for="FirstName"><span style="color: red;">*</span>First Name</label>
                <input
                  type="text"
                  name="first__name"
                  id="FirstName"
                  placeholder="Enter Your First Name"
                  required="required"
                  formControlName="firstName"
                />
              </div>
            </div>
            <div class="col-sm-6">
              <div class="input input--secondary">
                <label for="LastName"><span style="color: red;">*</span>Last Name</label>
                <input
                  type="text"
                  name="last__name"
                  id="LastName"
                  placeholder="Enter Your Last Name"
                  required="required"
                  formControlName="lastName"
                />
              </div>
            </div>
        </div>

        <div class="input input--secondary">
            <label for="Email"><span style="color: red;">*</span>Email</label>
            <input
              type="email"
              name="email"
              id="Email"
              placeholder="Enter your email"
              required="required"
              formControlName="email"
            />
        </div>


        <div class="input input--secondary">
            <label for="phone"><span style="color: red;">*</span>Phone Number</label>
            <div style="width: 100%;">
                <ngx-intl-tel-input
                    [cssClass]="'form-control'"
                    [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true"
                    [searchCountryFlag]="true"
                    [searchCountryField]="[
                    SearchCountryField.Iso2,
                    SearchCountryField.Name
                    ]"
                    [selectFirstCountry]="false"
                    [selectedCountryISO]="CountryISO.India"
                    [maxLength]="15"
                    [phoneValidation]="true"
                    [separateDialCode]="separateDialCode"
                    [numberFormat]="PhoneNumberFormat.National"
                    name="phone"
                    formControlName="mobile"
                >
                </ngx-intl-tel-input>
            </div>
          </div>

        <div class="input input--secondary">
            <label for="description">Description</label>
            <textarea
                style="padding: 10px;"
              name="description"
              id="description"
              placeholder="Describe your inquiry or provide additional details"
              formControlName="description"
            ></textarea>
        </div>
        <div class="input__button" style="margin-bottom: 2rem;">
        <button type="submit" class="button button--effect" [disabled]="webToLeadForm.invalid">
            Submit
        </button>
        </div>
    </form>
  </div>
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { EncryptionService } from './encryption.service';
import { jwtDecode } from 'jwt-decode';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root',
})
export class AuthService {

  jwt = new JwtHelperService();


  constructor(
    private router:Router,
    private toastr:ToastrService,
    private cookieService: CookieService,
    private encryptionService: EncryptionService
  ) {}

  isAuthenticated() {
    const token = localStorage.getItem('access_token');
    if (token) {
      return !this.jwt.isTokenExpired(token);
    } else {
      return false;
    }
  }


  setRememberMe(username: string, password: string): void {
    const encryptedPassword = this.encryptionService.encrypt(password);
    this.cookieService.set('username', username);
    this.cookieService.set('password', encryptedPassword);
  }

  getRememberedUser(): { username: string; password: string } | null {
    const username = this.cookieService.get('username');
    const encryptedPassword = this.cookieService.get('password');
    if (username && encryptedPassword) {
      const password = this.encryptionService.decrypt(encryptedPassword);
      return { username, password };
    }
    return null;
  }

  clearRememberedUser(): void {
    this.cookieService.delete('username');
    this.cookieService.delete('password');
  }

  decodeToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }

  getData() {
    const token = localStorage.getItem('access_token');
    if(token) {
      return this.decodeToken(token)
    }
  }


  redirectedToUserPages() {
    const token = localStorage.getItem('access_token');
    if(token) {
      const role = this.decodeToken(token).role
      if (role === 'admin') {
        this.router.navigate(['users']);
      } else if (role === 'channelpartner') {
        this.router.navigate(['referrals']);
      } else if (role === 'support') {
        this.router.navigate(['all-properties']);
      } else {
        this.router.navigate(['dashboard'])
      }
    }
  }


}

<section class="banner clear__top " >
    <div class="container ">
      <div class="banner__area">
        <h1 class="neutral-top">{{title}}</h1>
        <nav class="breadcrumb">
          <span style="padding-top: 3%;">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" style="font-size: 22px; font-weight: 600;">
               <a href=""><p style="font-size: 22px; font-weight: 600;">Home</p></a> 
              </li>
              <!-- <li class="breadcrumb-item">
                <a [href]="link">
                  <p style="font-size: 20px; font-weight: 600;">Pages</p>
                </a>
              </li> -->
              <li class="breadcrumb-item">
                <a [href]="link">
                  <p style="font-size: 22px; font-weight: 600;">{{item}}</p>
                </a>
              </li>
            </ol>
          </span>
        </nav>
      </div>
    </div>
  </section>
  
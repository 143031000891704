import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../shared/services/api.service';
import { AuthService } from '../shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ApexOptions } from 'ng-apexcharts';

interface Transaction {
  transaction_date: string;
  amount: number;
  trasaction_type: string;
}

@Component({
  selector: 'app-my-investments',
  templateUrl: './my-investments.component.html',
  styleUrls: ['./my-investments.component.scss']
})
export class MyInvestmentsComponent implements OnInit {
  transactions : any;
  totalIncome: number = 0;
  role: string;
  isLoading: boolean = true;
  investmentChartOptions: ApexOptions = {
    chart: {
      type: 'area',
      height: 530,
      zoom: { enabled: false },
      toolbar: { show: false },
      sparkline: { enabled: false }
    },
    colors: ['#1FAA5C'],
    series: [{ name: 'Invested', data: [] }],
    dataLabels: { enabled: false },
    stroke: { curve: 'smooth', width: 2 },
    fill: {
      type: 'gradient',
      colors: ['#1FAA5C'],
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 1,
        opacityTo: 0.1,
        stops: [0, 100]
      }
    },
    markers: { size: 5, strokeColors: '#51CCA9', strokeWidth: 2 },
    xaxis: { categories: [], axisTicks: { show: true } },
    yaxis: {
      show: true,
      opposite: false,
      labels: {
        formatter: (value: number) => `${value / 1000}K`
      }
    },
    legend: { horizontalAlign: 'left' },
    responsive: [{
      breakpoint: 767,
      options: {
        chart: {
          maxWidth: '100%',
          height: 250,
          sparkline: { enabled: false }
        }
      }
    }]
  };

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private apiService: ApiService,
    private authService: AuthService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) {
    if (authService.isAuthenticated() && !['admin', 'support', 'investor'].includes(authService.getData().role)) {
      this.authService.redirectedToUserPages();
    }
    this.role = authService.getData().role;
  }

  ngOnInit() {
    this.loadInvestorTransactions();
  }

  loadInvestorTransactions(): void {
    this.isLoading = true;
    this.apiService.getTrasactions().subscribe(
      (data: any) => {
        if (data && Array.isArray(data.transactions)) {
          this.transactions = data.transactions;
          this.totalIncome = data.total_income || 0;

          // Filter transactions to exclude those with 'income' type
          this.transactions = this.transactions.filter((transaction: Transaction) => transaction.trasaction_type !== 'income');
          
          this.updateChart();
        } else {
          console.error('Unexpected API response structure:', data);
          this.toastr.error('Unexpected data format received from the server.');
        }
        this.isLoading = false;
      },
      error => {
        console.error('API error:', error);
        this.toastr.error('Failed to load transactions. Please try again later.');
        this.isLoading = false;
      }
    );
  }

  prepareChartData(): void {
    if (!this.transactions || this.transactions.length === 0) {
      console.warn('No transactions available to prepare chart data.');
      this.investmentChartOptions.series = [{ name: 'Invested', data: [] }];
      this.investmentChartOptions.xaxis!.categories = [];
      return;
    }

    const dates = this.transactions.map((t: Transaction) => {
      const date = new Date(t.transaction_date);
      return `${date.getMonth() + 1}/${date.getDate()}`; // Format as MM/DD
    });
    const amounts = this.transactions.map((t: Transaction) => t.amount);

    // console.log('Chart dates:', dates);
    // console.log('Chart amounts:', amounts);

    this.investmentChartOptions = {
      ...this.investmentChartOptions,
      series: [{
        name: 'Invested',
        data: amounts
      }],
      xaxis: {
        categories: dates
      },
      yaxis: {
        show: true,
        opposite: false,
        labels: {
          formatter: (value: number) => `${value / 1000}K`
        }
      }
    };
  }

  updateChart(): void {
    this.prepareChartData();
    this.investmentChartOptions = { ...this.investmentChartOptions };
    this.cdr.detectChanges();
  }
}
<div class="dashboard section__space__bottom">
    <div class="container">
        <div class="dashboard__area">
            <div class="row">
                <div class="col-xxl-3">
                    <app-navigation></app-navigation>
                </div>
                <div class="col-xxl-9">
                    <div class="main__content">
                        <div class="collapse__sidebar">
                            <h4>Add User</h4>
                            <a href="javascript:void(0)" class="collapse__sidebar__btn">
                                <i class="fa-solid fa-bars-staggered"></i>
                            </a>
                        </div>
                        <div class="main__content-dashboard">
                            <div class="breadcrumb-dashboard">
                                <h5><span *ngIf="!referalId">Add</span>
                                    <span *ngIf="referalId">Update</span> Referral</h5>
                                <div>
                                    <a href="referrals">Referrals</a>
                                    <i class="fa-solid fa-arrow-right-long"></i>
                                    <a href="javascript:void(0)"><span *ngIf="!referalId">Add</span>
                                        <span *ngIf="referalId">Update</span> Referral</a>
                                </div>
                            </div>
                            <div class="row">
                            <div class="investment-table">
                                 <section >
                                <div class="container">
                                  <div class="registration__area">
                                    <h4 class="neutral-top">Propety Details</h4>
                                    <form [formGroup]="referalForm" (ngSubmit)="onSubmit()">
                                      <div class="regi__type">
                                        <label for="type">Property Type <span class="requried">*</span></label>
                                        <select class="nice-select type__select" formControlName="type" (change)="onPropertyTypeChange($event)">
                                            <option value="">Select Property Type</option>
                                            <option value="RETAIL">RETAIL</option>
                                            <option value="COMMERCIAL">COMMERCIAL</option>
                                            <option value="CO-LIVING">CO-LIVING</option>
                                            <option value="BARELAND">BARE LAND</option>
                                        </select>
                                        <p class="note">*** Please select property type to list the properties below ***</p>
                                      </div>
                                      <div class="regi__type">
                                        <label for="type">Property <span class="requried">*</span></label>
                                        <select class="nice-select type__select" formControlName="property">
                                            <option value="">Select Property</option>
                                          <ng-container *ngIf="properties.length > 0; else noProperties">
                                            <option *ngFor="let property of properties" [value]="property.property_id">{{ property.name }}</option>
                                          </ng-container>
                                        </select>
                                        <!-- Display this message when no properties are found -->
                                        <ng-template #noProperties>
                                          <option disabled value="">No properties found</option>
                                        </ng-template>
                                      </div>
                                      <div class="row">
                                        <div class="col-sm-12">
                                          <div class="input input--secondary">
                                            <label for="numberofshares">Number of Shares<span class="requried">*</span></label>
                                            <input type="number" formControlName="numberofshares" placeholder="No. Of Shares" required />
                                          </div>
                                        </div>
                                      </div>
                                      <h4 class="neutral-top next">User Details</h4>
                                      <div class="row">
                                        <div class="col-sm-6">
                                          <div class="input input--secondary">
                                            <label for="firstname">First Name<span class="requried">*</span></label>
                                            <input type="text" formControlName="firstname" placeholder="First Name" required />
                                          </div>
                                        </div>
                                        <div class="col-sm-6">
                                          <div class="input input--secondary">
                                            <label for="lastname">Last Name<span class="requried">*</span></label>
                                            <input type="text" formControlName="lastname" placeholder="Last Name" required />
                                          </div>
                                        </div>
                                      </div>
                                      <div class="input input--secondary">
                                        <label for="email">Email<span class="requried">*</span></label>
                                        <input type="email" formControlName="email" placeholder="Enter your email" required />
                                      </div>
                                      <div class="input input--secondary">
                                        <label for="phone">Phone Number<span class="required">*</span></label>
                                        <ngx-intl-tel-input
                                        [cssClass]="'form-control'"
                                        [preferredCountries]="preferredCountries"
                                        [enableAutoCountrySelect]="true"
                                        [enablePlaceholder]="true"
                                        [searchCountryFlag]="true"
                                        [searchCountryField]="[
                                          SearchCountryField.Iso2,
                                          SearchCountryField.Name
                                        ]"
                                        [selectFirstCountry]="false"
                                        [selectedCountryISO]="CountryISO.India"
                                        [maxLength]="15"
                                        [phoneValidation]="true"
                                        [separateDialCode]="separateDialCode"
                                        [numberFormat]="PhoneNumberFormat.National"
                                        name="phone"
                                        formControlName="phone_number"
                                      >
                                      </ngx-intl-tel-input>
                                        <div class="passwords-not-matched-error" *ngIf="referalForm.get('phone_number')?.invalid && referalForm.get('phone_number')?.touched">
                                          <div *ngIf="referalForm.get('phone_number')?.errors"><small class="text-danger">Enter a Valid Number</small></div>
                                        </div>
                                      </div>
                                                
                                      <div class="input__button">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <button class="button cancel-button" (click)="cancel()">
                                                    Cancel
                                                </button>
                                            </div>
                                            <div class="col-sm-6">
                                                <button type="submit" class="button button--effect" [disabled]="referalForm.invalid">
                                                    Submit
                                                  </button>
                                            </div>
                                            
                                        </div>
                                
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </section> 
                            </div>
                        </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="fixed-icons" [class.active]="isActive">
    <a href="whatsapp://send?phone=917275252528&text=Welcome to 22Yards!" target="_blank" title="Share on whatsapp"><img src="../../assets/images/whatsapp.png"></a>
    <a href="https://www.instagram.com/22_yards_proptech/" title="Instagram" target="_blank"><img src="../../assets/images/instagram.png"></a>
    <a href="https://www.youtube.com/@22_Yards_Proptech" title="Youtube" target="_blank"><img src="../../assets/images/youtube.png"></a>
    <a href="https://www.facebook.com/people/22Yards/61563374311398/" title="Facebook" target="_blank"><img src="../../assets/images/facebook.png"></a>
    <a [routerLink]="['/contact-us']" title="help" target="_blank"><img src="../../assets/images/help-desk.png"></a>
</div>

<!-- <div style="position: relative; height: 100vh;">
    <ngb-carousel [interval]="5000" [wrap]="true" [keyboard]="true" [pauseOnHover]="false" [showNavigationArrows]="false">
        <ng-template ngbSlide *ngFor="let item of images">
            <div class="carousel-img-wrapper">
                <img [src]="cmsUrl+item.image.url" alt="Random first slide" />
                <div style="display: flex; flex-direction: column; gap: 10px; justify-content: center; position: absolute; top: 0; width: 50%; height: 100%; padding: 1rem; background: rgba(0, 0, 0, 0.6); text-align: center;">
                    <h3 style="color: white;">{{ item.title }}</h3>
                    <h5 *ngIf="item.sub_title" style="color: white;">{{ item.sub_title }}</h5>
                    <p style="color: white; font-size: larger;">{{ item.description }}</p>
                    <a [routerLink]="item.property_id ? ['/property', item.property_id] : ['/properties']" class="button button--secondary button--effect" style="align-self: flex-end; width: 30%; margin-right: 25px;">{{ item.property_id ? 'Invest Now' : 'Start Exploring' }}</a>
                </div>
            </div>
            
        </ng-template>
    </ngb-carousel>
</div> -->

    <section class="hero pos__rel over__hi bg__img" data-background="./assets/images/hero/light-bg.png">
        <div class="container">
            <div class="hero__area">
                <div class="row">
                    <div class="col-lg-6 col-xxl-5">
                        <div class="hero__content">
                            <h5 class="neutral-top">A smarter, better way to invest</h5>
                            <h1>Real Estate Investment For <span>Everyone</span> </h1>
                            <p class="primary neutral-bottom">
                                Buy shares of rental properties, earn monthly income, and watch your money grow with <span style="font-size: larger; font-weight: bold;">22 Yards</span>
                            </p>
                            <div class="hero__cta__group">
                                <a href="properties" class="button button--effect">Start Exploring</a>
                                <a href="https://survey.22yardsprop.com" target="_blank" class="button button--secondary button--effect">Join Waitlist!</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xxl-6 offset-xxl-1">
                        <div class="hero__illustration d-none d-lg-block">
                            <img src="assets/images/hero/hero-illustration.png" alt="Hero Illustration" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- ==== why invest two section start ==== -->
    <section class="why__invest__two section__space">
        <div class="container">
            <div class="why__invest__two__area wow fadeInUp">
                <div class="section__header">
                    
                    <h2>Why Invest in 22Yards?</h2>
                    <p class="neutral-bottom">Embark on a rewarding property journey with the best fractional ownership Properties – a name built on trust and excellence.</p>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-xxl-3">
                        <div class="why__invest__two__single__item">
                            <img src="assets/images/icons/secure.png" alt="Secure Investment" />
                            <h5>Reliable and Profitable Investments</h5>
                            <p class="neutral-bottom">Secure your future with real estate opportunities that offer consistent returns and unmatched financial security.</p>
                        </div>
                    </div>
                    <div class="col-sm-6 col-xxl-3">
                        <div class="why__invest__two__single__item">
                            <img src="assets/images/icons/transparent.png" alt="Transparent Platform" />
                            <h5>Transparency You Can Rely On</h5>
                            <p class="neutral-bottom">We provide accurate property details and hassle-free transactions for a seamless experience</p>
                        </div>
                    </div>
                    <div class="col-sm-6 col-xxl-3">
                        <div class="why__invest__two__single__item">
                            <img src="assets/images/icons/cashout.png" alt="Cash out any time" />
                            <h5>Flexible investment Options</h5>
                            <p class="neutral-bottom">Invest with ease through flexible options, including regular interval contributions, making it simple to grow your portfolio.</p>
                        </div>
                    </div>
                    <div class="col-sm-6 col-xxl-3">
                        <div class="why__invest__two__single__item">
                            <img src="assets/images/icons/reinvest.png" alt="Reinvest" />
                            <h5>Grow with Smart Reinvestment</h5>
                            <p class="neutral-bottom">Maximize your wealth with 22Yards by reinvesting returns into high-yield assets, ensuring steady growth and financial stability over time.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- ==== #why invest section end ==== -->
    <!-- <div class="property__filter">
        <div class="container">
            <div class="property__filter__area">
                <div class="row d-flex align-items-center">
                    <div class="col-lg-12 col-xl-6">
                        <div class="property__search__wrapper">
                            <form action="#" method="post">
                                <div class="input">
                                    <input type="search" name="property__search" id="propertySearch"
                                        placeholder="Search for properties" />
                                    <i class="fa-solid fa-magnifying-glass"></i>
                                </div>
                                <button type="submit" class="button button--effect">Search</button>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-3">
                        <div class="property__select__wrapper">
                            <select class="location__select">
                                <option data-display="Location">Select Location</option>
                                <option value="angeles">Hyderabad</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-3">
                        <div class="property__select__wrapper">
                            <select class="property__select">
                                <option data-display="Property">Property Type</option>
                                <option value="commercial">Commercial</option>
                                <option value="residential">Residential</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- ==== featured properties in grid section start ==== -->
    <section class="properties__grid properties__grid--two section__space__top" *ngIf="featured_properties && featured_properties.length > 0">
        <div class="container">
            <div class="properties__grid__area wow fadeInUp">
                <div class="title__with__cta">
                    <div class="row d-flex align-items-center">
                        <div class="col-lg-8">
                            <h2>Featured Properties</h2>
                        </div>
                        <div class="col-lg-4">
                            <div class="text-start text-lg-end">
                                <a href="properties" class="button button--secondary button--effect">Browse All
                                    Properties</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="property__grid__wrapper">
                    <div class="row">
                        <div *ngFor="let property of featured_properties" class="col-lg-6 col-xl-4">
                            <div class="property__grid__single column__space--secondary" (click)="redirectToProperty(property.property_id)">
                                <div class="img__effect" *ngIf="!isImagesLoading">
                                    <!-- <p-carousel
                                      [value]="getPropertyImages(property)"
                                      [numVisible]="1" 
                                      [autoplayInterval]="3000"
                                      [numScroll]="1" 
                                      orientation="horizontal"
                                      styleClass="custom-carousel">
                                      <ng-template pTemplate="item" let-item>
                                        <img [src]="item.url" [alt]="item.name" class="carousel-image" />
                                      </ng-template>
                                    </p-carousel> -->
                                    <ngb-carousel [interval]="5000" [wrap]="true" [keyboard]="true" [pauseOnHover]="false" [showNavigationArrows]="false">
                                        <ng-template ngbSlide *ngFor="let item of getPropertyImages(property)">
                                            <div class="carousel-img-wrapper">
                                                <img [src]="item.url" alt="Random first slide" />
                                            </div> 
                                        </ng-template>
                                    </ngb-carousel>

                                </div>
                                <div class="property__grid__single__inner">
                                    <h4>{{ property.name }}</h4>
                                    <p class="sub__info"><i class="fa-solid fa-location-dot"></i> {{ property.location }}</p>
                                    <div class="progress__type">
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" aria-valuenow="25"
                                                aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <p class="project__has">
                                            <span class="project__has__investors">{{property.total_investors}} Investors</span> |
                                            <span class="project__has__investors__amount"><i class="fa-solid fa-inr"></i> {{property.total_investment}}</span>
                                            <span class="project__has__investors__percent">({{property.investor_percentage}}%)</span>
                                        </p>
                                    </div>
                                    <div class="item__info">
                                        <div class="item__info__single">
                                            <p>Annual Return</p>
                                            <h6>{{ property.ROI_min }}% - {{ property.ROI_max }}%</h6>
                                        </div>
                                        <div class="item__info__single">
                                            <p>Property Type</p>
                                            <h6>{{ property.type }}</h6>
                                        </div>
                                    </div>
                                    <div class="invest__cta__wrapper">
                                        <div class="invest__cta">
                                            <a [routerLink]="'/login'" class="button button--effect">
                                                Invest Now
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- ==== #featured properties in grid section end ==== -->

    <!-- ==== start step section start ==== -->
    <section class="start start--two start--three section__space">
        <div class="container">
            <div class="start__area wow fadeInUp">
                <div class="section__header">
                    <h5 class="neutral-top">We're changing the way you invest.</h5>
                    <h2>It's Easy to Get Started.</h2>
                    <p class="neutral-bottom">Signing up with 22 Yards is simple and only takes a few minutes.</p>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xl-4">
                        <div class="start__single__item column__space--secondary">
                            <div class="img__box">
                                <img src="assets/images/step/browse.png" alt="Browse Properties" />
                                <div class="step__count">
                                    <h4>01</h4>
                                </div>
                            </div>
                            <h4>Browse Properties</h4>
                            <p class="neutral-bottom">Select a property that fits your goal from our huge variety of
                                hand-picked properties.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4">
                        <div class="start__single__item column__space--secondary">
                            <div class="img__box arrow__container">
                                <img src="assets/images/step/invest.png" alt="View Details & Invest" />
                                <div class="step__count">
                                    <h4>02</h4>
                                </div>
                            </div>
                            <h4>View Details & Invest</h4>
                            <p class="neutral-bottom">View detailed metrics for that property like Rental Yield, etc.
                                and invest like institutions.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4">
                        <div class="start__single__item">
                            <div class="img__box">
                                <img src="assets/images/step/earn.png" alt="Earn and Track" />
                                <div class="step__count">
                                    <h4>03</h4>
                                </div>
                            </div>
                            <h4>Earn and Track</h4>
                            <p class="neutral-bottom">You have full visibility into the performance of your investment.
                                Track your total current.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- ==== #start step section end ==== -->
     <!-- ==== video popup section start ==== -->
    <div class="video">
        <div class="container">
            <div class="video__area">
                <img src="assets/images/Thumbnail.jpg" alt="Video Illustration" />
                <div class="video__btn">
                    <a class="video__popup" href="https://www.youtube.com/embed/watch?v=NY1Y_p1cU1s" target="_blank" title="YouTube video player">
                        <i class="fa-solid fa-play"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <!-- ==== #video popup section end ==== -->

    <!-- ==== numbers section start ==== -->
    <section class="numbers section__space bg__img" data-background="./assets/images/globe.png">
        <div class="container">
            <div class="numbers__area wow fadeInUp">
                <div class="row d-flex align-items-center">
                    <div class="col-lg-6">
                        <div class="content column__space">
                            <h5 class="neutral-top">Turn Your Dreams into Property Ownership</h5>
                            <h2>Categories We Offer</h2>
                            <p>We bring you an exclusive range of fractional ownership of luxury real estate tailored to fit every lifestyle and budget. Whether you're looking for high-yield investments or dream properties, we have you covered.</p>
                            <a href="properties" class="button button--effect">Start Exploring</a>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="row d-flex align-items-start align-items-lg-center">
                            <div class="col-sm-6">
                                <div class="numbers__single card shadow__effect">
                                    <div class="card__front">
                                        <img style="border-radius: 0;" src="assets/images/icons/hotel.png" alt="platform" />
                                        <h3><span>Hotels & Resorts</span></h3>
                                    </div>
                                    <div class="card__back">
                                        <p>Collaborate with renowned hospitality brands to develop or invest in premium hotels, resorts and retreats. Perfect for attracting both leisure tourists, Pilgrims and corporate travellers, these ventures promise robust ROI.</p>
                                    </div>
                                </div>
                                <div class="numbers__single card shadow__effect__secondary">
                                    <div class="card__front">
                                        <img style="border-radius:0 ;" src="assets/images/icons/commercial.png" alt="Returns" />
                                        <h3><span>Commercial Spaces</span></h3>
                                    </div>
                                    <div class="card__back">
                                        <p>Discover dynamic investment opportunities in commercial spaces, from high-end office buildings to bustling retail hubs. Each property is designed for long-term profitability and business growth.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="numbers__single card shadow__effect__secondary">
                                    <div class="card__front">
                                        <img style="border-radius: 0;" src="assets/images/icons/renewable-energy.png" alt="Experience" />
                                        <h3><span>Renewable Energy</span></h3>
                                    </div>
                                    <div class="card__back">
                                        <p>Go green while earning. Invest in high-efficiency solar farms designed for sustainable energy production, providing eco-friendly and lucrative returns.</p>
                                    </div>
                                </div>
                                <div class="numbers__single card shadow__effect__secondary">
                                    <div class="card__front">
                                        <img style="border-radius: 0;" src="assets/images/icons/land-bank.png" alt="Experience" />
                                        <h3><span>Land Banking</span></h3>
                                    </div>
                                    <div class="card__back">
                                        <p>Invest in prime land parcels strategically located in high-growth areas. Unlock potential appreciation and secure your financial future investments ideal for residential, commercial, or industrial developments.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!-- ==== #numbers section end ==== -->

    <!-- ==== testimonial section start ==== -->
    <section class="testimonial section__space pos__rel over__hi bg__img" data-background="./assets/images/testimonial/dot-map.png">
        <div class="container">
          <div class="testimonial__area">
            <div class="section__header">
              <h2>What Sets Us Apart?</h2>
              <h5 class="neutral-top">At 22 Yards Properties, we redefine property investments by making them accessible, secure, and rewarding.</h5>
             
            </div>
            <div class="testimonial__item__wrapper">
              <div class="testimonial__support">
                <div class="testimonial__item bg__img" data-background="./assets/images/testimonial/quote.png">
                  
                  <h3>Small Investment Sizes</h3>
                  <p class="tertiary">Start your fractional real estate journey with a minimal investment. We make premium properties and high-value projects accessible without requiring significant upfront capital.</p>
                </div>
              </div>
              <div class="testimonial__support">
                <div class="testimonial__item bg__img" data-background="./assets/images/testimonial/quote.png">
                  
                  <h3>End-to-End Asset Management</h3>
                  <p class="tertiary">Enjoy peace of mind knowing your investments are managed by our experienced team. We prioritize security, transparency, and accessibility, ensuring your assets remain safe and well-maintained.</p>
                </div>
              </div>
              <div class="testimonial__support">
                <div class="testimonial__item bg__img" data-background="./assets/images/testimonial/quote.png">
                  
                  <h3>Access to Premium Projects</h3>
                  <p class="tertiary">Invest in top real estate properties and projects that were previously out of reach. Our model allows you to participate in lucrative real estate opportunities with smaller financial commitments.</p>
                </div>
              </div>
              <div class="testimonial__support">
                <div class="testimonial__item bg__img" data-background="./assets/images/testimonial/quote.png">
                  
                  <h3>Long-Term Gains</h3>
                  <p class="tertiary">Stay with us and watch your wealth grow. Investors can expect up to 15% appreciation on their investments over time, making 22 Yards Properties a smart choice for sustainable financial growth.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    <!-- ==== #testimonial section end ==== -->
    <section *ngIf="blogs && blogs.length>0">
        <div style="margin-top: 3rem;" class="container">
            <h3 style="margin-bottom: 2rem;">Latest Blogs</h3>
            <div class="row altr">
                <div class="col-md-6 col-xl-4" *ngFor="let blog of blogs">
                    <div class="featured__large__post">
                        <a [routerLink]="['/blogs/',blog.slug]" class="thumbnail">
                            <img [src]="cmsUrl+blog.article_media[0].url" alt="Poster" />
                        </a>
                        <div class="blog__content">
                            <h5 class="truncated"><a [routerLink]="['/blogs/',blog.slug]">{{ blog.title }}</a></h5>
                            <div class="multiline-text--fallback" [innerHTML]="blog.content"></div>
                            <a [routerLink]="['/blogs/',blog.slug]" style="margin-top: 10px;">Read full Blog <i
                                    class="fa-solid fa-arrow-right-long"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div style="display: flex; justify-content: center;">
                <a style="color: #13216e;" href="/blogs">View All Blogs</a>
            </div>
        </div>
    </section>

    <!-- ==== market section start ==== -->
    <section style="margin-top: 5rem; margin-bottom: 3rem;" class="market  pos__rel over__hi">
        <div class="container">
            <div class="market__area">
                <div class="row d-flex align-items-center">
                    <div class="col-lg-6 col-xl-5">
                        <div class="market__thumb thumb__rtl column__space">
                            <img src="assets/images/market-illustration.png" alt="Explore the Market" />
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 offset-xl-1">
                        <div class="content">

                            <h2>Your Dream, 22 Yards Redeems</h2>
                            <p>Choose 22 Yards Properties for an investment experience that combines affordability, premium opportunities, and secure asset management. Join us now to unlock the full potential of real estate investments</p>
                            <a href="javascript:void(0);" class="button button--effect" (click)="openModal(content)">Connect with us!</a>
                            <img src="assets/images/arrow.png" alt="Go to" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- ==== #market section end ==== -->   
     
    <!-- <section style="margin-top: 10rem; margin-bottom: 3rem;" class="">
        <div class="container">
            <div class="market__area" style="padding: 15px;">
                <div class="row d-flex align-items-center">
                    <div class="col-lg-6 col-xl-5">
                        <div class="content column__space" style="display: flex; flex-direction: column; gap: 10px;">
                            <h2 class="neutral-top">Need Help?</h2>
                            <h5 class="neutral-top">Let’s Build Your Real Estate Journey Together!</h5>
                            <p>Questions about fractional real estate? Fill the form, and we’ll assist you. Start now!</p>
                            <img src="../../assets/images/contact-us.jpg">
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 offset-xl-1">
                        <div class="featured__large__post">
                            <app-crm-form></app-crm-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->


    <!-- Modal Template -->
<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Connect with Us</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <app-crm-form></app-crm-form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
    </div>
  </ng-template>
   


    <!-- Scroll To Top -->
    <a href="javascript:void(0)" class="scrollToTop">
        <i class="fa-solid fa-angles-up"></i>
    </a>

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DataService } from './data.service';


@Injectable({
  providedIn: 'root'
})
export class ApiService extends DataService{
  baseApiURL: string = environment.production  ? environment.apiUrl : 'http://127.0.0.1:8000/' ;
  cmsUrl = environment.cms_url
  authToken:any;
  userInfo:any = {};
  constructor(
    https: HttpClient
  ) {
    super(https);
   }

   private get_headers(Auth=false,isImageUpload=false) {
    const headers:any = {};
    if(!isImageUpload) {
      headers['Content-Type'] = 'application/json';
    }
    if(isImageUpload) {
      delete headers['Content-Type'];
    }
    if(Auth) {
      headers['Authorization'] = 'Bearer '+ localStorage.getItem('access_token');
    }
   
    return headers;
  }
  private construct_and_get_query_params(queryParams:any) {
    let params = new HttpParams();
    for (const key of Object.keys(queryParams)) {
      params = params.set(key, queryParams[key]);
    }
    return params;
  }

  private format_and_get_resource(resource:any) {
    return resource;
  }

  register(data:any){
    const url= this.baseApiURL+'auth/register/'
    return this.create(url,this.format_and_get_resource(data))
  }

  login(data:any){
    const url=this.baseApiURL+'auth/obtain_token/'
    return this.create(url,this.format_and_get_resource(data))
  }

  verifyEmail(data:any){
    const url = this.baseApiURL+'auth/verify/'
    return this.create(url,this.format_and_get_resource(data))
  }

  forgetPassword(data:any){
    const url=this.baseApiURL+'auth/forget-password/'
    return this.create(url,this.format_and_get_resource(data))
  }

  resetPassword(data:any){
    const url=this.baseApiURL+'auth/reset-password/'
    return this.create(url,this.format_and_get_resource(data))
  }

  getUsers(){
    const url=this.baseApiURL+'users/'
    return this.getAll(url, this.get_headers(true));
  }

  addUser(data:any){
    const url= this.baseApiURL+'users/'
    return this.create(url,this.format_and_get_resource(data),this.get_headers(true))
  }

  getUser(id:any) {
    const url = this.baseApiURL + 'user/' + id+'/';
    const queryParams = {};
    const params = this.construct_and_get_query_params(queryParams);
    return this.get(url, params, this.get_headers(true));
  }

  getVerificationStatus(id:any) {
    const url = this.baseApiURL + 'getVerificationStatus/' + id+'/';
    const queryParams = {};
    const params = this.construct_and_get_query_params(queryParams);
    return this.get(url, params, this.get_headers(true));
  }

  updateUser(id:any,data:any) {
    const url = this.baseApiURL +  'user/' + id+'/';
    return this.update(url,this.format_and_get_resource(data),this.get_headers(true))
  }

  deleteUser(id:any) {
    const url = this.baseApiURL + 'user/' + id+'/';
    return this.delete(url, this.get_headers(true))
  }


  getPropertyNamesByType(_inparams:any){
    const url = this.baseApiURL + 'fetch-properties/';
    const query_params = _inparams;
    const params = this.construct_and_get_query_params(query_params);
    return this.get(url, params, this.get_headers(true));
  }

  getChannelpartnerreferls(){
    const url=this.baseApiURL+'referrals/'
    return this.getAll(url, this.get_headers(true));
  }

  addReferel(data:any){
    const url= this.baseApiURL+'referrals/'
    return this.create(url,this.format_and_get_resource(data),this.get_headers(true))
  }

  getReferel(id:any) {
    const url = this.baseApiURL + 'referral/' + id+'/';
    const queryParams = {};
    const params = this.construct_and_get_query_params(queryParams);
    return this.get(url, params, this.get_headers(true));
  }

  getInvestorDetails(id:any){
    const url = this.baseApiURL + 'getInvestorDetails/' + id+'/';
    const queryParams = {};
    const params = this.construct_and_get_query_params(queryParams);
    return this.get(url, params, this.get_headers());
  }

  updateReferel(id:any,data:any) {
    const url = this.baseApiURL +  'referral/' + id+'/';
    return this.update(url,this.format_and_get_resource(data),this.get_headers(true))
  }

  deleteReferel(id:any) {
    const url = this.baseApiURL + 'referral/' + id+'/';
    return this.delete(url, this.get_headers(true))
  }
 
  addProperty(data:any){
    const url = this.baseApiURL+'onboard-properties/'
    return this.create(url,this.format_and_get_resource(data),this.get_headers(true,true))
  }

  updateProperty(id:any, data:any){
    const url = this.baseApiURL+'onboard-property/'+id+'/'
    return this.update(url,this.format_and_get_resource(data),this.get_headers(true,true))
  }

  getProperty(id:any){
    const url = this.baseApiURL + 'onboard-property/' + id+'/';
    const queryParams = {};
    const params = this.construct_and_get_query_params(queryParams);
    return this.get(url, params, this.get_headers(true));
  }

  deleteProperty(id:any){
    const url = this.baseApiURL + 'delete-property/' + id+'/';
    return this.delete(url, this.get_headers(true));
  }

  ListAllProperties(){
    const url = this.baseApiURL+'properties/'
    return this.getAll(url)
  }

  PropertyDetails(params:any){
    const url = this.baseApiURL+'properties/'
    return this.get(url, params)
  }

  GetAllPropertyImages(isAuthenticated:any){
    const url = this.baseApiURL+'property-files/'
    return this.getAll(url,this.get_headers(isAuthenticated))
  }

  GetPropertyFiles(params:any,isAuthenticated:any){
    const url = this.baseApiURL+'property-files/'
    return this.get(url,params,this.get_headers(isAuthenticated))
  }

  createPaymentOrder(amount: number, userId: string,propertyId:any) {
    const url= this.baseApiURL+'create-payment-order/'
    return this.create(url,this.format_and_get_resource({ amount, user_id: userId,property_id:propertyId }),this.get_headers(true))
  }

  verifyPayment(paymentId: string) {
    const url= this.baseApiURL+'verify-payment/'
    return this.create(url,this.format_and_get_resource({ payment_id: paymentId }),this.get_headers(true))
  }

  getPaymentDetails(paymentId: string) {
    const url= this.baseApiURL+'payment-details/'+paymentId
    return this.get(url,{},this.get_headers(true));
  }

  getTrasactions() {
    const url= this.baseApiURL+'transactions/'
    return this.get(url,{},this.get_headers(true));
  }

  getInvestors() {
    const url= this.baseApiURL+'investors/'
    return this.get(url,{},this.get_headers(true));
  }

  getInvestorProperties(investorId:any) {
    const url= this.baseApiURL+`investors/${investorId}/properties/`
    return this.get(url,{},this.get_headers(true));
  }

  addTransaction(data:any){
    const url = this.baseApiURL+'transactions/add/'
    return this.create(url,this.format_and_get_resource(data),this.get_headers(true,true))
  }

  getDashboard() {
    const url= this.baseApiURL+'dashboard/'
    return this.get(url,{},this.get_headers(true));
  }

  getLandingPageData(){
    let params = {
      'populate':'*',
      'sort':'priority:asc'
    }
    const url = this.cmsUrl+'/api/landing-page-images'
    return this.get(url,params,this.get_headers(false,false))
  }

  verifyKYC(data:any, id:string){
    const url = this.baseApiURL+'verify-kyc/'+id+'/'
    return this.create(url,this.format_and_get_resource(data),this.get_headers(true,true))
  }
  // Example

//   // Get All
//   getAllGroups() {
//     const url = this.baseApiURL + 'groups';
//     return this.get(url, {},this.get_headers(true,true))
    
//   }

//   // Create
//   createGroup(data:any) {
//     const url = this.baseApiURL + 'group';
//     return this.create(url, this.format_and_get_resource(data),this.get_headers(true,true))
//   }

//   // Update
//   updateForumsToGroupChat(groupId:any,data:any) {
//     const url = this.baseApiURL +  'group/'+String(groupId)+'/enable'
//     return this.update(url,this.format_and_get_resource(data),this.get_headers(true,true))
//   }


 
//  // Delete
//   deleteForumGroup(groupId:any) {
//     const url = this.baseApiURL + 'group/'+String(groupId);
//     return this.delete(url, this.get_headers(true,true))
//   }

  getBlogsData(params:any){
    params['populate']='*';
    params['sort']='posted_on:desc';
    const url = this.cmsUrl+'/api/blogs'
    return this.get(url,params,this.get_headers(false,false))
  }

  getBlogData(slug:string){
    const url = this.cmsUrl+'/api/blogs/'+slug
    return this.get(url,{},this.get_headers(false,false))

  }

  captureLead(data:any){
    const url = 'https://crm.zoho.in/crm/WebToLeadForm'
    return this.create(url, this.format_and_get_resource(data),this.get_headers(false,false))
  }

}
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../shared/services/api.service';
import { AuthService } from '../shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent {
  users:any;
  userId:any;
  roles = {
    'channelpartner': 'Channel Partner',
    'support': 'Support',
    'investor': 'Investor',
    'admin': 'Admin'
  };
  
  constructor(
    private router:Router,
    private apiService:ApiService, 
    private authService: AuthService,
    private toastr:ToastrService
  ){
      if(authService.isAuthenticated() && authService.getData().role !== 'admin') {
        this.authService.redirectedToUserPages()
      }
      this.userId = authService.getData().user_id
      this.getUsers()
  }

  getUsers() {
    this.apiService
    .getUsers()
    .subscribe(resp => {
      if(resp['status']) {
        this.users = resp['data']
        let index = this.users.findIndex((user:any) => user.id === this.userId)
        if(index !== -1) {
          this.users.splice(index,1)
        }
      }
    });
  }

  getRoleDisplayName(roleKey: string): string {
    return this.roles[roleKey as keyof typeof this.roles] || roleKey;
  }

  addUser() {
    this.router.navigate(['add-user'])
  }

  updateUser(userId:any) {
    this.router.navigate(['update-user', userId]);
  }

  deleteUser(userId:any) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        this.apiService.deleteUser(userId).subscribe(response => {
          this.getUsers();
        });
      }
    });
  }
   

}


import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FAQSComponent {
  // Define the FAQ data directly in the component
  faqs = [
    {
      id: "3@24e",
      category: "start",
      title: "What is 22 Yards?",
      details:
        '22 Yards is a fractional real estate investment company that allows individuals to invest in real estate properties without needing to purchase an entire property. The company offers a range of investment opportunities in multi-use properties, including service apartments, co-living spaces, resorts, hotels, and renewable energy projects. By enabling fractional ownership, 22 Yards provides investors with the ability to diversify their portfolios and enjoy steady returns, aligning with the company tagline, "Steady Runs, Solid Returns".',
    },
    {
      id: "3@64e",
      category: "start",
      title: "What are the benefits of investing via 22 Yards?",
      details:
        "Investing in 22 Yards offers several benefits: it allows for fractional ownership of diverse real estate assets, reducing the capital needed to enter the market. Investors gain access to steady returns from multi-use properties, including service apartments, co-living spaces, and renewable energy projects, all while diversifying their investment portfolio",
    },
    {
      id: "3@48e",
      category: "start",
      title: "What makes 22 Yards different?",
      details:
        "22 Yards stands out due to its fractional investment model, which lowers the barrier to entry for real estate investment. The company focuses on a diverse range of assets, including multi-use properties and renewable energy projects, providing investors with both steady returns and portfolio diversification. Additionally, 22 Yards emphasizes maximizing land utility and community value, setting it apart from traditional real estate investment approaches.",
    },
    {
      id: "3@*4e",
      category: "start",
      title: "What happens to my investments if 22 Yards goes into bankruptcy?",
      details:
        "To ensure there’s no risk for investors, 22 Yards has implemented robust financial safeguards and legal protections. In the unlikely event of bankruptcy, investors’ funds are protected through comprehensive insurance and risk management strategies. The company maintains a strong financial structure with clear protocols to safeguard investor capital, ensuring that your investment remains secure and unaffected by the company's financial difficulties.",
    },
  ];

  constructor(
    private titleService: Title,
    private metaService: Meta
  ) {}

  ngOnInit() {
    this.updateMetaTags();
  }

  private updateMetaTags() {
    // Page Title
    const pageTitle = 'Fractional Real Estate Investment Questions And Answers';
    this.titleService.setTitle(pageTitle);

    // Remove existing meta tags
    this.metaService.removeTag('name="description"');
    this.metaService.removeTag('property="og:title"');
    this.metaService.removeTag('property="og:description"');
    this.metaService.removeTag('property="og:url"');
    
   

    // Update meta tags
    this.metaService.addTags([
      // Basic Meta Tags
      { 
        name: 'description', 
        content: 'Get Clear Answers to Frequently Asked Questions About Fractional Real Estate Investment. Learn How It Works, Benefits, How to Investing In Premium Properties.'
 
      },
      { 
        name: 'keywords', 
        content: 'fractional real estate FAQ, 22 Yards investment questions, property investment insights, fractional ownership explained' 
      },
      
      // Open Graph Tags
      { 
        property: 'og:title', 
        content: pageTitle 
      },
      { 
        property: 'og:description', 
        content: 'Get Clear Answers to Frequently Asked Questions About Fractional Real Estate Investment. Learn How It Works, Benefits, How to Investing In Premium Properties.' 
      },
      { 
        property: 'og:url', 
        content: 'https://22yardsprop.com/faq' 
      }
     
      
    ]);

    // Canonical URL
    this.metaService.updateTag({
      rel: 'canonical',
      href: 'https://22yardsprop.com/faq'
    });
  }
}


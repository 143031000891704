<div class="dashboard section__space__bottom">
  <div class="container">
    <div class="dashboard__area">
      <div class="row">
        <div class="col-xxl-3">
          <app-navigation></app-navigation>
        </div>
        <div class="col-xxl-9">
          <div class="main__content">
            <div class="collapse__sidebar">
              <h4>Dashboard</h4>
              <a href="javascript:void(0)" class="collapse__sidebar__btn">
                <i class="fa-solid fa-bars-staggered"></i>
              </a>
            </div>
            <div class="main__content-dashboard">
              <div class="breadcrumb-dashboard">
                <h5>KYC</h5>
                <div>
                  <a href="dashboard">Dashboard</a>
                  <i class="fa-solid fa-arrow-right-long"></i>
                  <a href="javascript:void(0)">KYC</a>
                </div>
              </div>

              <div class="row">
                <div class="investment-table">
                  <section >
                    <div class="container">
                      <div class="registration__area kyc" *ngIf="is_kyc_completed">
                        <div class="kyc-verified">
                          <img src="../../assets/images/success.png">
                          <p>You're all set! Your KYC is complete and verified.</p>
                          <button class="button button--effect" (click)="redirectToProperties()">Invest in Properties</button>
                        </div>
                      </div>
                      <div class="registration__area" *ngIf="!is_kyc_completed">
                        <h4 style="padding-bottom: 20px;">{{ getFormHeading() }}</h4>
                        <form  [formGroup]="kycForm" (ngSubmit)="onSubmit()">
                          <!-- Personal Information -->
                         
                              <div *ngIf="selectedSection === 'personalInformation'">
                                
                                  <p style="padding-bottom: 20px;">Provide investor's information as per Bank Account</p>
                              
                                  <div class="row">
                                      <div class="col-sm-6">
                                          <div class="input input--secondary">
                                              <label for="email">Email<span class="requried">*</span></label>
                                              <input type="email" formControlName="email" placeholder="Enter your email" required />
                                          </div>
                                      </div>
                                      <div class="col-sm-6">
                                        <div class="input input--secondary">
                                            <label for="phone_number">Phone Number<span class="requried">*</span></label>
                                            <br/>
                                            <ngx-intl-tel-input
                                              [cssClass]="'form-control'"
                                              [preferredCountries]="preferredCountries"
                                              [enableAutoCountrySelect]="true"
                                              [enablePlaceholder]="true"
                                              [searchCountryFlag]="true"
                                              [searchCountryField]="[
                                                SearchCountryField.Iso2,
                                                SearchCountryField.Name
                                              ]"
                                              [selectFirstCountry]="false"
                                              [selectedCountryISO]="CountryISO.India"
                                              [maxLength]="15"
                                              [phoneValidation]="true"
                                              [separateDialCode]="separateDialCode"
                                              [numberFormat]="PhoneNumberFormat.National"
                                              name="phone"
                                              formControlName="phone_number"
                                            >
                                            </ngx-intl-tel-input>
                                          </div>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-sm-6">
                                        <div class="input input--secondary regi__type">
                                         
                                          <label for="residential">Residential Status<span class="requried">*</span></label>
                                          <select class="nice-select type__select" formControlName="residential">
                                            <option value="" disabled selected>Select your Residence</option>
                                              <option value="indian">Indian</option>
                                              <option value="NRI">NRI</option>
                                            </select>
                                          
                                        </div>
                                      </div>
                                      <div class="col-sm-6">
                                        <div class="input input--secondary regi__type">
                                          <label for="date_of_birth">Date of Birth<span class="requried">*</span></label>
                                          <input type="date" formControlName="date_of_birth" placeholder="Date of Birth" required (change)="validateDOB()" />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-sm-6">
                                        <div class="input input--secondary regi__type">
                                        
                                          <label for="gender">Gender<span class="requried">*</span></label>
                                          <select class="nice-select type__select" formControlName="gender">
                                              <option value="" disabled selected>Slect your Gender</option>
                                              <option value="male">Male</option>
                                              <option value="female">Female</option>
                                            </select>
                                        </div>
                                      </div>
                                      <div class="col-sm-6">
  
                                        <div class="input input--secondary regi__type">
                                        
                                          <label for="profession">Investor's Profession<span class="requried">*</span></label>
                                          <select class="nice-select type__select" formControlName="profession">
                                              <option value="" disabled selected>Select your Profession</option>
                                              <option value="IT Professional">IT Professional</option>
                                              <option value="Business Owner/Entrepreneur">Business Owner/Entrepreneur</option>
                                            <option value="Healthcare Professional">Healthcare Professional</option>
                                            <option value="Finance/Accounting/Banking Professional">Finance/Accounting/Banking Professional</option>
                                            <option value="Educator/Academic">Educator/Academic</option>
                                            <option value="Government Employee">Government Employee</option>
                                            <option value="Attorney/Law Professional">Attorney/Law Professional</option>
                                          </select>
                                         
                                        </div>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-sm-6">
                                        <div class="input input--secondary regi__type">
                                          <label for="organization">Investor's Organization</label>
                                          <input type="text" formControlName="organization" placeholder="Organization(Optional)"/>
                                        </div>
                                      </div>
                                      <div class="col-sm-6">
                                        <div class="input input--secondary regi__type">
                                          <label for="father_name">Father's Name<span class="requried">*</span></label>
                                          <input type="text" formControlName="father_name" placeholder="Father's Name" required />
                                        </div>
                                      </div>
                                    </div>

                                    
                                    <div class="input__button">
                                      <button type="button" [disabled]="checkForm('profile')" class="button button--effect" href="javascript:void(0)" (click)="selectSection('addressDetails')">
                                          Next
                                        </button>
                                    </div>
                                  </div>
    
                          <!-- Address Details -->
                          <div *ngIf="selectedSection === 'addressDetails'">
                              <p style="padding-bottom: 20px;">Provide your current correspondance address and upload an address proof of the same</p>
                            <div class="input input--secondary regi__type">
                              <label for="area">D.No., Street and Area<span class="requried">*</span></label>
                              <input type="text" formControlName="area" placeholder="Address - Area and Street" required/>
                            </div>
                            <div class="input input--secondary regi__type">
                              <label for="landmark">Landmark</label>
                              <input type="text" formControlName="landmark" placeholder="Landmark (Optional)"/>
                            </div>
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="input input--secondary regi__type">
                                  <label for="country">Country<span class="requried">*</span></label>
                                  <select class="nice-select type__select" formControlName="country" (change)="onCountryChange($event)" required>
                                    <option value="">Select Country</option>
                                    <option *ngFor="let country of countries" [value]="country.isoCode">{{ country.name }}</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="input input--secondary regi__type">
                                  <label for="state">State / Province / Region<span class="requried">*</span></label>
                                  <select class="nice-select type__select" formControlName="state" (change)="onStateChange($event)" required>
                                    <option value="">Select State</option>
                                    <option *ngFor="let state of states" [value]="state.isoCode">{{ state.name }}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="input input--secondary regi__type">
                                  <label for="city">City<span class="requried">*</span></label>
                                  <select class="nice-select type__select" formControlName="city" required (change)="onCityChange($event)">
                                    <option value="">Select City</option>
                                    <option *ngFor="let city of cities" [value]="city.name">{{ city.name }}</option>
                                    <option value="Other">Other</option> <!-- Add this option -->
                                  </select>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="input input--secondary regi__type">
                                  <label for="pincode">Pincode<span class="requried">*</span></label>
                                  <input type="number" formControlName="pincode" placeholder="Enter Pincode" required />
                                </div>
                              </div>
                              
                              <div *ngIf="isOtherCitySelected" class="input input--secondary">
                                  <label for="otherCity">Enter City Name<span class="requried">*</span></label>
                                     <input type="text" formControlName="city" id="otherCity" placeholder="Enter your city name" required/>
                                  </div>
                            </div>
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="input input--secondary regi__type">
                                  <label for="document_type">Document Type<span class="requried">*</span></label>
                                  <select class="nice-select type__select" formControlName="document_type" required>
                                    <option value="" disabled selected>Select Document Type</option>
                                    <option value="passport">Passport</option>
                                    <option value="aadhar">Aadhar</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="input input--secondary regi__type">
                                  <label for="document_number">Document Number<span class="requried">*</span></label>
                                  <input type="text" formControlName="document_number" placeholder="Document Number" required/>
                                </div>
                              </div>
                            </div>
                          
                            <div class="input input--secondary regi__type">
                              <label for="documnt_front">Please upload document front<span class="requried">*</span></label>
                              <input type="file"  required (change)="onFileChange($event, 'document_front')"/>
                            </div>
                          
                            <div class="input input--secondary regi__type">
                              <label for="document_back">Please upload document back side (if applicable)</label>
                              <input type="file"  (change)="onFileChange($event, 'document_back')" />
                            </div>
                            <div class="input__button" >
                              <div class="row">
                                <div class="col-sm-6">
                                  <button type="button" class="button button--effect" href="javascript:void(0)" (click)="selectSection('personalInformation')">
                                      Back
                                  </button>
                                </div>
                                <div class="col-sm-6">
                                  <button type="button" [disabled]="checkForm('address')" class="button button--effect" href="javascript:void(0)" (click)="selectSection('panDetails')">
                                    Next
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                         

                          <!-- PAN Details -->
                          <div *ngIf="selectedSection === 'panDetails'">
                              <p style="padding-bottom: 20px;">Provide your PAN details</p>
                                  
                              <div class="input input--secondary regi__type">
                                <label for="pan_number">PAN Number<span class="requried">*</span></label>
                                <input type="text" formControlName="pan_number" placeholder="Enter PAN Number" required />
                              </div>
                           
                           
                              <div class="input input--secondary regi__type">
                                <label for="pan_card">Upload PAN card front side<span class="requried">*</span></label>
                                <input type="file" required (change)="onFileChange($event, 'pan_card')" />
                              </div>
                              <div class="input__button" >
                                <div class="row">
                                  <div class="col-sm-6">
                                    <button type="button" class="button button--effect" href="javascript:void(0)" (click)="selectSection('addressDetails')">
                                      Back
                                    </button>
                                  </div>
                                  <div class="col-sm-6">
                                    <button type="button" [disabled]="checkForm('pan')" class="button button--effect" href="javascript:void(0)" (click)="selectSection('bankAccountDetails')">
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <!-- Bank Account Details -->
                          <div *ngIf="selectedSection === 'bankAccountDetails'">
                              <p style="padding-bottom: 20px;">Provide your Bank account details</p>
                              
                              <div class="input input--secondary regi__type">
                                <label for="account_name">Account holder name<span class="requried">*</span></label>
                                <input type="text" formControlName="account_name" placeholder="Enter Account holder name" required />
                              </div>

                              <div class="row">
                                <div class="col-sm-6">
                                  <div class="input input--secondary regi__type">
                                    <label for="account_number">Account Number<span class="requried">*</span></label>
                                    <input type="number" formControlName="account_number" placeholder="Account Number" required />
                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <div class="input input--secondary regi__type">
                                    <label for="account_number_conf">Confirm Account Number<span class="requried">*</span></label>
                                    <input type="number" formControlName="account_number_conf" placeholder="Re-Enter Account Number" required (change)="validateAccountNumber()" />
                                  </div>
                                </div>
                              </div>


                              <div class="input input--secondary regi__type">
                                <label for="ifsc_code">IFSC Code<span class="requried">*</span></label>
                                <input type="text" formControlName="ifsc_code" placeholder="IFSC Code" required />
                              </div>
                                                      
                           
                               
                                  <div class="input input--secondary regi__type">
                                    <label for="statement">Upload cancelled cheque/ Bank statement<span class="requried">*</span>
                                    </label>
                                    <input type="file"  required (change)="onFileChange($event, 'statement')"/>
                                  </div>
                              
                               
                                  <div class="input input--secondary regi__type">
                                    <label for="bank_name">Bank Name<span class="requried">*</span></label>
                                    <input type="text" formControlName="bank_name" placeholder="Bank Name" required />
                                  </div>
                                 <div class="input__button">
                                  <div class="row">
                                    <div class="col-sm-6">
                                      <button type="button" class="button button--effect" href="javascript:void(0)" (click)="selectSection('panDetails')">
                                        Back
                                      </button>
                                    </div>
                                    <div class="col-sm-6">
                                      <button type="button" [disabled]="checkForm('bank')" class="button button--effect" href="javascript:void(0)" (click)="selectSection('reviewSubmit')">
                                        Next
                                      </button>
                                    </div>
                                  </div>
                                </div>
                          </div>

                          <!-- Review & Submit -->
                          <div *ngIf="selectedSection === 'reviewSubmit'">
                              <!-- Display Personal Information -->
                              <div class="review-section">
                                <h5>Personal Information</h5>
                                <p><strong>Email:</strong> {{ kycForm.get('email')?.value }}</p>
                                <p><strong>Phone Number:</strong> {{ kycForm.get('phone_number')?.value?.e164Number }}</p>
                                <p><strong>Residential Status:</strong> {{ kycForm.get('residential')?.value }}</p>
                                <p><strong>Date of Birth:</strong> {{ kycForm.get('date_of_birth')?.value }}</p>
                                <p><strong>Gender:</strong> {{ kycForm.get('gender')?.value }}</p>
                                <p><strong>Profession:</strong> {{ kycForm.get('profession')?.value }}</p>
                                <p><strong>Organization:</strong> {{ kycForm.get('organization')?.value }}</p>
                                <p><strong>Father's Name:</strong> {{ kycForm.get('father_name')?.value }}</p>
                              </div>

                              <!-- Display Address Details -->
                              <div class="review-section">
                                <h5>Address Details</h5>
                                <p><strong>Address:</strong> {{ kycForm.get('area')?.value }}</p>
                                <p><strong>Landmark:</strong> {{ kycForm.get('landmark')?.value }}</p>
                                <p><strong>Country:</strong> {{ kycForm.get('country')?.value }}</p>
                                <p><strong>State:</strong> {{ kycForm.get('state')?.value }}</p>
                                <p><strong>City:</strong> {{ kycForm.get('city')?.value }}</p>
                                <p><strong>Pincode:</strong> {{ kycForm.get('pincode')?.value }}</p>
                                <p><strong>Document Type:</strong> {{ kycForm.get('document_type')?.value }}</p>
                                <p><strong>Document Number:</strong> {{ kycForm.get('document_number')?.value }}</p>
                              </div>

                              <!-- Display PAN Details -->
                              <div class="review-section">
                                <h5>PAN Details</h5>
                                <p><strong>PAN Number:</strong> {{ kycForm.get('pan_number')?.value }}</p>
                              </div>

                              <!-- Display Bank Account Details -->
                              <div class="review-section">
                                <h5>Bank Account Details</h5>
                                <p><strong>Account Holder Name:</strong> {{ kycForm.get('account_name')?.value }}</p>
                                <p><strong>Account Number:</strong> {{ kycForm.get('account_number')?.value }}</p>
                                <p><strong>IFSC Code:</strong> {{ kycForm.get('ifsc_code')?.value }}</p>
                                <p><strong>Bank Name:</strong> {{ kycForm.get('bank_name')?.value }}</p>
                              </div>
                               <div class="input__button">
                                <button type="button" class="button button--effect" (click)="selectSection('personalInformation')">
                                  Review
                                </button>
                                <button type="submit" class="button button--effect">
                                  Submit
                                </button>
                              </div>
                            </div>
                        </form>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

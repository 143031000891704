<section class="faq section__space border">
    <div class="container">
      <div class="faq__group">
        <div class="accordion">

            
    <div class="accordion-item" *ngFor="let item of faqs; let i = index">
      <h5 class="accordion-header" [attr.id]="'headingFundOne' + i">
        <span class="icon_box">
          <img src="assets/images/icons/message.png" alt="message" />
        </span>
        <button
          class="accordion-button"
          [ngClass]="{'collapsed': i !== 0}"
          type="button"
          [attr.data-bs-toggle]="'collapse'"
          [attr.data-bs-target]="'#collapseFundOne' + i"
          [attr.aria-expanded]="i === 0"
          [attr.aria-controls]="'collapseFundOne' + i"
        >
          {{ item.title }}
        </button>
      </h5>
      <div
        [attr.id]="'collapseFundOne' + i"
        class="accordion-collapse collapse"
        [ngClass]="{'show': i === 0}"
        [attr.aria-labelledby]="'headingFundOne' + i"
      >
        <div class="accordion-body">
          <p>{{ item.details }}</p>
        </div>
      </div>
    </div>
 
  </div>
  </div>
  </div>
  </section>
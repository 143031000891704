<!-- ==== details section start ==== -->
<!-- <div class="property__details__banner bg__img clear__top" style="overflow: hidden; height: 500px;">
    <p-carousel
        [value]="getPropertyImages(property_files)"
        [numVisible]="1" 
        [autoplayInterval]="3000"
        [numScroll]="1" 
        orientation="horizontal" 
        styleClass="custom-carousel">
        <ng-template pTemplate="item" let-item>
            <img [src]="item.url" [alt]="item.name" class="carousel-image" />
        </ng-template>
    </p-carousel>
</div> -->
<div style="position: relative; height: 90vh; overflow: hidden;">
    <ngb-carousel [interval]="5000" [wrap]="true" [keyboard]="true" [pauseOnHover]="false" [showNavigationArrows]="false">
        <ng-template ngbSlide *ngFor="let item of images">
            <div class="carousel-img-wrapper">
                <img [src]="item.url" alt="property-image" />
            </div>
        </ng-template>
    </ngb-carousel>
</div>
<section class="p__details faq section__space__bottom">
    <div class="container">
        <div class="p__details__area" *ngIf="property">
            <div class="row">
                <div class="col-lg-7">
                    <div class="p__details__content">
                        <a href="#gallery" class="button button--effect button--secondary" (click)="scrollToGallery($event)" style="z-index: 5;">
                            <i class="fa-solid fa-images"></i> Browse Gallery
                          </a>
                        <div class="intro">
                            <h3>{{ property.name }}</h3>
                            <p><i class="fa-solid fa-location-dot"></i> {{ property.location }}
                            </p>
                        </div>
                        <div class="group__one">
                            <h4>Project Description</h4>
                            <p>{{property.description}}</p>
                        </div>
                        <!-- <div class="group__two">
                            <h5>Reasons to invest in the project A19, Vilnius:</h5>
                            <ul>
                                <li><img src="assets/images/check.png" alt="Check" /> Lofts in an attractive area -
                                    in the center of Vilnius;</li>
                                <li><img src="assets/images/check.png" alt="Check" /> ixed, attractive annual rental
                                    income;</li>
                                <li><img src="assets/images/check.png" alt="Check" /> The fixed interest is indexed
                                    to inflation;</li>
                                <li><img src="assets/images/check.png" alt="Check" /> The fixed interest is indexed
                                    to inflation;</li>
                                <li><img src="assets/images/check.png" alt="Check" /> Variable capital gains;</li>
                                <li><img src="assets/images/check.png" alt="Check" /> The premises were appraised by
                                    an independent valuer at 347 000 EUR</li>
                                <li><img src="assets/images/check.png" alt="Check" /> The project owner is an
                                    experienced real estate administrator.</li>
                            </ul>
                        </div> -->
                        <!-- <div class="terms">
                            <h5>Financial terms of the investment:</h5>
                            <div class="terms__wrapper">
                                <div class="terms__single">
                                    <img src="assets/images/icons/loan.png" alt="Maximum Loan" />
                                    <p>Maximum loan term</p>
                                    <h5 class="neutral-bottom">{{property.tenure}} Months</h5>
                                </div>
                                <div class="terms__single">
                                    <img src="assets/images/icons/charge.png" alt="Charge" />
                                    <p>Security</p>
                                    <h5 class="neutral-bottom">1st charge
                                        Mortgage</h5>
                                </div>
                                <div class="terms__single">
                                    <img src="assets/images/icons/project.png" alt="Annual" />
                                    <p>Annual Return</p>
                                    <h5 class="neutral-bottom">{{ property.ROI_min }}% - {{ property.ROI_max }}%</h5>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="group__two">
                            <h5>When investing:</h5>
                            <ul>
                                <li><img src="assets/images/check.png" alt="Check" /> Up to 4999 € - the annual
                                    return is 7%.</li>
                                <li><img src="assets/images/check.png" alt="Check" /> 5000 € – 14999 € - the annual
                                    return is 7.1%.</li>
                                <li><img src="assets/images/check.png" alt="Check" /> 15000 € – 29999 € - the annual
                                    return is 7.2%.</li>
                                <li><img src="assets/images/check.png" alt="Check" /> 30000 € – 49999 € - the annual
                                    return is 7.35%.</li>
                                <li><img src="assets/images/check.png" alt="Check" /> 50000 € and more - the annual
                                    return is 7.5%.</li>
                            </ul>
                        </div> -->
                        <!-- <div class="terms">
                            <h5>The capital growth distribution:</h5>
                            <div class="terms__wrapper">
                                <div class="terms__single">
                                    <img src="assets/images/icons/investor.png" alt="Maximum Loan" />
                                    <p>Investors</p>
                                    <h5 class="neutral-bottom">40% - 60%</h5>
                                </div>
                                <div class="terms__single">
                                    <img src="assets/images/icons/project.png" alt="Charge" />
                                    <p>Annual Return</p>
                                    <h5 class="neutral-bottom">{{ property.ROI_min }}% - {{ property.ROI_max }}%</h5>
                                </div>
                                <div class="terms__single">
                                    <img src="assets/images/icons/revest.png" alt="Annual" />
                                    <p>Target IRR</p>
                                    <h5 class="neutral-bottom">{{ property.IRR_min }}% - {{ property.IRR_max }}%</h5>
                                </div>
                            </div>
                        </div> -->
                        <div class="lease-details">
                            <div class="tenant-details">
                                <h5>Tenant Details</h5>
                                <p>{{ property.property_tenancy[0].tenant_details }}</p>
                            </div>
                            <h5>Lease Details</h5>
                            <div class="lease-timeline">
                              <div class="timeline-step">
                                <p>Lease Start Date</p>
                                <h6>{{ property.property_tenancy[0].lease_start_date }}</h6>
                              </div>
                              <div class="timeline-step">
                                <p>Lock-in End Date</p>
                                <h6>{{ property.property_tenancy[0].lock_in_end_date }}</h6>
                              </div>
                              <div class="timeline-step">
                                <p>Lease End Date</p>
                                <h6>{{ property.property_tenancy[0].lease_end_date }}</h6>
                              </div>
                            </div>
                            
                            <div class="lease-summary">
                              <div class="summary-box">
                                <p>Lease Term</p>
                                <h6>{{ property.property_tenancy[0].lease_term }}</h6>
                              </div>
                              <div class="summary-box">
                                <p>Lock-in period</p>
                                <h6>{{ property.property_tenancy[0].lock_in_period }}</h6>
                              </div>
                            </div>
                          </div>                          
                        <!-- <div class="faq__group">
                            <h5 class="atr">Frequently asked questions</h5>
                            <div class="accordion" id="accordionExampleFund">
                                <div class="accordion-item content__space">
                                    <h5 class="accordion-header" id="headingFundOne">
                                        <span class="icon_box">
                                            <img src="assets/images/icons/message.png" alt="Fund" />
                                        </span>
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseFundOne" aria-expanded="true"
                                            aria-controls="collapseFundOne">
                                            What is Revest?
                                        </button>
                                    </h5>
                                    <div id="collapseFundOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingFundOne" data-bs-parent="#accordionExampleFund">
                                        <div class="accordion-body">
                                            <p>combined with a handful of model sentence structures, to generate
                                                Lorem Ipsum
                                                which looks reasonable. The generated Lorem Ipsum is therefore
                                                always free
                                                from
                                                repetition, injected humour, or</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item content__space">
                                    <h5 class="accordion-header" id="headingFundTwo">
                                        <span class="icon_box">
                                            <img src="assets/images/icons/message.png" alt="Fund" />
                                        </span>
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseFundTwo"
                                            aria-expanded="false" aria-controls="collapseFundTwo">
                                            What are the benefits of investing via Revest?
                                        </button>
                                    </h5>
                                    <div id="collapseFundTwo" class="accordion-collapse collapse"
                                        aria-labelledby="headingFundTwo" data-bs-parent="#accordionExampleFund">
                                        <div class="accordion-body">
                                            <p>combined with a handful of model sentence structures, to generate
                                                Lorem Ipsum
                                                which looks reasonable. The generated Lorem Ipsum is therefore
                                                always free
                                                from
                                                repetition, injected humour, or</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item content__space">
                                    <h5 class="accordion-header" id="headingFundThree">
                                        <span class="icon_box">
                                            <img src="assets/images/icons/message.png" alt="Fund" />
                                        </span>
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseFundThree"
                                            aria-expanded="false" aria-controls="collapseFundThree">
                                            What makes Revest different?
                                        </button>
                                    </h5>
                                    <div id="collapseFundThree" class="accordion-collapse collapse"
                                        aria-labelledby="headingFundThree" data-bs-parent="#accordionExampleFund">
                                        <div class="accordion-body">
                                            <p>combined with a handful of model sentence structures, to generate
                                                Lorem Ipsum
                                                which looks reasonable. The generated Lorem Ipsum is therefore
                                                always free
                                                from
                                                repetition, injected humour, or</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="group__one">
                            <div class="map__wrapper">
                                <iframe
                                [src]="mapSrc"
                                width="746"
                                height="312"
                                loading="lazy"
                                style="border: 0;"
                                allowfullscreen
                                ></iframe>
                                <a
                                    [attr.href]="mapLink"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    [ngStyle]="{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        zIndex: 1,
                                        backgroundColor: 'rgba(0,0,0,0)'
                                    }"
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="p__details__sidebar">
                        <div class="intro">
                            <h5>Available for funding: <span><i class="fa-solid fa-inr"></i> {{property.remaining_investment_value}}</span></h5>
                            <div class="progress__type progress__type--two">
                                <div class="progress">
                                    <div class="progress-bar" 
                                        [style.width]="getProgressPercentage() + '%'" 
                                        role="progressbar" 
                                        [attr.aria-valuenow]="getProgressPercentage()" 
                                        aria-valuemin="0" 
                                        aria-valuemax="100">
                                    </div>
                                </div>
                                <div class="project__info">
                                    <p class="project__has"><span class="project__has__investors">{{property.total_investors}}
                                            Investors</span> | <span class="project__has__investors__amount"><i
                                                class="fa-solid fa-inr"></i> {{property.total_investment}}</span></p>
                                    <p class="project__goal">
                                        <i class="fa-solid fa-inr"></i> {{property.value}} Goal
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="group brin">
                            <!-- <h5 class="neutral-top">Occupancy</h5>
                            <div class="acus__btns">
                                <a href="javascript:void(0)" class="acus__btn acus__btn__active">0%</a>
                                <a href="javascript:void(0)" class="acus__btn">20%</a>
                                <a href="javascript:void(0)" class="acus__btn">40%</a>
                                <a href="javascript:void(0)" class="acus__btn">60%</a>
                                <a href="javascript:void(0)" class="acus__btn">80%</a>
                                <a href="javascript:void(0)" class="acus__btn">100%</a>
                            </div> -->
                            <div class="acus__content">
                                <form action="#" method="post">
                                    <div class="input input--secondary">
                                        <label for="anNum">Annual return rate:</label>
                                        <h5>{{ property.ROI_min }}% - {{ property.ROI_max }}%</h5>
                                    </div>
                                    <div class="input input--secondary">
                                        <label for="anNumIn">Target IRR:</label>
                                        <h5>{{ property.IRR_min }}% - {{ property.IRR_max }}%</h5>
                                    </div>
                                    <!-- <div class="input input--secondary">
                                        <label for="anNumInTwo">Earn</label>
                                        <input type="number" name="an__num_in_two" id="anNumInTwo"
                                            placeholder="€ 35.00" required="required" />
                                    </div>
                                    <div class="capital">
                                        <p>Capital Growth Split:</p>
                                        <h5>40% <a href="blog.html"><i class="fa-solid fa-circle-info"></i></a>
                                        </h5>
                                    </div> -->
                                    <div class="suby">
                                        <button (click)="onInvestNowClick($event, property)" class="button button--effect">Invest Now</button>
                                        <p class="text-center neutral-bottom">
                                            <a href="/contact-us">Request a free callback</a>
                                        </p>
                                    </div>

                                </form>
                            </div>
                            
                        </div>
                        <div class="group alt__brin__last">
                            <h5>Reports</h5>
                            <hr />
                            <h6>Investment Note</h6>
                            <p>Property Share's Detailed Investment Note</p>
                            <a [href]="getFileUrl('land_documents')" class="button">DOWNLOAD INVESTMENT NOTE <i
                                    class="fa-solid fa-download"></i></a>
                            <h6>Legal Title Report</h6>
                            <p>Detailed Report on the Title diligence of the
                                property.</p>
                            <a [href]="getFileUrl('legal_opinion')" class="button">DOWNLOAD TITLE REPORT <i
                                    class="fa-solid fa-download"></i></a>
                        </div>
                        <!-- <div class="group birinit">
                            <h6>Share via Social </h6>
                            <div class="social text-start">
                                <a href="javascript:void(0)">
                                    <i class="fab fa-facebook-f"></i>
                                </a>
                                <a href="javascript:void(0)">
                                    <i class="fab fa-twitter"></i>
                                </a>
                                <a href="javascript:void(0)">
                                    <i class="fab fa-instagram"></i>
                                </a>
                                <a href="javascript:void(0)">
                                    <i class="fab fa-linkedin-in"></i>
                                </a>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- ==== #details section end ==== -->

<!-- ==== property gallery two section start ==== -->
<section class="p__gallery wow fadeInUp" id="gallery">
    <div class="container">
        <hr class="divider" />
        <div class="p__gallery__area section__space">
            <div class="title__with__cta">
                <div class="row d-flex align-items-center">
                    <div class="col-lg-8">
                        <h2>Property Gallery</h2>
                    </div>
                    <div class="col-lg-4">
                        <div class="text-start text-lg-end">
                            <a href="/contact-us" class="button button--secondary button--effect">Request
                                info</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row p__gallery__single">
                <div class="col-md-6 col-lg-4 gallery__single__two" *ngFor="let image of getPropertyImages(property_files) ">
                    <a href="{{image.url}}">
                        <img [src]="image.url" alt="Property Image" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- ==== property gallery two section end ==== -->


<!-- Investment Modal -->
<ng-template #investModal let-modal>
    <div class="modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="investModalLabel">Invest in Property - {{selectedProperty.name}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
        </div>
        <div class="modal-body">
          <!-- Form for number of shares -->
          <form (submit)="onSubmitInvestmentForm($event)">
            <div class="mb-3">
              <label for="numShares" class="form-label">Number of Shares</label>
              <input type="number" class="form-control" id="numShares" [(ngModel)]="numShares" (change)="onSharesChange()" name="numShares" required>
             <p class="error-text">** Maximum shares you can buy {{remainingShares}}</p>
            </div>
            <div class="mb-3">
              <label for="investmentValue" class="form-label">Investment Value</label>
              <input type="text" class="form-control" id="investmentValue" [value]="investmentValue" disabled>
            </div>
            <button type="submit" class="btn btn-primary" [disabled]="numShares <= 0">Confirm Investment</button>
          </form>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #investConfirmationModal let-modal>
    <div class="modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="investmentModalLabel">Investment Details</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Close click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" id="investmentModalDescription">
            <p><strong>Bank Account Details:</strong></p>
            <p>Account Name: {{ bankDetails.account_name }}</p>
            <p>Account Number: {{ bankDetails.account_number }}</p>
            <p>IFSC Code: {{ bankDetails.ifsc_code }}</p>
            <p><strong>Investment Value:</strong> ₹{{ investmentValue }}</p>
  
            <button class="btn btn-primary" (click)="copyDetails()">Copy Details</button>
            <button class="btn btn-secondary ml-10" (click)="downloadDetails()">Download Details</button>
  
            <div class="mt-3">
                <label for="paymentProof"><strong>Note:</strong></label>
                <p>
                  Please confirm the transaction. After confirming the transaction, kindly upload the payment receipts within <strong>48 hours</strong>. 
                  Failure to do so will result in the release of your held shares back to the open market.
                </p>
              </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cancel click')">Cancel</button>
            <button type="button" class="btn btn-success" (click)="confirmTransaction()">Confirm Transaction</button>
          </div>
        </div>
      </div>
  </ng-template>
  

  
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input-gg';
import { Country, State, City } from 'country-state-city';
import { HotToastService } from '@ngneat/hot-toast';
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { elementAt } from 'rxjs';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss']
})
export class KycComponent implements OnInit {
  selectedSection: string = 'personalInformation';
  kycForm!: FormGroup;
  email!:string;
  user_id!:string;
  countries: { isoCode: string, name: string }[] = [];
  states: { isoCode: string, name: string }[] = [];
  cities: { name: string }[] = [];
  is_kyc_completed:boolean=false;

  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.UnitedStates];
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  PhoneNumberFormat = PhoneNumberFormat;
  CountryISO = CountryISO;

  constructor(
    private fb: FormBuilder,
    private toast: HotToastService,
    private auth:AuthService,
    private apiService:ApiService,
    private router:Router,
    private viewportScroller: ViewportScroller,
    private route: ActivatedRoute
  ) {
    if(this.auth.isAuthenticated() && this.auth.getData().role==='investor'){
      this.email=this.auth.getData().email;
      this.user_id = this.auth.getData().user_id;
    }
    else{
      this.router.navigate(['/'])
    }
  }

  ngOnInit(): void {
    this.initializeForm();
    this.loadCountries();
    if(localStorage.getItem('is_kyc_completed')==='true'){
      this.is_kyc_completed=true
    }
  }

  uploadProof(event: Event, type: string): void {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];

      // You can set the file to the form control
      this.kycForm.get(`${type}_proof`)?.setValue(file);
    }
  }

  checkForm(type:any) {
    if (type == 'profile') {
      if(this.kycForm.get('email')?.value && this.kycForm.get('phone_number')?.value && 
         this.kycForm.get('residential')?.value && this.kycForm.get('date_of_birth')?.value && 
         this.kycForm.get('gender')?.value && this.kycForm.get('profession')?.value && 
         this.kycForm.get('father_name')?.value
              ) {
         return false
      }
    } else if (type == 'address') {
        if(this.kycForm.get('country')?.value && this.kycForm.get('pincode')?.value && 
          this.kycForm.get('area')?.value && this.kycForm.get('state')?.value && 
          this.kycForm.get('city')?.value && this.kycForm.get('document_type')?.value && 
          this.kycForm.get('document_number')?.value && this.kycForm.get('document_front')?.value
                ) {
          return false
        }
      } else if (type == 'pan') {
        if(this.kycForm.get('pan_number')?.value && this.kycForm.get('pan_card')?.value) {
          return false
        }
      } else if (type == 'bank') {
        if(this.kycForm.get('ifsc_code')?.value && this.kycForm.get('account_name')?.value && 
          this.kycForm.get('account_number')?.value && this.kycForm.get('account_number_conf')?.value && 
          this.kycForm.get('statement')?.value && this.kycForm.get('bank_name')?.value
                ) {
          return false
        }
      }
    return true

  }
  

  initializeForm(): void {
    this.kycForm = this.fb.group({
      // Personal Information
      phone_number: ['', Validators.required],
      email: [{ value: this.email, disabled: true }, [Validators.required, Validators.email]],
      residential: ['', Validators.required],
      date_of_birth: ['', Validators.required],
      gender: ['', Validators.required],
      profession: ['', Validators.required],
      organization: [''],
      father_name: ['', Validators.required],
      
      // Address Details
      country: ['', Validators.required],
      pincode: ['', Validators.required],
      area: ['', Validators.required],
      landmark: [''],
      state: ['', Validators.required],
      city: ['', Validators.required],
      document_type: ['', Validators.required],
      document_number: ['', Validators.required],
      document_front: [null, Validators.required],
      document_back: [null],
      
      // PAN Details
      pan_number: ['', Validators.required],
      pan_card: [null, Validators.required],
      
      // Bank Account Details
      ifsc_code: ['', Validators.required],
      account_name: ['', Validators.required],
      account_number: ['', Validators.required],
      account_number_conf: ['', Validators.required],
      statement: [null, Validators.required],
      bank_name: ['', Validators.required]
    });
  }

  loadCountries(): void {
    this.countries = Country.getAllCountries().map(c => ({ isoCode: c.isoCode, name: c.name }));
  }

  onCountryChange(event: Event): void {
    const countryCode = (event.target as HTMLSelectElement).value;
    if (countryCode) {
      this.states = State.getStatesOfCountry(countryCode).map(s => ({ isoCode: s.isoCode, name: s.name }));
      this.cities = [];
      this.kycForm.get('state')?.setValue('');
      this.kycForm.get('city')?.setValue('');
    } else {
      this.states = [];
      this.cities = [];
    }
  }

  onStateChange(event: Event): void {
    const stateCode = (event.target as HTMLSelectElement).value;
    const countryCode = this.kycForm.get('country')?.value;
    
    if (countryCode && stateCode) {
      this.cities = City.getCitiesOfState(countryCode, stateCode).map(c => ({ name: c.name }));
    } else {
      this.cities = [];
    }
    this.kycForm.get('city')?.setValue('');
  }


  isOtherCitySelected = false;

  onCityChange(event: any) {
    this.isOtherCitySelected = event.target.value === 'Other';
  }

  selectSection(section: string): void {
    this.selectedSection = section;
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  getFormHeading(): string {
    switch (this.selectedSection) {
      case 'addressDetails':
        return 'Address Details';
      case 'panDetails':
        return 'PAN Details';
      case 'bankAccountDetails':
        return 'Bank Account Details';
      case 'reviewSubmit':
        return 'Review & Submit';
      default:
        return 'Personal Information';
    }
  }

  onFileChange(event: Event, controlName: string) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.kycForm.patchValue({ [controlName]: input.files[0] });
    }
  }

  onPhoneNumberChange(event: Event, controlName:string){
    const input = event.target as HTMLInputElement;
  }

  isFormComplete(): boolean {
    return this.kycForm.valid;
  }

  validateAccountNumber(){
    if(this.kycForm.get('account_number')?.value !== this.kycForm.get('account_number_conf')?.value){
      this.toast.error('Account Number Does not match please check!')
      this.kycForm.patchValue({
        account_number:null,
        account_number_conf:null
      })
    }
  }

  validateDOB(){
    const dob = this.kycForm.get('date_of_birth')?.value
    const date_of_birth = new Date(dob)
    const today = new Date()

    date_of_birth.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    if(date_of_birth >= today){
      this.toast.error('Date of Birth should be a older date!')
      this.kycForm.patchValue({
        date_of_birth:null
      })
    }
  }

  redirectToProperties(): void {
    this.router.navigate(['/properties']); // Adjust the route path as per your routing configuration
  }


  onSubmit(): void {
    if (this.kycForm.valid) {
      const mobile_number = this.kycForm.get('phone_number')?.value;
      this.kycForm.patchValue({
        phone_number:mobile_number.e164Number
      })
      // Create a FormData object to handle file and other data submission
      const formData = new FormData();

      // Append form data to FormData object
      Object.keys(this.kycForm.controls).forEach(key => {
        const control = this.kycForm.get(key);
        if (control && control.value) {
          if (key === 'documnt_front' || key === 'document_back' || key === 'pan_card' || key === 'statement') {
            // Append files
            formData.append(key, control.value);
          } else {
            // Append other fields
            formData.append(key, control.value);
          }
        }
      });
      const toastRef = this.toast.loading('Submitting your profile details',{
        autoClose:false
      })
      this.apiService.verifyKYC(formData,this.user_id).subscribe(response=>{
        toastRef.close()
        if(!response.error){
          this.toast.success('KYC Details Submitted Successfully!')
          this.route.queryParams.subscribe(params => {
            const propertyId = params['property']; // Extract property ID
      
            if (propertyId) {
              // Navigate to /property/<property_id> and remove query params
              this.router.navigate(['/property', propertyId], { replaceUrl: true });
            } else {
              console.error('Property ID is missing in query parameters.');
            }
          });
        } else {
          this.toast.error('Verification Failed.Please try again')
        }
      })
    } else {
      this.toast.error('KYC Verification Failed. Please try again with valid details!')

      // Loop through the form controls and print the errors
      Object.keys(this.kycForm.controls).forEach(key => {
        const controlErrors = this.kycForm.get(key)?.errors;
        if (controlErrors) {
          console.log(`Errors in ${key}:`, controlErrors);
        }
      });
    }
  }
}

<app-banner 
  [title]="'Key Risks'"
  [link]="'/key-risks'"
  [item]="'Key Risks'">
</app-banner>

<section class="faq key-faq section__space">
    <div class="container">
      <div class="key-faq__area">
        <div class="section__header">
          <h2 class="neutral-top">Key Risks</h2>
          <p class="neutral-bottom">
            Investing in property can be rewarding but, as with any investment
            there are risks. The Info Packs and the Investment Terms and
            Conditions cover the risks specific to a particular investment but
            it is also important that, before investing, you understand the
            following general risks..
          </p>
        </div>
        <div class="faq__group">

 </div>
            </div>
            </div>
            </section>
           <section class="faq border" style="padding-bottom: 20px;">
                <div class="container">
                  <div class="faq__group">
                    <div class="accordion">
            
                        
                <div class="accordion-item" *ngFor="let item of risk; let i = index">
                  <h5 class="accordion-header" [attr.id]="'headingFundOne' + i">
                    <span class="icon_box">
                      <img src="assets/images/icons/message.png" alt="message" />
                    </span>
                    <button
                      class="accordion-button"
                      [ngClass]="{'collapsed': i !== 0}"
                      type="button"
                      [attr.data-bs-toggle]="'collapse'"
                      [attr.data-bs-target]="'#collapseFundOne' + i"
                      [attr.aria-expanded]="i === 0"
                      [attr.aria-controls]="'collapseFundOne' + i"
                    >
                      {{ item.title }}
                    </button>
                  </h5>
                  <div
                    [attr.id]="'collapseFundOne' + i"
                    class="accordion-collapse collapse"
                    [ngClass]="{'show': i === 0}"
                    [attr.aria-labelledby]="'headingFundOne' + i"
                  >
                    <div class="accordion-body">
                      <p>{{ item.details }}</p>
                    </div>
                  </div>
                </div>
             
              </div>
              </div>
              </div>
              </section>

       
           
            
<header class="header header--secondary">
    <nav class="navbar navbar-expand-xl">
        <div class="container">
            <a class="navbar-brand" href="/">
                <img src="assets/images/logo.png" alt="Logo" class="logo" />
            </a>
            <div class="navbar__out order-2 order-xl-3">
                <div class="nav__group__btn">
                    <a href="/login" class="log d-none d-sm-block"> Log In </a>
                    <a href="/register" class="button button--effect d-none d-sm-block"> 
                        Join Now <i class="fa-solid fa-arrow-right-long"></i> 
                    </a>
                </div>
                <button class="navbar-toggler d-block d-sm-none" type="button" data-bs-toggle="collapse"
                    data-bs-target="#primaryNav" aria-controls="primaryNav" aria-expanded="false"
                    aria-label="Toggle Primary Nav">
                    <span class="icon-bar top-bar"></span>
                    <span class="icon-bar middle-bar"></span>
                    <span class="icon-bar bottom-bar"></span>
                </button>
            </div>
            <div class="collapse navbar-collapse order-3 order-xl-2" id="primaryNav">
                <ul class="navbar-nav mx-auto"> <!-- Added mx-auto class here -->
                    <!-- Home -->
                    <li class="nav-item">
                        <a class="nav-link" href="/">Home</a>
                    </li>
                    <!-- About Us Dropdown -->
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown" href="#" id="aboutDropdown" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            About Us
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="aboutDropdown">
                            <li><a class="dropdown-item"  [routerLink]="['/our-vision']" (click)="scrollToVision()">Our Vision</a></li>
                            <li><a class="dropdown-item" [routerLink]="['/our-significance']" (click)="scrollToSignificance()">Our Significance</a></li>   
                         <li><a class="dropdown-item"[routerLink]="['/our-story']" (click)="scrollToOurStory()">Our Story</a></li>
                         <li><a class="dropdown-item"[routerLink]="['/our-team']" (click)="scrollToTeam()">Our Team</a></li>
                        </ul>
                    </li>
                    <!-- Blogs -->
                    <li class="nav-item">
                        <a class="nav-link" href="/blogs">Blogs</a>
                    </li>
                    <!-- Properties -->
                    <li class="nav-item">
                        <a class="nav-link" href="/properties">Properties</a>
                    </li>
                    <!-- Contact Us -->
                    <li class="nav-item">
                        <a class="nav-link" href="/contact-us">Contact Us</a>
                    </li>
                    <!-- FAQs -->
                    <li class="nav-item">
                        <a class="nav-link" href="/frequantly-ask-questions">FAQs</a>
                    </li>
                    <!-- Login and Join Now for Mobile -->
                    <li class="nav-item d-block d-sm-none">
                        <a href="/login" class="nav-link">Log In</a>
                    </li>
                    <li class="nav-item d-block d-sm-none">
                        <a href="/register" class="button button--effect button--last">Join Now
                            <i class="fa-solid fa-arrow-right-long"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>

import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { PasswordValidator } from '../auth/password-validator';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input-gg';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss']
})
export class UpdateUserComponent {

  userForm!: FormGroup;
  userId: string;
  passwordVisible: boolean = false;

  separateDialCode = true;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.India,CountryISO.UnitedStates,CountryISO.Canada];
  showTooltip = false;
  showConfirmPasswordTooltip = false;
  isMinLengthSatisfied: boolean = false;
  hasUppercase: boolean = false;
  hasLowercase: boolean = false;
  hasNumber: boolean = false;
  hasSpecialChar: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toast: HotToastService,
    private apiService:ApiService, 
    private toastr:ToastrService,
    private authService: AuthService
  ) {
    this.userId = this.route.snapshot.paramMap.get('id')!;
    if(this.userId) {
      this.getUserDetails();
    }
    if(authService.isAuthenticated() && authService.getData().role !== 'admin') {
      this.authService.redirectedToUserPages()
    }
  }

  ngOnInit() {
    this.userForm = this.fb.group({
      role: ['admin', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone_number : ['', Validators.required],
      password: ['', [Validators.required, PasswordValidator.strongPassword()]],
    });

  }

  getUserDetails() {
    this.apiService.getUser(this.userId).subscribe(response =>{
      if(!response.error) {
        this.initializeForm(response)
      }
    })
  }

  initializeForm(userData: any): void {
    this.userForm = this.fb.group({
      firstname: [userData.firstname, Validators.required],
      lastname: [userData.lastname, Validators.required],
      email: [userData.email, [Validators.required, Validators.email]],
      phone_number: [userData.phone_number, Validators.required],
      password: [''], // Password is optional here
      role: [userData.role, Validators.required],
    });
  }

  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible;
  }


  checkPassword(key:any) {
    const password = this.userForm.get(key)?.value || '';
    if(key == 'password') {
      this.showTooltip = password.length > 0;
    } else {
      this.showConfirmPasswordTooltip = password.length > 0;
    }

    this.isMinLengthSatisfied = password.length >= 8;
    this.hasUppercase = /[A-Z]/.test(password);
    this.hasLowercase = /[a-z]/.test(password);
    this.hasNumber = /[0-9]/.test(password);
    this.hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (this.isMinLengthSatisfied && this.hasUppercase && this.hasLowercase && this.hasNumber && this.hasSpecialChar) {
      if(key == 'password') {
        this.showTooltip = false;
      } else {
        this.showConfirmPasswordTooltip = false;
      }
    }
  }

  cancel(){
    this.router.navigate(['/users'])
  }

  onSubmit() {
    if (this.userForm.valid) {
      const formData = this.userForm.value;
      const phoneNumberControl = this.userForm.get('phone_number');
      if (phoneNumberControl && phoneNumberControl.valid) {
        formData.phone_number = phoneNumberControl.value.e164Number;
      }
      if (Object.keys(this.route.snapshot.queryParams).length > 0) {
        formData.referral = true;
      }
      if(this.userId) {
        if (formData.password === '') {
          delete formData.password;
        }
        const toastRef = this.toast.loading('Updating user details...');
        this.apiService.updateUser(this.userId,formData).subscribe(response => {
          if (!response.error) {
            toastRef.close();
            this.router.navigate(['/users'])
            this.toast.success('User updated successfully!');
          } else {
            this.toast.error('Something went wrong. Please try again later.');
          }
        });
      } else {
        const toastRef = this.toast.loading('Submitting your user...');
        this.apiService.addUser(formData).subscribe(response =>{
          if (response) {
            toastRef.close();
            this.router.navigate(['/users'])
            this.toast.success('Please check your email and verify !');
          }
          else{
            this.toast.error('Something went wrong.Please try again later.')
          }
        })
      }
     
    }
  }

}

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class PasswordValidator {
  static strongPassword(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasNumeric = /[0-9]/.test(value);
      const hasMinLength = value.length >= 8;
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
      const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && hasMinLength;

      if (!passwordValid) {
        return {
          strongPassword: {
            hasUpperCase,
            hasLowerCase,
            hasNumeric,
            hasMinLength,
            hasSpecialChar
          }
        };
      }

      return null;
    };
  }
}


<app-banner 
  [title]="'Privacy Policy'"
  [link]="'/privacy-policy'"
  [item]="'Privacy Policy'">
</app-banner>

 
 
 <section class="section__space">
        <div class="container">
          <div class="terms-area">
            <div class="terms__single">
              <h3 class="neutral-top">
                1. Introduction
              </h3>
              <p>
                Welcome to 22 Yards. We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your information when you interact with our services, whether through our website, mobile applications, or other platforms
              </p>
  
            </div>
            <hr />
            <div class="terms__single">
              <h3 class="neutral-top">
                2. Information We Collect
              </h3>
              <h5>2.1 Personal Information</h5>
  
              <p>
                When you interact with 22 Yards, we may collect personal information that identifies, relates to, describes, or is capable of being associated with you. This includes but is not limited to:
              </p>
              <br />
              <p>
                <span style= "font-size: 20px;font-weight: 600;">Contact Information: </span> Name, email address, phone number, mailing address.
              </p>
              <br />
              <p>
                <span style= "font-size: 20px;font-weight: 600;">Financial Information: </span> Bank account details, payment information, and investment preferences.
              </p>
              <p>
                <span style= "font-size: 20px;font-weight: 600;">Identification Information: </span>
                Social Security number, government-issued ID numbers, and other identification details.
              </p>
              <div class="terms__single">
                <h5 style="padding-top: 20px;" >
                  2.2 Non-Personal Information
                </h5>
                <p>
                  We may also collect non-personal information, which does not directly identify you. This may include:
                </p>
                <p>
                  <span style= "font-size: 20px;font-weight: 600;">Device Information:  </span>
                  IP address, browser type, operating system, and device type.
                </p>
                <p>
                  <span style= "font-size: 20px;font-weight: 600;">Usage Data: </span>
                  Pages visited, time spent on the site, and navigation paths.
                </p>
  
              </div>
              <div class="terms__single">
                <h5 style="padding-top: 20px;" >
                  2.3 Information from Third Parties
                </h5>
                <p>We may receive information about you from third-party sources, such as public databases, marketing partners, and social media platforms, to enhance our ability to provide relevant services.</p>
              </div>
            </div>
            <hr />
            <div class="terms__single">
              <h3 class="neutral-top">
                3. How We Use Your Information
              </h3>
              <h5>3.1 To Provide and Improve Our Services</h5>
  
              <h6 style="padding: 20px 0px 10px 0px;" >We use your information to:</h6>
              <p>
                Facilitate your investments and transactions.<br />
                Provide customer support and respond to inquiries.<br />
                Enhance, improve, and personalize our Services.
                <br />
              </p>
  
              <div class="terms__single">
  
                <h5 style="padding-top: 20px;" >3.2 For Marketing and Communications</h5>
  
                <h6 style="padding: 20px 0px 10px 0px;" >We may use your information to:</h6>
                <p>
                  Send promotional materials, newsletters, and other communications.<br />
                  Conduct surveys and market research to improve our Services.<br />
                  Offer targeted advertisements based on your interests.
  
                  <br />
                </p>
  
  
              </div>
  
              <div class="terms__single">
  
                <h5 style="padding-top: 20px;" >3.3 For Legal and Compliance Purposes</h5>
  
                <h6 style="padding: 20px 0px 10px 0px;" >We may use your information to:</h6>
                <p>
                  Comply with legal obligations and regulations.<br />
                  Detect, prevent, and respond to fraud, security breaches, or other illegal activities.<br />
                  Enforce our terms of service and other legal agreements.
  
                </p></div>
  
            </div>
            <hr/>
            <div class="terms__single">
              <h3 class="neutral-top">
              4. Sharing Your Information
              </h3>
              <h5>4.1 With Service Providers</h5>
             <p>We may share your information with third-party service providers who assist us in operating our Services,
               such as payment processors, marketing agencies, and IT support.</p>  
  
               <div class="terms__single">
                <h5 style="padding-top: 20px;" >
                4.2 With Business Partners
                </h5>
                <p>We may share your information with trusted business partners 
                  to offer joint services or products that may interest you.</p>
                </div>
                <div class="terms__single">
                <h5 style="padding-top: 20px;" >
                4.3 For Legal Reasons
                </h5>
                <p>We may disclose your information if required by law, 
                  regulation, or legal process, or in response to a government request.</p>
                </div>
  
                <div class="terms__single">
                <h5 style="padding-top: 20px;" >
                4.4 In Connection with Business Transfers
                </h5>
                <p>If we undergo a merger, acquisition, or sale of all or part of our assets, 
                  your information may be transferred as part of that transaction.</p>
                </div>
              </div> 
              <hr/>
  
             <div class="terms__single">
                <h3 class="neutral-top">
                5. Data Security
                </h3>
                <h5>
                5.1 Security Measures
                </h5>
                <p>We implement industry-standard security measures to protect your information from unauthorized access, alteration, disclosure, or destruction.
                 These measures include encryption, firewalls, and secure server environments.</p>
                <div class="terms__single">
                  <h5  style="padding-top: 20px;" >
                  5.2 Retention of Information
                  </h5>
                  <p>We retain your information for as long as necessary to fulfill the purposes outlined in this Privacy Policy,
                   comply with legal obligations, resolve disputes, and enforce our agreements.</p>
                </div>
  
                </div>
                <hr/>
                <div class="terms__single">
                <h3 class="neutral-top">
                6. Your Rights and Choices
                </h3>
                <h5>
                6.1 Access and Correction
                </h5>
                <p>You have the right to access, correct, or update your personal information at any time. 
                  You can do so by contacting us using the details provided below.</p>
                  <div class="terms__single">
                <h5 style="padding-top: 20px;" >
                6.2 Opt-Out
                </h5>
                <p>You may opt-out of receiving promotional communications from us by following the unsubscribe
                   instructions in the emails we send or by contacting us directly.</p>
                </div>
  
                <div class="terms__single">
                <h5 style="padding-top: 20px;" >
               6.3 Data Deletion
                </h5>
                <p>You can request the deletion of your personal information, subject to certain exceptions such as compliance with legal obligations.
                   Please note that we may retain certain information as required by law or for legitimate business purposes.</p>
                </div>
  
                </div>
             <hr/>
                <div class="terms__single">
                <h3 class="neutral-top">
                7. Cookies and Tracking Technologies
                </h3>
                <h5>
                7.1 Use of Cookies
                </h5>
                <p>We use cookies and similar tracking technologies to enhance your experience on our site, 
                  analyze usage patterns, and deliver personalized content. 
                  You can control the use of cookies through your browser settings.</p>
                 
                  <div class="terms__single">
                <h5 style="padding-top: 20px;" >
                7.2 Third-Party Tracking
                </h5>
                <p>We may allow third-party service providers to use cookies and other
                   tracking technologies to collect information about your interactions with our
                    Services and deliver targeted advertisements.</p>
  
                </div>
                </div>
                <hr/>
                <div class="terms__single">
              <h3 class="neutral-top">
              8. International Data Transfers
              </h3>
              <p>
              If you are accessing our Services from outside the India,
              your information may be transferred to, stored, and processed in the India.
              By using our Services, you consent to the transfer of your information to the India,
              where the data protection laws may differ from those in your country.
               </p>
               
  
            </div>
  
            <hr/>
            <div class="terms__single">
              <h3 class="neutral-top">
              9. Changes to This Privacy Policy
              </h3>
              <p>
              We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes by posting the new Privacy Policy on our site and updating the effective date.
               </p>
               
  
            </div>
  
          </div>
        </div>
      </section>
    
          
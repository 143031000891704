import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit{

  cmsUrl:any;
  blogs:any;

  constructor(
    private apiService: ApiService,
  ){
    this.cmsUrl = environment.cms_url
  }

  ngOnInit(): void {
    this.getBlogs()
  }

  getBlogs(params:any={}){
    this.apiService.getBlogsData(params).subscribe(response=>{
      this.blogs = response.data
      this.blogs.slice(0,3)
    })
  }


}

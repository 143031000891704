import { Component, OnInit } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { AuthService } from '../shared/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input-gg';
import { Country, State, City } from 'country-state-city';
import { Router } from '@angular/router';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { HotToastService } from '@ngneat/hot-toast';

@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.scss']
})
export class MyprofileComponent implements OnInit {

  user_id!: string;
  userDetails!: any;
  userDocuments: any[] = [];
  profileDetails: any;
  is_kyc_completed: boolean = false;
  personalDetailsForm: FormGroup;
  addressDetailsForm: FormGroup; // New form for Address Details
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.UnitedStates];
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode = true;
  PhoneNumberFormat = PhoneNumberFormat;
  countries: { isoCode: string, name: string }[] = [];
  states: { isoCode: string, name: string }[] = [];
  cities: { name: string }[] = [];
  faEye = faEye;

  // Flags to track form changes
  isPersonalDetailsFormChanged: boolean = false;
  isAddressDetailsFormChanged: boolean = false;
  isOtherCitySelected = false;
  selectedImageUrl: string | null = null;
  displayDialog: boolean = false;
  document_front!: any;
  document_back!: any;
  pan_card!: any;

  // To store initial form values for comparison
  initialPersonalDetailsValues: any;
  initialAddressDetailsValues: any;
  
  // Flag to prevent unintended dirty states during initialization
  isInitializing: boolean = true;

  constructor(
    private apiService: ApiService,
    private auth: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private toast: HotToastService
  ) {
    if (this.auth.isAuthenticated()) {
      this.apiService.getVerificationStatus(this.auth.getData().user_id).subscribe(response => {
        this.is_kyc_completed = response['is_kyc_completed']
      });
    } else {
      this.router.navigate(['login']);
    }

    // Initialize the forms in the constructor
    this.personalDetailsForm = this.fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      phone_number: ['', [Validators.required]],
      email: [{ value: '', disabled: true }, [Validators.required, Validators.email]],
      date_of_birth: ['', Validators.required],
      gender: ['', Validators.required],
      father_name: ['', Validators.required]
    });

    this.addressDetailsForm = this.fb.group({
      area: ['', Validators.required],
      city: ['', Validators.required],
      cityInput: [''],
      state: ['', Validators.required],
      country: ['', Validators.required],
      pincode: ['', [Validators.required, Validators.pattern('^[0-9]{6}$')]],  // Assuming pincode format
    });
  }

  ngOnInit(): void {
    this.loadCountries();
    if (this.auth.isAuthenticated()) {
      this.user_id = this.auth.getData().user_id;
      this.apiService.getUser(this.user_id).subscribe(response => {
        this.userDetails = response;
        this.userDocuments = response.user_documents || []; // Ensure userDocuments is initialized
        this.document_front = this.userDocuments.find(doc => doc.name === 'document_front');
        this.document_back = this.userDocuments.find(doc => doc.name === 'document_back');
        this.pan_card = this.userDocuments.find(doc => doc.name === 'pan_card');
        this.loadUserData();
      });

      // Subscribe to value changes to track form modifications
      this.personalDetailsForm.valueChanges.subscribe(() => {
        if (!this.isInitializing) {
          this.checkFormChanges();
        }
      });

      this.addressDetailsForm.valueChanges.subscribe(() => {
        if (!this.isInitializing) {
          this.checkFormChanges();
        }
      });
    }
  }

  loadCountries(): void {
    this.countries = Country.getAllCountries().map(c => ({ isoCode: c.isoCode, name: c.name }));
  }

  onCountryChange(event: Event): void {
    const countryCode = (event.target as HTMLSelectElement).value;
    if (countryCode) {
      this.states = State.getStatesOfCountry(countryCode).map(s => ({ isoCode: s.isoCode, name: s.name }));
      this.cities = [];
      this.addressDetailsForm.get('state')?.setValue('');
      this.addressDetailsForm.get('city')?.setValue('');
    } else {
      this.states = [];
      this.cities = [];
    }
  }

  onStateChange(event: Event): void {
    const stateCode = (event.target as HTMLSelectElement).value;
    const countryCode = this.addressDetailsForm.get('country')?.value;

    if (countryCode && stateCode) {
      this.cities = City.getCitiesOfState(countryCode, stateCode).map(c => ({ name: c.name }));
    } else {
      this.cities = [];
    }
    this.addressDetailsForm.get('city')?.setValue('');
  }

  onCityChange(event: any) {
    if (event.target.value === 'Other') {
      this.isOtherCitySelected = true;
      this.addressDetailsForm.get('cityInput')?.setValidators([Validators.required]); // Require city input
      this.addressDetailsForm.get('city')?.clearValidators(); // Clear validators for city dropdown
    } else {
      this.isOtherCitySelected = false;
      this.addressDetailsForm.get('cityInput')?.clearValidators(); // Clear validators for city input
      this.addressDetailsForm.get('city')?.setValidators([Validators.required]); // Require city dropdown
    }
    this.addressDetailsForm.get('cityInput')?.updateValueAndValidity();
    this.addressDetailsForm.get('city')?.updateValueAndValidity();
  }

  loadUserData() {
    this.isInitializing = true;  // Set the flag to true during initialization

    if (this.userDetails) {
        this.profileDetails = this.userDetails.user_profile_details[0] || {};
        // Using patchValue instead of setValue
        this.personalDetailsForm.patchValue({
            firstname: this.userDetails.firstname || '',
            lastname: this.userDetails.lastname || '',
            phone_number: this.userDetails.phone_number || '',
            email: this.userDetails.email || '',
            date_of_birth: this.profileDetails?.date_of_birth || '', // Provide a fallback value
            gender: this.profileDetails.gender || '',
            father_name: this.profileDetails.father_name || ''
        });

        this.addressDetailsForm.patchValue({
            area: this.profileDetails.area || '',
            city: this.profileDetails.city || '',
            cityInput: this.isOtherCitySelected ? this.profileDetails.city : '',
            state: this.profileDetails.state || '',
            country: this.profileDetails.country || '',
            pincode: this.profileDetails.pincode || ''
        });

        // Store the initial form values after loading data
        this.initialPersonalDetailsValues = this.personalDetailsForm.getRawValue();
        this.initialAddressDetailsValues = this.addressDetailsForm.getRawValue();

        // Additional logic to handle city and state loading
        if (this.profileDetails.country) {
            this.addressDetailsForm.patchValue({ country: this.profileDetails.country });

            this.states = State.getStatesOfCountry(this.profileDetails.country).map(s => ({ isoCode: s.isoCode, name: s.name }));

            if (this.profileDetails.state) {
                this.addressDetailsForm.patchValue({ state: this.profileDetails.state });

                this.cities = City.getCitiesOfState(this.profileDetails.country, this.profileDetails.state).map(c => ({ name: c.name }));
                if (this.cities.find(city => city.name === this.profileDetails.city)) {
                    this.addressDetailsForm.patchValue({ city: this.profileDetails.city });
                    this.isOtherCitySelected = false;
                } else {
                    this.addressDetailsForm.patchValue({ city: 'Other', cityInput: this.profileDetails.city });
                    this.isOtherCitySelected = true;
                }
            }
        }

        this.isInitializing = false;  // Reset the flag after initialization
    }
}


  checkFormChanges() {
    this.isPersonalDetailsFormChanged = JSON.stringify(this.personalDetailsForm.getRawValue()) !== JSON.stringify(this.initialPersonalDetailsValues);
    this.isAddressDetailsFormChanged = JSON.stringify(this.addressDetailsForm.getRawValue()) !== JSON.stringify(this.initialAddressDetailsValues);
  }

  viewImage(imageUrl: string): void {
    this.selectedImageUrl = imageUrl;
    this.displayDialog = true;
  }

  onSubmitPersonalDetails() {
    if (this.personalDetailsForm.valid) {
      const personalDetails = this.personalDetailsForm.getRawValue();
  
      // Fetch the current user data to include address details in the payload
      this.apiService.getUser(this.user_id).subscribe(
        (userDetails: any) => {
          const existingAddressDetails = userDetails?.user_profile_details[0] || {};
  
          // Prepare the payload with both personal and address details
          const payload = {
            user_profile_details: [
              {
                ...existingAddressDetails, // Include existing address details
                date_of_birth: personalDetails.date_of_birth,
                gender: personalDetails.gender,
                father_name: personalDetails.father_name,
              }
            ],
            firstname: personalDetails.firstname,
            lastname: personalDetails.lastname,
            phone_number: personalDetails.phone_number.e164Number, // Use e164Number format for phone
          };
  
          this.apiService.updateUser(this.user_id, payload).subscribe(
            response => {
              this.toast.success('Personal Details updated Successfully');
              this.isPersonalDetailsFormChanged = false; // Reset the flag
              this.personalDetailsForm.markAsPristine(); // Mark form as pristine
              this.initialPersonalDetailsValues = this.personalDetailsForm.getRawValue(); // Update initial values
            },
            error => {
              this.toast.error('Personal details update failed:', error);
            }
          );
        },
        error => {
          this.toast.error('Failed to fetch user details:', error);
        }
      );
    } else {
      this.toast.error('Personal Details Form is invalid');
    }
  }
  
  onSubmitAddressDetails() {
    if (this.addressDetailsForm.valid) {
      const addressDetails = this.addressDetailsForm.getRawValue();
  
      if (this.isOtherCitySelected) {
        addressDetails.city = this.addressDetailsForm.get('cityInput')?.value;
      }
  
      // Fetch the current user data to include personal details in the payload
      this.apiService.getUser(this.user_id).subscribe(
        (userDetails: any) => {
          const existingPersonalDetails = userDetails?.user_profile_details[0] || {};
  
          // Prepare the payload with both personal and address details
          const payload = {
            user_profile_details: [
              {
                ...existingPersonalDetails, // Include existing personal details
                area: addressDetails.area,
                city: addressDetails.city,
                state: addressDetails.state,
                country: addressDetails.country,
                pincode: addressDetails.pincode,
              }
            ]
          };
  
          this.apiService.updateUser(this.user_id, payload).subscribe(
            response => {
              this.toast.success('Address updated Successfully');
              this.isAddressDetailsFormChanged = false; // Reset the flag
              this.addressDetailsForm.markAsPristine(); // Mark form as pristine
              this.initialAddressDetailsValues = this.addressDetailsForm.getRawValue(); // Update initial values
            },
            error => {
              this.toast.error('Address details update failed:', error);
            }
          );
        },
        error => {
          this.toast.error('Failed to fetch user details:', error);
        }
      );
    } else {
      this.toast.error('Address Details Form is invalid');
    }
  }
  
  
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from './shared/services/auth-guard.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MyInvestmentsComponent } from './my-investments/my-investments.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { MyprofileComponent } from './myprofile/myprofile.component';
import { LandingComponent } from './landing/landing.component';
import { VerifyEmailComponent } from './auth/verify-email/verify-email.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { UsersComponent } from './users/users.component';
import { UpdateUserComponent } from './update-user/update-user.component';
import { UpdatePropertyComponent } from './update-property/update-property.component';
import { PropertiesListComponent } from './properties-list/properties-list.component';
import { ChannelpartnerReferlsComponent } from './channelpartner-referls/channelpartner-referls.component';
import { UpdateChannelpartnerReferalComponent } from './update-channelpartner-referal/update-channelpartner-referal.component';
import { KycComponent } from './kyc/kyc.component';
import { AboutUsComponent } from './staticPages/about-us/about-us.component';
import { ContactusComponent } from './staticPages/contact-us/contact-us.component';
import { PrivacyComponent } from './staticPages/privacy/privacy.component'
import { TermsComponent } from './staticPages/terms/terms.component';
import { ShippingComponent } from './staticPages/shipping/shipping.component';
import { CancelRefundComponent } from './staticPages/cancel-refund/cancel-refund.component';
import { FAQSComponent } from './staticPages/faqs/faqs.component';
import { KeyRisksComponent } from './staticPages/key-risks/key-risks.component';
import { HowItWorksComponent } from './staticPages/how-it-works/how-it-works.component';
import { CookiePolicyComponent } from './staticPages/cookie-policy/cookie-policy.component';
import { BlogComponent } from './staticPages/blog/blog.component';
import { BlogDetailComponent } from './staticPages/blog-detail/blog-detail.component';


const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: '', loadChildren: () => import('./property/property.module').then(m => m.PropertyModule) },
  { path: 'verify-email', component: VerifyEmailComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'add-user', component: UpdateUserComponent, canActivate: [AuthGuard] },
  { path: 'update-user/:id', component: UpdateUserComponent, canActivate: [AuthGuard] },
  { path: 'all-properties', component: PropertiesListComponent, canActivate: [AuthGuard] },
  { path: 'add-property', component: UpdatePropertyComponent, canActivate: [AuthGuard] },
  { path: 'update-property/:id', component: UpdatePropertyComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'investments', component: MyInvestmentsComponent, canActivate: [AuthGuard] },
  { path: 'transactions', component: TransactionsComponent, canActivate: [AuthGuard] },
  { path: 'referrals', component: ChannelpartnerReferlsComponent, canActivate: [AuthGuard] },
  { path: 'add-referral', component: UpdateChannelpartnerReferalComponent, canActivate: [AuthGuard] },
  { path: 'update-referral/:id', component: UpdateChannelpartnerReferalComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: MyprofileComponent, canActivate: [AuthGuard] },
  { path: 'kyc', component: KycComponent, canActivate: [AuthGuard] },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'our-vision', component: AboutUsComponent },
  { path: 'our-significance', component: AboutUsComponent },
  { path: 'our-story', component: AboutUsComponent },
  { path: 'our-team', component: AboutUsComponent },
  { path: 'contact-us', component: ContactusComponent },
  { path: 'privacy-policy', component: PrivacyComponent },
  { path: 'cookie-policy', component: CookiePolicyComponent },
  { path: 'terms-and-conditions', component: TermsComponent },
  { path: 'shipping-and-delivery', component: ShippingComponent },
  { path: 'cancel-and-refund', component: CancelRefundComponent },
  { path: 'frequantly-ask-questions', component: FAQSComponent },
  { path: 'key-risks', component: KeyRisksComponent },
  { path: 'how-it-works', component: HowItWorksComponent },
  { path: 'blogs', component: BlogComponent },
  { path: 'blogs/:slug', component: BlogDetailComponent },
  { path: '**', redirectTo: '' } // Place this at the end
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

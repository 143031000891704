import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../shared/services/api.service';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {

  details :any;
  property_images: any;
  investment_properties: any;
  remaining_properties: any;
  isImagesLoading = true;
  constructor(
    private router:Router,
    private apiService:ApiService, 
    private authService: AuthService,
  ){
      if(authService.isAuthenticated() && authService.getData().role !== 'investor') {
        this.authService.redirectedToUserPages()
      }
      this.getDashboard()
  }

  getDashboard() {
    this.apiService.getDashboard().subscribe(
      (data: any) => {
        if (!data['error']) {
          this.details = data;
          this.investment_properties = data.investment_properties;
          this.remaining_properties = data.remaining_properties;

          // Fetch property images
          this.apiService.GetAllPropertyImages(false).subscribe(property_images => {
            this.property_images = property_images;
            
            // Map images to properties
            this.investment_properties = this.mapImagesToProperties(this.investment_properties, this.property_images);
            this.remaining_properties = this.mapImagesToProperties(this.remaining_properties, this.property_images);
            // Set loading state to false once images are mapped
            this.isImagesLoading = false;
          }, error => {
            console.error('Error fetching property images:', error);
            this.isImagesLoading = false;
          });
        }
      },
      error => {
        console.error('API error:', error);
      }
    );
  }

  mapImagesToProperties(properties: any[], images: any[]): any[] {
    return properties.map(property => {
      // Find the corresponding images object based on property_id
      const matchingImages = images.filter(img => img.property_id === property.property_id);
      // Return a new object with the images mapped
      return {
        ...property,
        images: matchingImages || []
      };
    });
  }

  getPropertyImages(property: any) {
    if(property.images) {
      // Check for both possible keys and filter images based on 'propertyImages'
      const images = property.images[0]['property_images'] || property.images[0]['propertyImages'] || [];
      return images.filter((image: any) => image.name === 'propertyImages');
    }
    return []
  }
  




}

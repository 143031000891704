import { Component, OnInit } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { ApiService } from '../shared/services/api.service';
import { AuthService } from '../shared/services/auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-properties-list',
  templateUrl: './properties-list.component.html',
  styleUrls: ['./properties-list.component.scss']
})
export class PropertiesListComponent implements OnInit{


  properties:any[]=[]
  constructor(
    private toast:HotToastService,
    private apiService:ApiService,
    private router:Router,
    private authService: AuthService,
  ){
     if(authService.isAuthenticated() && (authService.getData().role !== 'support' && authService.getData().role !== 'admin')) {
      this.authService.redirectedToUserPages()
    }
  }

  ngOnInit(): void {
    this.getProperties();
  }
  updateProperty(propertyId:string){
    this.router.navigate(['update-property',propertyId])
  }
  addProperty(){
    this.router.navigate(['add-property'])
  }
  deleteProperty(propertyId:any) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        this.apiService.deleteProperty(propertyId).subscribe(response => {
          this.getProperties();
        });
      }
    });
  }

  getProperties(){
    this.apiService.ListAllProperties().subscribe(response =>{
      this.properties=response
    })
  }
}

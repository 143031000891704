<app-banner 
  [title]="'How It Works'"
  [link]="'/how-it-works'"
  [item]="'How it works'">
</app-banner>
<section class="section__space">
<div class="video">
    <div class="container">
      <div class="video__area">
        <img src="assets/images/Thumbnail.jpg" alt="video illustration" />
        <div class="video__btn">
          <a
            class="video__popup"
            title="YouTube video player"
            href="https://www.youtube.com/embed/watch?v=NY1Y_p1cU1s"
            target="_blank"
          >
           
            <i class="fa fa-play"></i>
          </a>
        </div>
      </div>
    </div>
  </div>

</section>
  <section style="padding-bottom: 20px;">
    <div class="container">
      <div class="start__area">
        <div class="row">
            <div class="col-md-6 col-xl-4">
              <div class="start__single__item column__space--secondary">
                <div class="img__box">
                  <img src="../../../assets/images/step/browse.png" alt="Browse Properties" />
                  <div class="step__count">
                    <h4>01</h4>
                  </div>
                </div>
                <h4>Browse Properties</h4>
                <p class="neutral-bottom">
                  Select a property that fits your goal from our huge variety of
                  hand-picked properties.
                </p>
              </div>
            </div>
            <div class="col-md-6 col-xl-4">
              <div class="start__single__item column__space--secondary">
                <div class="img__box arrow__container">
                  <img src="../../../assets/images/step/invest.png" alt="View Details & Invest" />
                  <div class="step__count">
                    <h4>02</h4>
                  </div>
                </div>
                <h4>View Details & Invest</h4>
                <p class="neutral-bottom">
                  View detailed metrics for that property like Rental Yield, etc. and
                  invest like institutions.
                </p>
              </div>
            </div>
            <div class="col-md-6 col-xl-4">
              <div class="start__single__item">
                <div class="img__box">
                  <img src="../../../assets/images/step/earn.png" alt="Earn and Track" />
                  <div class="step__count">
                    <h4>03</h4>
                  </div>
                </div>
                <h4>Earn and Track</h4>
                <p class="neutral-bottom">
                  You have full visibility into the performance of your investment.
                  Track your total current.
                </p>
              </div>
            </div>
          </div>
          



        </div>
        </div>
        </section>
  


        <section
  class="community section__space__top over__hi bg__img"
  [attr.data-background]="'./assets/images/community-bg.png'"
  style="padding-bottom: 20px;"
>
  <div class="container">
    <div class="community__area">
      <div class="section__header">
        <h5 class="neutral-top">Smart way to raise money</h5>
        <h2>Join Thousands of Investors</h2>
        <p class="neutral-bottom">
          Individual and institutional investors invest $10–$100,000 per deal on 22 Yards.
        </p>
      </div>
      <div class="comunity-wrapper section__space">
        <div class="buttons">
          <a href="/register" class="button button--effect">Become an Investor</a>
        </div>
        <div class="comunity-area">
          <div *ngFor="let item of comunityImages" class="comunity-item">
            <img [src]="item" alt="comunity" />
          </div>
        </div>
        <div class="comunity-area two">
          <div *ngFor="let item of comunityImages" class="comunity-item">
            <img [src]="item" alt="comunity" />
          </div>
        </div>
        <div class="comunity-area three">
          <div *ngFor="let item of comunityImages" class="comunity-item">
            <img [src]="item" alt="comunity" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

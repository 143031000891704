<div class="dashboard section__space__bottom">
    <div class="container">
        <div class="dashboard__area">
            <div class="row">
                <div class="col-xxl-3">
                    <app-navigation></app-navigation>
                </div>
                <div class="col-xxl-9">
                    <div class="main__content">
                        <div class="collapse__sidebar">
                            <h4>Referrals</h4>
                            <a href="javascript:void(0)" class="collapse__sidebar__btn">
                                <i class="fa-solid fa-bars-staggered"></i>
                            </a>
                        </div>
                        <div class="main__content-dashboard">
                            <div class="breadcrumb-dashboard">
                                <h5>Referrals<span *ngIf="referalls">({{referalls.length}})</span></h5>
                                <div>
                                   <button class="button button--effect pointer" (click)="addReferal()">+ Add Referral</button>
                                </div>
                            </div>
                        </div>
                        <div class="investment-table investment-table-two">
                            <div class="table-wrapper">
                                <table>
                                    <tr>
                                        <th>Propety</th>
                                        <th>Name</th>
                                        <th>Contact</th>
                                        <th>No. of shares</th>
                                        <th>Status</th>
                                        <th>last_action_at</th>
                                        <th>Actions</th>
                                    </tr>
                                    <tr *ngIf="referalls && referalls.length === 0">
                                        <td colspan="7" class="text-center">No referrals found</td>
                                    </tr>
                                    <tr *ngFor="let referal of referalls">
                                        <td>
                                            {{referal.property_name}}
                                        </td>
                                        <td>
                                            {{referal.investor.firstname}}
                                        </td>
                                        <td>
                                            {{referal.investor.phone_number}}
                                        </td>
                                        <td>
                                            {{referal.no_of_shares}}
                                        </td>
                                        <td>{{referal.status}}</td>
                                        <td>{{ referal.updated_at | date: 'short'}}</td>
                                        <td>
                                            <img class="actions_img pointer" src="assets/images/icons/edit.png" alt="Edit" (click)="updateReferal(referal.id)"/>
                                            <img class="actions_img pointer" src="assets/images/icons/delete.png" alt="Delete" (click)="deleteReferal(referal.id)"/>
                                        </td>
                                    </tr>
                                    
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { PasswordValidator } from '../auth/password-validator';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input-gg';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-update-channelpartner-referal',
  templateUrl: './update-channelpartner-referal.component.html',
  styleUrls: ['./update-channelpartner-referal.component.scss']
})
export class UpdateChannelpartnerReferalComponent {

  referalForm!: FormGroup;
  referalId :any;
  properties:any =  []
  currentInvestorId:any;
  separateDialCode = true;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.India,CountryISO.UnitedStates,CountryISO.Canada];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toast: HotToastService,
    private apiService:ApiService, 
    private toastr:ToastrService,
    private authService: AuthService
  ) {
    this.referalId = this.route.snapshot.paramMap.get('id')!;
    if(this.referalId) {
      this.getReferalDetails();
    }
    if(authService.isAuthenticated() && authService.getData().role !== 'channelpartner') {
      this.authService.redirectedToUserPages()
    }
  }

  ngOnInit() {
    this.referalForm = this.fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone_number : ['', Validators.required],
      numberofshares : ['', Validators.required],
      type: ['', Validators.required],
      property : ['', Validators.required],
      channel_partner_id: [this.authService.getData().user_id, Validators.required],
    });
  }

  onPropertyTypeChange(event: any): void {
    const selectedType = event.target.value;
    if (selectedType) {
      this.fetchProperties(selectedType);
    } else {
      this.properties = []; // Clear properties if no type is selected
    }
  }

  fetchProperties(type:any){
    this.apiService
    .getPropertyNamesByType({"type":type})
    .subscribe(resp => {
      if(resp['data']) {
        this.properties = resp['data']
      }
    });
  }

  getReferalDetails() {
    this.apiService.getReferel(this.referalId).subscribe(response =>{
      if(!response.error) {
        this.fetchProperties(response.type)
        this.initializeForm(response)
      }
    })
  }

  initializeForm(data: any): void {
    this.referalForm = this.fb.group({
      firstname: [data.investor.firstname, Validators.required],
      lastname: [data.investor.lastname, Validators.required],
      email: [data.investor.email, [Validators.required, Validators.email]],
      phone_number : [data.investor.phone_number, Validators.required],
      numberofshares : [data.no_of_shares, Validators.required],
      type: [data.type, Validators.required],
      property : [data.property, Validators.required],
      channel_partner_id: [this.authService.getData().user_id, Validators.required],
    });
    this.currentInvestorId = data.investor.id
  }

  cancel() {
    this.router.navigate(['/referrals'])
  }

  onSubmit() {
    if (this.referalForm.valid) {
      const formData = this.referalForm.value;
      const phoneNumberControl = this.referalForm.get('phone_number');
      if (phoneNumberControl && phoneNumberControl.valid) {
        formData.phone_number = phoneNumberControl.value.e164Number;
      }
      if (Object.keys(this.route.snapshot.queryParams).length > 0) {
        formData.referral = true;
      }
      if(this.referalId) {
        if (formData.password === '') {
          delete formData.password;
        }
        formData.investor_id = this.currentInvestorId
        const toastRef = this.toast.loading('Updating Referral...');
        this.apiService.updateReferel(this.referalId,formData).subscribe(response => {
          if (!response.error) {
            toastRef.close();
            this.router.navigate(['/referrals'])
            this.toastr.success('Referral updated successfully!');
          } else {
            this.toastr.error('Something went wrong. Please try again later.');
          }
        });
      } else {
        const toastRef = this.toast.loading('Sending your referral...');
        this.apiService.addReferel(formData).subscribe(response =>{
          if (!response['error']) {
            toastRef.close();
            this.router.navigate(['/referrals'])
            this.toastr.success('Referred Sucessfully!');
          }
          else{
            this.toastr.error('Something went wrong.Please try again later.')
          }
        })
      }
     
    }
  }

}

<app-banner 
  [title]="'Terms & conditions'"
  [link]="'/terms-and-conditions'"
  [item]="'Terms & Conditions'">
</app-banner>
<section class="section__space">
    <div class="container">
      <div class="terms-area">
        <div class="terms__single">
          <h3 class="neutral-top">
          Terms & Conditions for Fractional Ownership with 22YARDS
          </h3>
          <p> Please carefully review the following key points related to your Fractional Ownership with 22YARDS, alongside the detailed Terms & Conditions provided. By signing up for the purchase of 22YARDS, you agree to the following terms:
          </p>
          
        </div>
        <hr />
        <div class="terms__single terms" >
        <p>
              <span style="font-size: 20px; font-weight: 600;">1.	Acknowledgment of Terms:   </span>
               I/we confirm that we have read and understood the Terms & Conditions associated with the special offer and fractional ownership of 22YARDS. We agree to abide by these terms and acknowledge that we have received all necessary clarifications and information.
          </p>
         
        </div>
        
        <div class="terms__single terms" >
        <p>
              <span style="font-size: 20px; font-weight: 600;">2.	Payment Allocation: </span>
              I/we understand that any payment made will first cover the Booking Fees. Any remaining balance will be applied toward the entitlement fees.
              </p>
            
        </div>
        <div class="terms__single terms">
        <p>
              <span style="font-size: 20px; font-weight: 600;">3.	Cancellation Policy: </span>
              In the event of a cancellation request, 22YARDS reserves the right to deduct cancellation charges as specified in the Terms & Conditions. The booking amount will be treated as &quot;Earnest Money,&quot; and 10% of this amount will be forfeited if cancellation occurs after payment. However, there is a seven-day grace period from the Onboarding date during which cancellations can be made without forfeiture.
              </p>
        </div>
        <div class="terms__single terms">
        <p>
              <span style="font-size: 20px; font-weight: 600;">4.	Completion of Ownership</span>
              Fractional Ownership of 22YARDS will be conferred only upon full payment of the total amount and completion of the two installments as per the chosen Payment Plan. Full ownership benefits will be granted only after these conditions are met.
              </p>
        </div><div class="terms__single terms">
        <p>
              <span style="font-size: 20px; font-weight: 600;">5.	Exit Policy</span>
              As a Fractional Owner, I/we have the right to exit this agreement at any time, provided a 60-day notice period is given.
              </p>
        </div><div class="terms__single terms">
        <p>
              <span style="font-size: 20px; font-weight: 600;">6.	Name Transfer or Gift</span>
              For any name transfers or gifts, please note that it may take up to 7 working days for the transfer to be reflected.
              </p>
        </div><div class="terms__single terms">
        <p>
              <span style="font-size: 20px; font-weight: 600;">7.	Formation of SPV</span>
              A Special Purpose Vehicle (SPV) will be established once all units of a particular property have been sold.
              
              </p>
        </div>
        <div class="terms__single terms">
        <p>
              <span style="font-size: 20px; font-weight: 600;">8.	Bank Rate of Interest (BROI): </span>
              The BROI will be applicable only after the full payment has been completed by the Fractional Owner or Shareholder. All relevant documentation will be provided upon full payment.
              </p>
        </div>
        <div class="terms__single terms">
        <p>
              <span style="font-size: 20px; font-weight: 600;">9.	Property Maintenance: </span>
              22YARDS will be responsible for property maintenance. To cover maintenance costs, 30% of the generated rental income will be deducted, with the remaining 70% distributed among property shareholders. This maintenance cost will not affect the guaranteed 8% return on the investment amount.
              </p>
        </div>
        <hr/>

        <div class="terms__single">
          <h3 class="neutral-top">
          Cancellation & Refund Policy
          </h3>
          <div class="terms__single terms">
        <p>
              <span style="font-size: 20px; font-weight: 600;">•	Payment Information:  </span>
              Users must provide accurate payment information and use only legally owned cards or accounts. 22YARDS does not store users&apos; payment details.</p>
        </div>
        <div class="terms__single terms">
        <p>
              <span style="font-size: 20px; font-weight: 600;">•	Transaction Failures:  </span>
            Failed transactions due to lack of authorization or interruptions will not be processed, and erroneous charges will be refunded. Users are responsible for fraudulent card use and must provide evidence to dispute such charges; 22YARDS is not liable for such incidents</p>
        </div>
        <div class="terms__single terms">
        <p>
              <span style="font-size: 20px; font-weight: 600;">•	Fraudulent Use: </span>
              Users must provide evidence in cases of fraudulent card use. 22YARDS is not responsible for issues arising from fraudulent transactions.</p>
        </div>
        <div class="terms__single terms">
        <p>
              <span style="font-size: 20px; font-weight: 600;">•	Bank Authorization Issues: </span>
              In the event of transaction failure due to bank authorization or other interruptions, the transaction will be considered unsuccessful,and no orders will proceed. Erroneous charges will be refunded. Users are responsible for resolving any issues related to fraudulent card use.</p>
        </div>

          </div>
        </div>
        

    </div>
  </section>

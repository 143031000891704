<div class="dashboard section__space__bottom">
    <div class="container">
        <div class="dashboard__area">
            <div class="row">
                <div class="col-xxl-3">
                    <app-navigation></app-navigation>
                </div>
                <div class="col-xxl-9">
                    <div class="main__content">
                        <div class="collapse__sidebar">
                            <h4>Add User</h4>
                            <a href="javascript:void(0)" class="collapse__sidebar__btn">
                                <i class="fa-solid fa-bars-staggered"></i>
                            </a>
                        </div>
                        <div class="main__content-dashboard">
                            <div class="breadcrumb-dashboard">
                                <h5><span *ngIf="!propertyId">Add</span>
                                    <span *ngIf="propertyId">Update</span> Property</h5>
                                <div>
                                    <a href="all-properties">Properties</a>
                                    <i class="fa-solid fa-arrow-right-long"></i>
                                    <a href="javascript:void(0)">Add Property</a>
                                </div>
                            </div>
                            <div class="row">
                            <div class="investment-table">
                                 <section >
                                <div class="container">
                                  <div class="registration__area">
                                    <form [formGroup]="propertyForm" (ngSubmit)="onSubmit()">
                                        <h5>Property Details:</h5>
                                        <div class="regi__type">
                                            <label for="type">Property Type? <span class="requried">*</span></label>
                                            <select class="nice-select type__select" formControlName="type">
                                            <option value="RETAIL">RETAIL</option>
                                            <option value="COMMERCIAL">COMMERCIAL</option>
                                            <option value="CO-LIVING">CO-LIVING</option>
                                            <option value="BARELAND">BARELAND</option>
                                            </select>
                                        </div>
                                        <div class="input input--secondary">
                                              <label for="name">Property Name<span class="requried">*</span></label>
                                              <input id="name" type="text" placeholder="Search for a location" formControlName="name"/>
                                          </div>
                                        <div class="input input--secondary">
                                        <!-- Search bar for location input -->
                                            <label for="location">Property Location<span class="requried">*</span></label>
                                            <input id="location" type="text" placeholder="Search for a location" formControlName="location"/>
                                            <!-- Button to use current location -->
                                        </div>
                                        <div class="input input--secondary">
                                            <div #mapContainer id="map"></div>
                                        </div>
                                        <div class="input input--secondary">
                                            <label for="decription">Property Description<span class="requried">*</span></label>
                                            <textarea formControlName="description" placeholder="Enter Property Description" rows="3"></textarea>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                              <div class="input input--secondary">
                                                <label for="land_area_sq_yards">Land Area in Sq.Yd<span class="requried">*</span></label>
                                                <input type="number" formControlName="land_area_sq_yards" placeholder="Enter Land Area" required class="no-spinner" onwheel="this.blur()" />
                                              </div>
                                            </div>
                                            <div class="col-sm-6">
                                              <div class="input input--secondary">
                                                <label for="built_up_area_sq_yards">Built up Area in Sq.Yd<span></span></label>
                                                <input type="number" formControlName="built_up_area_sq_yards" placeholder="Enter Built up Area" class="no-spinner" (change)="validateBuiltupArea()" onwheel="this.blur()" />
                                              </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                              <div class="input input--secondary">
                                                <label for="value">Property Value<span class="requried">*</span></label>
                                                <input type="number" formControlName="value" placeholder="Enter Property Value" required class="no-spinner" (input)="updateEachShareValue()" onwheel="this.blur()" />
                                              </div>
                                            </div>
                                            <div class="col-sm-6">
                                              <div class="input input--secondary">
                                                <label for="tenure">Tenure in Months<span class="requried">*</span></label>
                                                <input type="number" formControlName="tenure" placeholder="Enter tenure period" required class="no-spinner" onwheel="this.blur()" (input)="updateLeaseterm()" />
                                              </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                              <div class="input input--secondary">
                                                <label for="no_of_shares">No. of Shares<span class="requried">*</span></label>
                                                <input type="number" formControlName="no_of_shares" placeholder="Enter No. of shares" required class="no-spinner" (input)="updateEachShareValue()" onwheel="this.blur()" />
                                              </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="input input--secondary">
                                                  <label for="blocked_shares_by_admin">Block Shares<span class="requried">*</span></label>
                                                  <input type="number" formControlName="blocked_shares_by_admin" placeholder="Enter no of shares to be blocked" required class="no-spinner" (change)="updateReleasedShares()" onwheel="this.blur()" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="input input--secondary">
                                                  <label for="value">Value of Each Share<span class="requried">*</span></label>
                                                  <input type="number" formControlName="value_of_each_share"
                                                  placeholder="Value of each share" required class="no-spinner" onwheel="this.blur()" />
                                                </div>
                                              </div>
                                            <div class="col-sm-6">
                                                <div class="input input--secondary">
                                                  <label for="released_shares">Released Shares<span class="requried">*</span></label>
                                                  <input type="number" formControlName="released_shares"
                                                  placeholder="Shares released" required class="no-spinner" onwheel="this.blur()" />
                                                </div>
                                              </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="input input--secondary">
                                                  <label for="ROI_min">Min Annual Return%<span class="requried">*</span></label>
                                                  <input type="number" formControlName="ROI_min"
                                                  placeholder="Enter Min Annual Return %" required class="no-spinner" onwheel="this.blur()"/>
                                                </div>
                                              </div>
                                            <div class="col-sm-6">
                                                <div class="input input--secondary">
                                                  <label for="ROI_max">Max Annual Return%<span class="requried">*</span></label>
                                                  <input type="number" formControlName="ROI_max"
                                                  placeholder="Enter Max Annual Return %" required class="no-spinner" onwheel="this.blur()" />
                                                </div>
                                              </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="input input--secondary">
                                                  <label for="IRR_min">Min IRR Return%<span class="requried">*</span></label>
                                                  <input type="number" formControlName="IRR_min"
                                                  placeholder="Enter Min IRR Return %" required class="no-spinner" onwheel="this.blur()" />
                                                </div>
                                              </div>
                                            <div class="col-sm-6">
                                                <div class="input input--secondary">
                                                  <label for="IRR_max">Max IRR Return%<span class="requried">*</span></label>
                                                  <input type="number" formControlName="IRR_max"
                                                  placeholder="Enter Max IRR Return %" required class="no-spinner" onwheel="this.blur()" />
                                                </div>
                                              </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-sm-6">
                                              <div class="input input--secondary">
                                                <label for="invester_referal_commision">Fee per Investor Referral %</label>
                                                <input type="number" formControlName="invester_referal_commision"
                                                placeholder="Enter Fee per Referral %" class="no-spinner" onwheel="this.blur()" />
                                              </div>
                                            </div>
                                          <div class="col-sm-6">
                                              <div class="input input--secondary">
                                                <label for="property_referal_commision">Fee per Property Referral %</label>
                                                <input type="number" formControlName="property_referal_commision"
                                                placeholder="Enter Fee per Referral %" class="no-spinner" onwheel="this.blur()" />
                                              </div>
                                            </div>
                                        </div>

                                        <div class="input input--secondary">
                                            <label for="tenant_details">Tenant Details<span class="requried">*</span></label>
                                            <textarea formControlName="tenant_details" placeholder="Enter tenant Details" rows="3"></textarea>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                              <div class="input input--secondary">
                                                <label for="lease_term">Lease Tenure in months<span class="requried">*</span></label>
                                                <input type="number" formControlName="lease_term" placeholder="Enter lease Tenure" required class="no-spinner" onwheel="this.blur()" (change)="updateLeaseEndAndLockinEndDate()"/>
                                              </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="input input--secondary">
                                                  <label for="lock_in_period">Lock-in period in months <span class="requried">*</span></label>
                                                  <input type="number" formControlName="lock_in_period"
                                                  placeholder="Enter Lock-in period" required class="no-spinner" onwheel="this.blur()" (input)="updateLeaseEndAndLockinEndDate()"/>
                                                </div>
                                              </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">
                                              <div class="input input--secondary">
                                                <label for="lease_start_date">Lease Start Date<span class="requried">*</span></label>
                                                <input type="date" formControlName="lease_start_date" placeholder="Select Lease Start Date" required class="no-spinner" (change)="updateLeaseEndAndLockinEndDate()" />
                                              </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="input input--secondary">
                                                  <label for="lock_in_end_date">Lock-in End Date<span class="requried">*</span></label>
                                                  <input type="date" formControlName="lock_in_end_date"
                                                  placeholder="Lock-in End Date" required class="no-spinner" onwheel="this.blur()" />
                                                </div>
                                              </div>
                                            <div class="col-sm-4">
                                                <div class="input input--secondary">
                                                  <label for="lease_end_date">Lease End Date<span class="requried">*</span></label>
                                                  <input type="date" formControlName="lease_end_date"
                                                  placeholder="Select Lease End Date" required class="no-spinner" onwheel="this.blur()" />
                                                </div>
                                              </div>
                                        </div>
                                        <h5><br/>Property Account Details:</h5>
                                        <div class="input input--secondary">
                                            <label for="account_name"><br/>Name of the Account Holder<span class="requried">*</span></label>
                                            <input type="text" formControlName="account_name" placeholder="Enter Account Holder Name" required class="no-spinner" onwheel="this.blur()" />
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="input input--secondary">
                                                    <label for="account_number">Account Number<span class="requried">*</span></label>
                                                    <input type="number" formControlName="account_number" placeholder="Enter account number" required class="no-spinner" onwheel="this.blur()" />
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="input input--secondary">
                                                    <label for="account_number_conf">Confirm Account Number<span class="requried">*</span></label>
                                                    <input type="number" formControlName="account_number_conf" placeholder="Re-Enter account number" required class="no-spinner" onwheel="this.blur()" (change)="validateAccountNumber()" />
                                                </div>
                                              </div>
                                        </div>
                                        
                                        <div class="input input--secondary">
                                            <label for="ifsc_code">IFSC Code<span class="requried">*</span></label>
                                            <input type="text" formControlName="ifsc_code" placeholder="Enter tenure period" required class="no-spinner" onwheel="this.blur()" />
                                        </div>

                                        <h5><br/>Property documents:</h5>

                                        <div class="input input--secondary">
                                          <label for="propertyImages"><br/>Property Images<span class="requried">*</span></label>
                                          
                                          <!-- Display existing files for Property Images -->
                                          <div *ngIf="existingPropertyImages.length > 0" class="existing-files">
                                            <label>Existing Property Images:</label>
                                            <ul>
                                              <li *ngFor="let file of existingPropertyImages; let i = index">
                                                  <a [href]="file.url" target="_blank"><span>{{ file.name || ('Property Images ' + (i + 1)) }}</span></a>
                                                  <div class="icons">
                                                    <fa-icon [icon]="faEye" (click)="viewImage(file.url)"></fa-icon>
                                                    <fa-icon [icon]="faTrash" (click)="removeFile('propertyImages', i)" class="red-icon"></fa-icon>
                                                  </div>
                                              </li>
                                            </ul>
                                          </div>
                                        
                                          <!-- File input for new uploads -->
                                          <input 
                                            type="file" 
                                            id="propertyImages" 
                                            multiple 
                                            required 
                                            accept=".jpg,.jpeg,.png" 
                                            (input)="handleFileInput($event, 'propertyImages')" 
                                          />
                                          <p-dialog header="Image View" [(visible)]="displayDialog" [modal]="true" [style]="{height: '30vw'}">
                                            <img [src]="selectedImageUrl" alt="Image" />
                                          </p-dialog>
                                        </div>
                                        <div class="input input--secondary">
                                          <label for="landDocuments">Land Documents<span class="requried">*</span></label>
                                          
                                          <!-- Display existing files for Land Documents -->
                                          <div *ngIf="existingLandDocuments.length > 0" class="existing-files">
                                            <label>Existing Land Documents:</label>
                                            <ul>
                                              <li *ngFor="let file of existingLandDocuments; let i = index">
                                                <a [href]="file.url" target="_blank"><span>{{ file.name || ('Land Documents ' + (i + 1)) }}</span></a>
                                                <div class="icons">
                                                  <fa-icon [icon]="faEye" (click)="viewDocument(file.url)"></fa-icon>
                                                  <fa-icon [icon]="faTrash" (click)="removeFile('landDocuments', i)" class="red-icon"></fa-icon>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                    
                                          <!-- File input for new uploads -->
                                          <input 
                                            type="file" 
                                            id="landDocuments" 
                                            multiple 
                                            required 
                                            accept=".pdf,.doc,.docx" 
                                            (input)="handleFileInput($event, 'landDocuments')" 
                                          />

                                          <p-dialog header="Image View" [(visible)]="displayDialog" [modal]="true" [style]="{height: '30vw'}">
                                            <img [src]="selectedImageUrl" alt="Image" />
                                          </p-dialog>

                                        </div>
                                        <div class="input input--secondary">
                                          <label for="legalOpinion">Legal Opinion<span class="requried">*</span></label>
                                          
                                          <!-- Display existing files for Legal Opinion -->
                                          <div *ngIf="existingLegalOpinions.length > 0" class="existing-files">
                                            <label>Existing Legal Opinions:</label>
                                            <ul>
                                              <li *ngFor="let file of existingLegalOpinions; let i = index">
                                                <a [href]="file.url" target="_blank"><span>{{ file.name || ('Legal opinion ' + (i + 1)) }}</span></a>
                                                <div class="icons">
                                                  <fa-icon [icon]="faEye" (click)="viewImage(file.url)"></fa-icon>
                                                  <fa-icon [icon]="faTrash" (click)="removeFile('legalOpinion', i)" class="red-icon"></fa-icon>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                    
                                          <!-- File input for new uploads -->
                                          <input 
                                            type="file" 
                                            id="legalOpinion"  
                                            multiple 
                                            required 
                                            accept=".pdf,.doc,.docx" 
                                            (input)="handleFileInput($event, 'legalOpinion')" 
                                          />

                                          <p-dialog header="Image View" [(visible)]="displayDialog" [modal]="true" [style]="{height: '30vw'}">
                                            <img [src]="selectedImageUrl" alt="Image" />
                                          </p-dialog>

                                        </div>
                                        <div class="input input--secondary">
                                          <label for="floorPlans">Floor Plans<span class="requried">*</span></label>
                                          
                                          <!-- Display existing files for Floor Plans -->
                                          <div *ngIf="existingFloorPlans.length > 0" class="existing-files">
                                            <label>Existing Floor Plans:</label>
                                            <ul>
                                              <li *ngFor="let file of existingFloorPlans; let i = index">
                                                <a [href]="file.url" target="_blank"><span>{{ file.name || ('Floor Plans ' + (i + 1)) }}</span></a>
                                                <div class="icons">
                                                  <fa-icon [icon]="faEye" (click)="viewImage(file.url)"></fa-icon>
                                                  <fa-icon [icon]="faTrash" (click)="removeFile('floorPlans', i)" class="red-icon"></fa-icon>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                    
                                          <!-- File input for new uploads -->
                                          <input 
                                            type="file" 
                                            id="floorPlans" 
                                            multiple 
                                            required 
                                            accept=".jpg,.jpeg,.png" 
                                            (input)="handleFileInput($event, 'floorPlans')" 
                                          />

                                          <p-dialog header="Image View" [(visible)]="displayDialog" [modal]="true" [style]="{height: '30vw'}">
                                            <img [src]="selectedImageUrl" alt="Image" />
                                          </p-dialog>


                                        </div>
                                        <div class="input input--secondary">
                                          <label for="renders">3D Renders<span class="requried">*</span></label>
                                          
                                          <!-- Display existing files for Renders -->
                                          <div *ngIf="existingRenders.length > 0" class="existing-files">
                                            <label>Existing 3D Renders:</label>
                                            <ul>
                                              <li *ngFor="let file of existingRenders; let i = index">
                                                <a [href]="file.url" target="_blank"><span>{{ file.name || ('3D Render ' + (i + 1)) }}</span></a>
                                                <div class="icons">
                                                  <fa-icon [icon]="faEye" (click)="viewImage(file.url)"></fa-icon>
                                                  <fa-icon [icon]="faTrash" (click)="removeFile('renders', i)" class="red-icon"></fa-icon> 
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                    
                                          <!-- File input for new uploads -->
                                          <input 
                                            type="file" 
                                            id="renders" 
                                            multiple 
                                            required 
                                            accept=".jpg,.jpeg,.png" 
                                            (input)="handleFileInput($event, 'renders')" 
                                          />

                                          <p-dialog header="Image View" [(visible)]="displayDialog" [modal]="true" [style]="{height: '30vw'}">
                                            <img [src]="selectedImageUrl" alt="Image" />
                                          </p-dialog>


                                        </div>
                                        <div class="input__button">
                                          <div class="row">
                                              <div class="col-sm-6">
                                                  <button class="button cancel-button" (click)="cancel()">
                                                      Cancel
                                                  </button>
                                              </div>
                                              <div class="col-sm-6">
                                                  <button type="submit" class="button button--effect" [disabled]="propertyForm.invalid">
                                                      Submit
                                                    </button>
                                              </div>  
                                          </div>
                                        </div>
                                    </form>
                                  </div>
                                </div>
                              </section> 
                            </div>
                        </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
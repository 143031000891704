import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../shared/services/api.service';
import { AuthService } from '../shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent {
  role:any;
  trasactions :any ;
  transactionForm!: FormGroup;
  properties:any; 
  investors :any;
  @ViewChild('trasactionAddModal') trasactionAddModal!: TemplateRef<any>;

  constructor(
    private router:Router,
    private modalService: NgbModal,
    private apiService:ApiService, 
    private authService: AuthService,
    private fb: FormBuilder,
    private toastr:ToastrService,

  ){
      if(authService.isAuthenticated() && (authService.getData().role !== 'admin' && authService.getData().role !== 'support' && authService.getData().role !== 'investor')) {
        this.authService.redirectedToUserPages()
      }
      this.role =authService.getData().role
      this.getTrasacrions()
  }

  ngOnInit(): void {
    this.transactionForm = this.fb.group({
      property: ['', Validators.required],
      investor: ['', Validators.required],
      amount: ['', [Validators.required, Validators.min(0.01)]],
      transactionType: ['income', Validators.required],
      method: ['', Validators.required],
      reference_id :  ['', Validators.required],
      status : ['deposit', Validators.required]
    });
    if(this.authService.getData().role != 'investor' ) {
      this.loadInvestors();
    }
  }


  loadInvestors(): void {
    this.apiService.getInvestors().subscribe((data: any) => {
      this.investors = data;
    });
  }

  loadInvestorProperties(investorId: string): void {
    this.apiService.getInvestorProperties(investorId).subscribe((data: any) => {
      this.properties = data['data'];
      this.transactionForm.patchValue({
        property: this.properties[0].property_id
      });
    }, error => {
      console.error('Error loading properties', error);
    });
  }
  

  onInvestorChange(event: Event): void {
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.loadInvestorProperties(selectedValue)
    // Additional logic for handling investor change
  }
  

  addTrasaction() {
    const trasactionAddModal = this.modalService.open(this.trasactionAddModal, { centered: true });

  }

  onSubmit(): void {
    if (this.transactionForm.valid) {
      this.apiService.addTransaction(this.transactionForm.value).subscribe(response => {
        if(!response['error']) {
          this.toastr.success('Transaction added successfully');
          // Optionally close the modal
          this.modalService.dismissAll()
          this.getTrasacrions()
        } else {
          this.toastr.error('Failed to add transaction');
        }
      }, (error: any) => {
        this.toastr.error('Failed to add transaction');
      });
    }
  }

  getTrasacrions() {
    this.apiService
    .getTrasactions()
    .subscribe(resp => {
      if(!resp['error']) {
        this.trasactions = resp.transactions;
      }
    });
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-cancel-refund',
  templateUrl: './cancel-refund.component.html',
  styleUrls: ['./cancel-refund.component.scss']
})
export class CancelRefundComponent {

}

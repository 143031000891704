import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'primeng/carousel';
import { GalleriaModule } from 'primeng/galleria';
import { PropertyRoutingModule } from './property-routing.module';
import { PropertiesComponent } from './properties/properties.component';
import { PropertyDetailsComponent } from './property-details/property-details.component';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap'

@NgModule({
  declarations: [
    PropertiesComponent,
    PropertyDetailsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    PropertyRoutingModule,
    CarouselModule,
    GalleriaModule,
    NgbCarouselModule
  ]
})
export class PropertyModule { }

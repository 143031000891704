<div class="wrapper bg__img" *ngIf="isShowresetPassword" [ngStyle]="{'background-image': 'url(../../../assets/images/registration-bg.png)'}">
    <!-- ==== Reset Password section start ==== -->
    <section class="registration clear__top">
        <div class="container">
            <div class="registration__area">
                <h4 class="neutral-top">Reset Password</h4>
                <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()" class="form__login">
                    <div class="input input--secondary password-field">
                        <label for="newPassword">New Password<span class="required">*</span></label>
                        <input [type]="passwordVisible ? 'text' : 'password'" formControlName="newPassword"
                                (input)="checkPassword('newPassword')"
                               id="newPassword" placeholder="New Password" required />
                        <button type="button" (click)="togglePasswordVisibility()">
                            <i [ngClass]="passwordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                        </button>
                        <div class="password-tooltip" *ngIf="showTooltip">
                            <ul class="error-list">
                              <li [ngClass]="{'strikethrough': isMinLengthSatisfied}">
                                Minimum 8 characters required.
                              </li>
                              <li [ngClass]="{'strikethrough':hasUppercase}">
                                At least one uppercase letter required.
                              </li>
                              <li [ngClass]="{'strikethrough':hasLowercase}">
                                At least one lowercase letter required.
                              </li>
                              <li [ngClass]="{'strikethrough':hasNumber}">
                                At least one number required.
                              </li>
                              <li [ngClass]="{'strikethrough': hasSpecialChar}">At least one special character</li>
                            </ul>
                          </div>
                    </div>
                    <div class="input input--secondary password-field">
                        <label for="confirmPassword">Confirm Password<span class="required">*</span></label>
                        <input [type]="confirmPasswordVisible ? 'text' : 'password'"
                                (input)="checkPassword('confirmPassword')"
                             formControlName="confirmPassword" id="confirmPassword" placeholder="Confirm Password" required />
                        <button type="button" (click)="toggleConfirmPasswordVisibility()">
                            <i [ngClass]="confirmPasswordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                        </button>
                        <div class="password-tooltip" *ngIf="showConfirmPasswordTooltip">
                            <ul class="error-list">
                              <li [ngClass]="{'strikethrough': isMinLengthSatisfied}">
                                Minimum 8 characters required.
                              </li>
                              <li [ngClass]="{'strikethrough':hasUppercase}">
                                At least one uppercase letter required.
                              </li>
                              <li [ngClass]="{'strikethrough':hasLowercase}">
                                At least one lowercase letter required.
                              </li>
                              <li [ngClass]="{'strikethrough':hasNumber}">
                                At least one number required.
                              </li>
                              <li [ngClass]="{'strikethrough': hasSpecialChar}">At least one special character</li>
                            </ul>
                          </div>
                        <div class="passwords-not-matched-error" *ngIf="resetPasswordForm!.errors?.['passwordMismatch'] && resetPasswordForm.get('confirmPassword')?.touched">
                            <small class="text-danger">Passwords do not match.</small>
                          </div>       
                    </div>
                    <div class="input__button">
                        <button type="submit" class="button button--effect" [disabled]="resetPasswordForm.invalid">Reset Password</button>
                    </div>
                </form>
            </div>
        </div>
    </section>
</div>

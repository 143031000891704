<div class="dashboard section__space__bottom">
    <div class="container">
        <div class="dashboard__area">
            <div class="row">
                <div class="col-xxl-3">
                    <app-navigation></app-navigation>
                </div>
                <div class="col-xxl-9">
                    <div class="main__content">
                        <div class="collapse__sidebar">
                            <h4>Users</h4>
                            <a href="javascript:void(0)" class="collapse__sidebar__btn">
                                <i class="fa-solid fa-bars-staggered"></i>
                            </a>
                        </div>
                        <div class="main__content-dashboard">
                            <div class="breadcrumb-dashboard">
                                <h5>Users<span *ngIf="users">({{users.length}})</span></h5>
                                <div>
                                   <button class="button button--effect pointer" (click)="addUser()">+ Add User</button>
                                </div>
                            </div>
                        </div>
                        <div class="investment-table investment-table-two">
                            <div class="table-wrapper">
                                <table>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Role</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                    <tr *ngIf="users.length === 0">
                                        <td colspan="4" class="text-center">No Users found</td>
                                      </tr>
                                      <tr *ngFor="let user of users">
                                        <td>
                                            {{user.firstname}} {{user.lastname}}
                                        </td>
                                        <td>
                                            <div class="email-container" title="{{user.email}}">
                                                {{user.email}}
                                            </div>
                                        </td>
                                        <td>{{ getRoleDisplayName(user.role) }}</td>
                                        <td>{{user.status}}</td>
                                        <td *ngIf="user.role == 'admin' || user.role == 'support'">
                                            <img class="actions_img pointer" src="assets/images/icons/edit.png" alt="Edit" (click)="updateUser(user.id)" />
                                            <img class="actions_img pointer" src="assets/images/icons/delete.png" alt="Delete" (click)="deleteUser(user.id)" />
                                        </td>
                                        <td *ngIf="user.role != 'admin' && user.role != 'support'">
                                    
                                        </td>
                                    </tr>
                                    
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
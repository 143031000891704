 <!-- ==== footer section start ==== -->
 <footer class="footer pos__rel over__hi">
    <div class="container">
        <div class="footer__area section__space">
            <div class="row" style="display: flex;">
                <div class="col-md-12 col-lg-4 col-xl-4">
                    <div class="footer__intro">
                        <a href="/">
                            <img src="assets/images/logo-light.png" alt="22Yards" class="logo" />
                        </a>
                        <p>22 Yards is a platform offering anyone the ability to invest and potentially earn money from property at the click of a button</p>
                        <div class="social">
                            <a href="https://www.facebook.com/people/22Yards/61563374311398/" target="_blank" title="Follow us on Facebook">
                                <i class="fa-brands fa-facebook-f"></i>
                            </a>
                            <a href="https://www.youtube.com/@22_Yards_Proptech" target="_blank" title="Subscribe to our YouTube channel">
                                <i class="fa-brands fa-youtube"></i>
                            </a>
                            <a href="https://www.instagram.com/22_yards_proptech/" target="_blank" title="Follow us on Instagram">
                                <i class="fa-brands fa-instagram"></i>
                            </a>
                            <a href="https://www.linkedin.com/company/22yardsprop/" target="_blank" title="Connect with us on LinkedIn">
                                <i class="fa-brands fa-linkedin-in"></i>
                            </a>
                        </div>
                    </div>
                </div>


                <div class="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                    <div class="footer__links footer__links--alt" >
                        <h5>Company</h5>
                        <ul>
                            <li>
                                <a href="about-us">About Us</a>
                            </li>
                            <!-- <li>
                                <a href="career.html">Careers</a>
                            </li> -->
                             <li>
                                <a href="blogs">Blog</a>
                            </li>
                            <li>
                                <a href="contact-us">Contact Us</a>
                            </li>
                         </ul>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                    <div class="footer__links footer__links--alt">
                        <h5>Invest</h5>
                        <ul>
                            <li>
                                <a href="/properties">Browse Properties</a>
                            </li> 
                            <li>
                                <a href="/properties">Property Alerts</a>
                            </li> 
                            <li>
                                <a href="how-it-works">How it works</a>
                            </li>
                            <li class="neutral-bottom">
                                <a href="frequantly-ask-questions">FAQs</a>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <div class="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                    <div class="footer__links">
                        <h5>Legal</h5>
                        <ul>
                            <li>
                                <a href="privacy-policy">Privacy Policy</a>
                            </li>
                            <li>
                                <a href="terms-and-conditions">Terms & Conditions</a>
                            </li>
                            <li>
                                <a href="cookie-policy">Cookie Policy</a>
                            </li> 
                             <!-- <li class="neutral-bottom">
                                <a href="key-risks">Key Risks</a>
                            </li> 
                            <li>
                                <a href="shipping-and-delivery">Shipping & Delivery</a>
                            </li>
                            <li>
                                <a href="cancel-and-refund">Cancel & Refund</a>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer__credit" >
            <div class="row d-flex align-items-center">
                <div class="col-sm-9 order-1 order-sm-0">
                    <div class="footer__copyright">
                        <p>Copyright © <a
                                href="/">Twenty Two Yards PropTech Private Limited.</a></p>
                    </div>
                </div>
                <!-- <div class="col-sm-3">
                    <div class="footer__language">
                        <select class="language-select">
                            <option value="english">En</option>
                            <option value="australia">Aus</option>
                            <option value="brazil">Bra</option>
                            <option value="argentina">Arg</option>
                        </select>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <div class="footer__animation">
        <img src="assets/images/footer/footer__left__circle.png" alt="Circle" class="left__circle" />
        <img src="assets/images/footer/footer__right__circle.png" alt="Circle" class="right__circle" />
        <img src="assets/images/footer/footer__home___illustration.png" alt="Home" class="home__illustration" />
    </div>
</footer>
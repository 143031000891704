import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  role:any;
  lastLogin :any;
  constructor(
    private router:Router,
    private auth: AuthService
  ){
    this.role = this.auth.getData().role;
    this.lastLogin = localStorage.getItem('last_login')
  }


  logout() {
    localStorage.clear();
    this.router.navigate(['/']); 
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent {


 comunityImages: string[] = Array.from({ length: 19 }, (_, i) => `../../../assets/images/community/${i + 1}.png`);
}

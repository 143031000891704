
<app-banner 
  [title]="'Cancel & Refund Polocies'"
  [link]="'/cancel-and-refund'"
  [item]="'Cancel & Refund'">
</app-banner>

<section class="section__space">
    <div class="container">
            <div class="terms-area">
        <div class="terms__single">
                <h3 class="neutral-top">
                Cancellation & Refund Policy
                </h3>
                <div class="terms__single terms">
              <p>
                    <span style= "font-size: 20px;font-weight: 600;">•	Payment Information:  </span>
                    Users must provide accurate payment information and use only legally owned cards or accounts. 22YARDS does not store users&apos; payment details.</p>
              </div>
              <div class="terms__single terms">
              <p>
                    <span style= "font-size: 20px;font-weight: 600;">•	Transaction Failures:  </span>
                  Failed transactions due to lack of authorization or interruptions will not be processed, and erroneous charges will be refunded. Users are responsible for fraudulent card use and must provide evidence to dispute such charges; 22YARDS is not liable for such incidents</p>
              </div>
              <div class="terms__single terms">
              <p>
                    <span style= "font-size: 20px;font-weight: 600;">•	Fraudulent Use: </span>
                    Users must provide evidence in cases of fraudulent card use. 22YARDS is not responsible for issues arising from fraudulent transactions.</p>
              </div>
              <div class="terms__single terms">
              <p>
                    <span style= "font-size: 20px;font-weight: 600;">•	Bank Authorization Issues: </span>
                    In the event of transaction failure due to bank authorization or other interruptions, the transaction will be considered unsuccessful,and no orders will proceed. Erroneous charges will be refunded. Users are responsible for resolving any issues related to fraudulent card use.</p>
              </div>
    
                </div>
                </div>
        </div>
      </section>
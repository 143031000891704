<header class="dashboard-header">
  <div class="container">
        <div class="dashboard-header__area">
            <a href="index.html" class="header-logo">
                <img src="assets/images/logo.png" alt="Logo" class="logo" />
            </a>
            <div class="dashboard-header__area-content">
                <a class="button--effect" href="/dashboard" *ngIf="role=='investor'" >
                    <h6>Home</h6>
                </a>
                <a class="button button--effect" href="/properties" *ngIf="role=='investor'" >
                    <img src="assets/images/direction.png" alt="Investment" /> New Investments
                </a>
                <div class="notification-area">
                    <a href="javascript:void(0)" class="icon__wrapper notification__icon">
                        <i class="fa-solid fa-bell"></i>
                        <span>03</span>
                    </a>
                    <div class="notification__wrapper">
                        <div class="notification__head">
                            <p class="text-center">3 New Notification</p>
                        </div>
                        <div class="notification__single-wrapper">
                            <div class="notification__single">
                                <a href="javascript:void(0)">
                                    <h6>Welcome to spoment</h6>
                                    <p>We are happy to welcome you to our community spoment.
                                    </p>
                                </a>
                                <p class="time">2 hours ago</p>
                            </div>
                            <div class="notification__single">
                                <a href="javascript:void(0)">
                                    <h6>Welcome to spoment</h6>
                                    <p>We are happy to welcome you to our community spoment.
                                    </p>
                                </a>
                                <p class="time">2 hours ago</p>
                            </div>
                        </div>

                        <div class="mark__read">
                            <a href="#">Mark all as read</a>
                        </div>
                    </div>
                </div>
                <a href="profile" class="profile">
                    <img *ngIf="role=='investor' && !is_kyc_completed" src="assets/images/unverified_user.png" alt="Profile" />
                    <img *ngIf="role=='investor' && is_kyc_completed" src="assets/images/verified_user.png" alt="Profile" />
                    <img *ngIf="role !='investor'" src="assets/images/icons/profile.png" alt="Profile" />
                </a>
            </div>
        </div>
    </div>
</header>
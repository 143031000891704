import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.css']
})
export class BlogDetailComponent {

  cmsUrl: string;
  blogSlug: any;
  blogData: any;
  blogs:any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private meta: Meta,
  ) {
    this.cmsUrl = environment.cms_url;

    // Subscribe to route param changes to update `articleId` and fetch data
    this.activatedRoute.paramMap.subscribe(params => {
      this.blogSlug = params.get('slug');
      if (this.blogSlug) {
        this.getBlogData(this.blogSlug); // Fetch article data whenever `articleId` changes
        this.getBlogs()
      }
    });
  }

  getBlogData(slug: string) {
    this.apiService.getBlogData(slug).subscribe(response => {
      this.blogData = response.data;
      // this.updateMetaTags()
    });
  }

  getBlogs(params:any={}){
    this.apiService.getBlogsData(params).subscribe(response=>{
      this.blogs = response.data
      this.blogs.slice(0,3)
    })
  }

  updateMetaTags() {
    const metaTags = [
      { name: 'title', content: this.blogData.meta_content.title },
      { name: 'description', content: this.blogData.meta_content.content },
      { name: 'tags', content: this.blogData.meta_content.tags }
    ];
  
    metaTags.forEach(tag => {
      const existingTag = this.meta.getTag(`name="${tag.name}"`);
      if (existingTag) {
        this.meta.updateTag({ name: tag.name, content: tag.content });
      } else {
        this.meta.addTag(tag);
      }
    });
  }

}

import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-auth-header',
  templateUrl: './auth-header.component.html',
  styleUrls: ['./auth-header.component.scss']
})
export class AuthHeaderComponent {
  constructor(
    private router: Router, 
    private location: Location,
    private ngZone: NgZone
  ) {}

  private smoothScrollToSection(sectionId: string, offset: number = 100) {
    // Check if we're already on the correct route
    const currentRoute = this.location.path();
    const targetRoute = this.getRouteForSection(sectionId);

    if (currentRoute === targetRoute) {
      // If on the same route, simply scroll to the section
      this.scrollToElement(sectionId, offset);
    } else {
      // Navigate to the route and then scroll
      this.ngZone.run(() => {
        this.router.navigate([targetRoute]).then(() => {
          // Use a promise to ensure navigation is complete
          setTimeout(() => {
            this.scrollToElement(sectionId, offset);
          }, 100);
        });
      });
    }
  }

  private getRouteForSection(sectionId: string): string {
    switch(sectionId) {
      case 'vision': return '/our-vision';
      case 'significane': return '/our-significance';
      case 'team': return '/our-team';
      case 'story': return '/our-story';
      default: return '/';
    }
  }

  private scrollToElement(sectionId: string, offset: number) {
    const element = document.getElementById(sectionId);
    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }

  scrollToVision() {
    this.smoothScrollToSection('vision', 100);
  }

  scrollToSignificance() {
    this.smoothScrollToSection('significane', 0);
  }

  scrollToTeam() {
    this.smoothScrollToSection('team', 100);
  }

  scrollToOurStory() {
    this.smoothScrollToSection('story', 100);
  }
}
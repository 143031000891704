<div class="dashboard section__space__bottom">
    <div class="container">
        <div class="dashboard__area">
            <div class="row">
                <div class="col-xxl-3">
                    <app-navigation></app-navigation>
                </div>
                <div class="col-xxl-9">
                    <div class="main__content">
                        <div class="collapse__sidebar">
                            <h4>Dashboard</h4>
                            <a href="javascript:void(0)" class="collapse__sidebar__btn">
                                <i class="fa-solid fa-bars-staggered"></i>
                            </a>
                        </div>
                        <div class="main__content-dashboard">
                            <div class="breadcrumb-dashboard">
                                <h5>Investments</h5>
                                <div>
                                    <a href="dashboard">Dashboard</a>
                                    <i class="fa-solid fa-arrow-right-long"></i>
                                    <a href="javascript:void(0)">Investments</a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-8">
                                    <div class="investment-table">
                                        <div class="intro">
                                            <h5>Transactions</h5>
                                        </div>
                                        <div class="table-wrapper">
                                            <table>
                                                <tr>
                                                    <th>Project</th>
                                                    <th>Amount Invested</th>
                                                    <th>Date Invested</th>
                                                </tr>
                                                 <tr *ngIf="transactions && transactions.length === 0">
                                                    <td colspan="5" class="text-center">No trasactions found</td>
                                                </tr>
                                        <tr *ngFor="let trasaction of transactions">
                                                    <td>
                                                        {{trasaction.property_name}}
                                                    </td>
                                                    <td><i class="fa-solid fa-inr"></i>{{trasaction.amount}}</td>
                                                    <td>{{trasaction.transaction_date | date : 'short'}}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="investment-sidebar">
                                        <div class="statistics">
                                            <h5>Statistics</h5>
                                            <hr />
                                            <div class="group">
                                                <img src="assets/images/icons/wallet.png" alt="Wallet" />
                                                <div>
                                                    <h4><i
                                                        class="fa-solid fa-inr"></i>{{totalIncome}}</h4>
                                                    <p>Total Income</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="explore">
                                            <img src="assets/images/icons/explore.png" alt="Explore" />
                                            <div class="group">
                                                <h6>Explore More</h6>
                                                <p class="secondary">Investment opportunities</p>
                                                <a href="/properties"
                                                    class="button button--effect">Explore</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="dashboard-single__box investment-single-box">
                                <div class="intro">
                                    <h5 class="investo">Investment Chart</h5>
                                    <!-- <a href="#">Generate Report</a> -->
                                </div>
                                <div *ngIf="isLoading">Loading...</div>
                                <div *ngIf="!isLoading">
                                    <apx-chart
                                    [series]="investmentChartOptions.series!"
                                    [chart]="investmentChartOptions.chart!"
                                    [dataLabels]="investmentChartOptions.dataLabels!"
                                    [stroke]="investmentChartOptions.stroke!"
                                    [fill]="investmentChartOptions.fill!"
                                    [markers]="investmentChartOptions.markers!"
                                    [xaxis]="investmentChartOptions.xaxis!"
                                    [yaxis]="investmentChartOptions.yaxis!"
                                    [legend]="investmentChartOptions.legend!"
                                    [responsive]="investmentChartOptions.responsive!">
                              </apx-chart>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="dashboard section__space__bottom">
    <div class="container">
        <div class="dashboard__area">
            <div class="row">
                <div class="col-xxl-3">
                    <app-navigation></app-navigation>
                </div>
                <div class="col-xxl-9">
                    <div class="main__content">
                        <div class="collapse__sidebar">
                            <h4>Properties</h4>
                            <a href="javascript:void(0)" class="collapse__sidebar__btn">
                                <i class="fa-solid fa-bars-staggered"></i>
                            </a>
                        </div>
                        <div class="main__content-dashboard">
                            <div class="breadcrumb-dashboard">
                                <h5>Properties<span>({{ properties.length }})</span></h5>
                                <div>
                                   <button class="button button--effect pointer" (click)="addProperty()">+ Add Property</button>
                                </div>
                            </div>
                        </div>
                        <div class="investment-table investment-table-two">
                            <div class="table-wrapper">
                                <table>
                                    <tr>
                                        <th> Name </th>
                                        <th> Value </th>
                                        <th> Type  </th>
                                        <th> Location </th>
                                        <th> No.of shares </th>
                                        <th>Actions</th>
                                    </tr>
                                    <tr *ngIf="properties && properties.length === 0">
                                        <td colspan="6" class="text-center">No properties found</td>
                                    </tr>
                                    <tr *ngFor="let property of properties">
                                        <td>{{ property.name }}</td>
                                        <td>{{ property.value }}</td>
                                        <td>{{ property.type }}</td>
                                        <td>{{ property.location }}</td>
                                        <td>{{ property.no_of_shares }}</td>
                                        <td>
                                            <img class="actions_img pointer" src="assets/images/icons/edit.png" alt="Edit" (click)="updateProperty(property.property_id)" />
                                            <img class="actions_img pointer" src="assets/images/icons/delete.png" alt="Delete" (click)="deleteProperty(property.property_id)" />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
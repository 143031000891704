<!-- ==== banner section start ==== -->
<section class="banner blog-banner key-banner banner--secondary clear__top bg__img"
data-background="./assets/images/banner/banner-bg.png">
<div class="container">
    <div class="banner__area">
        <h1 class="neutral-top">Blog</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    Blog
                </li>
            </ol>
        </nav>
    </div>
</div>
<img src="assets/images/banner/blog__thumb.png" alt="Career" class="banner__thumb" />
</section>
<!-- ==== #banner section end ==== -->

<!-- ==== blog overview section start ==== -->
<section class="blog__overview section__space">
<div class="container">
    <div class="blog__overview__area">
        <div class="search__bar">
            <form action="#" method="post">
                <div>
                    <input type="text" name="post_search" id="postSearch" placeholder="Search for blogs"
                        required="required" />
                    <i class="fa-solid fa-magnifying-glass"></i>
                </div>
                <button type="submit" class="button">Search</button>
            </form>
        </div>

        <div class="latest__post__area">
            <div class="row">
                <div class="col-xl-12">
                    <div>
                        <div class="row altr">
                            <div class="col-md-6 col-xl-4" *ngFor="let blog of blogs">
                                <div class="featured__large__post">
                                    <a [routerLink]="['/blogs/',blog.slug]" class="thumbnail">
                                        <img [src]="cmsUrl+blog.article_media[0].url" alt="Poster" />
                                    </a>
                                    <div class="blog__content">
                                        <h5><a [routerLink]="['/blogs/',blog.slug]">{{ blog.title }}</a></h5>
                                        <div class="multiline-text--fallback" [innerHTML]="blog.content"></div>
                                        <a style="margin-top: 10px;" [routerLink]="['/blogs/',blog.slug]">Read full Blog <i
                                                class="fa-solid fa-arrow-right-long"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
<!-- ==== #blog overview section end ==== -->

<!-- ==== latest post section start ==== -->
<section class="latest__post section__space">
<div class="container">
    
</div>
</section>
<!-- ==== #latest section end ==== -->
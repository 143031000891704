<div class="sidebar">
    <a href="javascript:void(0)" class="close__sidebar">
        <i class="fa-solid fa-xmark"></i>
    </a>
    <div class="sidenav__wrapper">
        <ul>
            <li *ngIf="role=='investor'"> 
                <a href="dashboard">
                    <img src="assets/images/icons/dashboard.png" alt="Dashboard" /> Dashboard
                </a>
            </li>
            <li *ngIf="role=='admin' "> 
                <a href="users">
                    <img src="assets/images/icons/users.png" alt="Users" /> Users
                </a>
            </li>
            <li *ngIf="role=='admin' || role=='support'"> 
                <a href="all-properties">
                    <img src="assets/images/icons/properties.png" alt="Properties" /> Properties
                </a>
            </li>
            <li *ngIf="role=='investor'"> 
                <a href="properties">
                    <img src="assets/images/icons/properties.png" alt="Properties" /> Properties
                </a>
            </li>
            <li *ngIf="role=='investor'">
                <a href="investments">
                    <img src="assets/images/icons/investments.png" alt="Investments" />
                    Investments
                </a>
            </li>
            <li>
                <a href="transactions" *ngIf="role=='investor' || role=='admin'">
                    <img src="assets/images/icons/transactions.png" alt="Transactions" />
                    Transactions
                </a>
            </li>
            <li>
                <a href="referrals" *ngIf="role=='channelpartner'">
                    <img src="assets/images/icons/referrals.png" alt="referrals" /> My Referrals
                </a>
            </li>
            <li *ngIf="role=='investor'">
                <a href="kyc" >
                    <img src="assets/images/icons/kyc.png" alt="kyc" /> KYC
                </a>
            </li>
            <li>
                <a href="profile">
                    <img src="assets/images/icons/profile.png" alt="Account" /> My Profile
                </a>
            </li>
        </ul>
        <hr />
        <ul class="logout">
            <li>
                <a (click)="logout()" class="pointer">
                    <img src="assets/images/icons/logout.png" alt="Logout" /> Logout
                </a>
            </li>
        </ul>
    </div>
    <div class="sidenav__wrapper sidenav__footer">
        <h6>Last Login</h6>
        <hr />
        <div class="sidenav__time">
            <p class="secondary"><img src="assets/images/icons/calendar.png" alt="Calendar" />
                {{lastLogin | date: 'medium'}}</p>
        </div>
    </div>
</div>
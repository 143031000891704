import { Component, OnInit, HostListener } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactusComponent {
 
  askForm!: FormGroup;
  isActive: boolean = false;
  private inactivityTimeout: any;


 

  constructor(private fb: FormBuilder,
    private titleService: Title,
    private metaService: Meta
  ) {}

  ngOnInit() {
    this.updateMetaTags();
    this.startInactivityTimer();
    this.askForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      countryCode: ['1', Validators.required],
      phoneNumber: ['', Validators.required],
      subject: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  private updateMetaTags() {
    // Page Title
    const pageTitle = 'Contact Us | Fractional Real Estate Investment Support';
    this.titleService.setTitle(pageTitle);

    // Remove existing meta tags
    this.metaService.removeTag('name="description"');
    this.metaService.removeTag('property="og:title"');
    this.metaService.removeTag('property="og:description"');
    this.metaService.removeTag('property="og:url"');
    
  

    // Update meta tags
    this.metaService.addTags([
      // Basic Meta Tags
      { 
        name: 'description', 
        content: 'Have Questions or Need Support? Contact Our Team to Learn More About Fractional Real Estate Investment Opportunities And How We Can Help You Get Started.' 
      },
      { 
        name: 'keywords', 
        content: 'contact 22 Yards, real estate investment contact, fractional property investment inquiry, investment support' 
      },
      
      // Open Graph Tags
      { 
        property: 'og:title', 
        content: pageTitle 
      },
      { 
        property: 'og:description', 
        content: 'Get in touch with 22 Yards. Contact our team for inquiries about fractional real estate investments, property opportunities, and investment solutions.' 
      },
      { 
        property: 'og:url', 
        content: 'https://22yardsprop.com/contact-us' 
      }
      

      
    ]);

    // Canonical URL
    this.metaService.updateTag({
      rel: 'canonical',
      href: 'https://22yardsprop.com/contact-us'
    });
  }

  onSubmit() {
    if (this.askForm.valid) {
      console.log(this.askForm.value);
      // Handle form submission
    }
  }


  contactOverviewData = [
    {
      id: "$kasd$5",
      icon: 'assets/images/icons/email.png',
      tilte: "Send Us an Email",
      desc: "",
      link: "mailto:info@22yardsprop.com",
      contact: "info@22yardsprop.com",
    },
    {
      id: "$k5d$5",
      icon: 'assets/images/icons/phone.png',
      tilte: "Give Us a Call",
      desc: "",
      link: "tel:+917275252528",
      contact: "+91 7275-2525-28",
    },
    {
      id: "$k63sd$5",
      icon: 'assets/images/location.png',
      tilte: "Address",
      desc: "",
      link: "4A, TSS TowersKavuri Hills Phase 2 Rd, Doctor's Colony, Madhapur, Hyderabad, Telangana 500081",
      contact: "4A, TSS TowersKavuri Hills Phase 2 Rd, Doctor's Colony, Madhapur, Hyderabad, Telangana 500081",
    },

  ];


  @HostListener('document:mousemove', ['$event'])
    onMouseMove(): void {
      this.showIcons();
    }
  
    private showIcons(): void {
      this.isActive = true;
      this.resetInactivityTimer();
    }
  
    private startInactivityTimer(): void {
      this.inactivityTimeout = setTimeout(() => {
        this.isActive = false;
      }, 3000); // Adjust inactivity duration here (in milliseconds)
    }
  
    private resetInactivityTimer(): void {
      clearTimeout(this.inactivityTimeout);
      this.startInactivityTimer();
    }

  faqs = [
    {
      id: "3@24e",
      category: "start",
      title: "What is 22 Yards?",
      details:
        '22 Yards is a fractional real estate investment company that allows individuals to invest in real estate properties without needing to purchase an entire property. The company offers a range of investment opportunities in multi-use properties, including service apartments, co-living spaces, resorts, hotels, and renewable energy projects. By enabling fractional ownership, 22 Yards provides investors with the ability to diversify their portfolios and enjoy steady returns, aligning with the company tagline, "Steady Runs, Solid Returns".',
    },
    {
      id: "3@64e",
      category: "start",
      title: "What are the benefits of investing via 22 Yards?",
      details:
        "Investing in 22 Yards offers several benefits: it allows for fractional ownership of diverse real estate assets, reducing the capital needed to enter the market. Investors gain access to steady returns from multi-use properties, including service apartments, co-living spaces, and renewable energy projects, all while diversifying their investment portfolio",
    },
    {
      id: "3@48e",
      category: "start",
      title: "What makes 22 Yards different?",
      details:
        "22 Yards stands out due to its fractional investment model, which lowers the barrier to entry for real estate investment. The company focuses on a diverse range of assets, including multi-use properties and renewable energy projects, providing investors with both steady returns and portfolio diversification. Additionally, 22 Yards emphasizes maximizing land utility and community value, setting it apart from traditional real estate investment approaches.",
    },
    {
      id: "3@*4e",
      category: "start",
      title: "What happens to my investments if 22 Yards goes into bankruptcy?",
      details:
        "To ensure there’s no risk for investors, 22 Yards has implemented robust financial safeguards and legal protections. In the unlikely event of bankruptcy, investors’ funds are protected through comprehensive insurance and risk management strategies. The company maintains a strong financial structure with clear protocols to safeguard investor capital, ensuring that your investment remains secure and unaffected by the company's financial difficulties.",
    },
    {
      id: "3@4^e",
      category: "start",
      title: "What regulations apply to 22 Yards?",
      details:
        "combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or",
    },
    {
      id: "3@4)e",
      category: "start",
      title: "How do I start investing in 22 Yards?",
      details:
        "combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or",
    },
  ];


}

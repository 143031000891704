import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/services/api.service';
import { HotToastService } from '@ngneat/hot-toast';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  cmsUrl = environment.cms_url;
  featured_properties: any[] = [];
  property_images: any[] = [];
  isImagesLoading: boolean = false;
  images:any[]=[];
  isActive: boolean = false;
  private inactivityTimeout: any;
  blogs:any[]=[];

  constructor(
    private router: Router,
    private apiService: ApiService,
    private toastr: ToastrService,
    private toast: HotToastService,
    private auth: AuthService,
    private modalService: NgbModal,
  ) {
    if (auth.isAuthenticated()) {
      this.auth.redirectedToUserPages();
    }
  }

  responsiveOptions: any[] = [
    {
        breakpoint: '1300px',
        numVisible: 4
    },
    {
        breakpoint: '575px',
        numVisible: 1
    }
  ];

  ngOnInit(): void {
    this.getBlogs();
    this.startInactivityTimer();
    this.getLandingPageImages()
    // Fetch and render properties first
    this.apiService.ListAllProperties().subscribe(response => {
      this.featured_properties = response.slice(0, 3); // Fetch the first 3 properties


      // Set loading state to true for images
      this.isImagesLoading = true;
      // Fetch property images separately
      this.apiService.GetAllPropertyImages(false).subscribe(property_images => {
        this.property_images = property_images; // Fetch all images

        // Map images to the appropriate property
        this.featured_properties = this.mapImagesToProperties(this.featured_properties, this.property_images);

        // Set loading state to false once images are mapped
        this.isImagesLoading = false;
      }, error => {
        console.error('Error fetching property images:', error);
        this.isImagesLoading = false; // Ensure loading state is reset even on error
      });
    });
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(): void {
    this.showIcons();
  }

  private showIcons(): void {
    this.isActive = true;
    this.resetInactivityTimer();
  }

  private startInactivityTimer(): void {
    this.inactivityTimeout = setTimeout(() => {
      this.isActive = false;
    }, 3000); // Adjust inactivity duration here (in milliseconds)
  }

  private resetInactivityTimer(): void {
    clearTimeout(this.inactivityTimeout);
    this.startInactivityTimer();
  }

  getLandingPageImages() {
    this.apiService.getLandingPageData().subscribe(response => {
      this.images = response.data;
    });
  }

  // Function to map images to properties
  mapImagesToProperties(properties: any[], images: any[]): any[] {
    return properties.map(property => {
      // Find the corresponding images object based on property_id
      const matchingImages = images.find(img => img.property_id === property.property_id);
      
      // Return a new object with the images mapped
      return {
        ...property, // Spread the original property to retain its data
        images: matchingImages ? matchingImages.property_images : []
      };
    });
  }

  // Function to get property images by specific name
  getPropertyImages(property: any) {
    return property.images.filter((image: any) => image.name === 'propertyImages');
  }

  redirectToProperty(propertyId: string) {
    this.router.navigate([`/property/${propertyId}`]);
  }

  getBlogs(params:any={}){
    this.apiService.getBlogsData(params).subscribe(response=>{
      this.blogs = response.data
    })
  }


  openModal(content: any): void {
    this.modalService.open(content, { size: 'lg', centered: true });
  }


}

<!-- ==== banner section start ==== -->
<!-- <section class="banner__alt bg__img" data-background="./assets/images/banner/banner-two-bg.png">
    <div class="container">
        <div class="banner__alt__area">
            <h1 class="neutral-top neutral-bottom">Browse Properties</h1>
        </div>
    </div>
</section> -->
<!-- ==== banner section end ==== -->

<!-- ==== property filter start ==== -->
<!-- <div class="property__filter">
    <div class="container">
        <div class="property__filter__area">
            <div class="row d-flex align-items-center">
                <div class="col-lg-12 col-xl-6">
                    <div class="property__search__wrapper">
                        <form action="#" method="post">
                            <div class="input">
                                <input type="search" name="property__search" id="propertySearch"
                                    placeholder="Search for properties" />
                                <i class="fa-solid fa-magnifying-glass"></i>
                            </div>
                            <button type="submit" class="button button--effect">Search</button>
                        </form>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-3">
                    <div class="property__select__wrapper">
                        <select class="location__select">
                            <option data-display="Location">Select Location</option>
                            <option value="angeles">Los Angeles</option>
                            <option value="francis">San Francisco, CA</option>
                            <option value="weldon">The Weldon</option>
                            <option value="diego">San Diego</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-3">
                    <div class="property__select__wrapper">
                        <select class="property__select">
                            <option data-display="Property">Property Type</option>
                            <option value="commercial">Commercial</option>
                            <option value="residential">Residential</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- ==== #property filter end ==== -->
<!-- ==== properties grid section start ==== -->
<div class="fixed-icons" [class.active]="isActive">
    <a href="whatsapp://send?phone=917275252528&text=Welcome to 22Yards!" target="_blank" title="Share on whatsapp"><img src="../../assets/images/whatsapp.png"></a>
    <a href="https://www.instagram.com/22_yards_proptech/" title="Instagram" target="_blank"><img src="../../assets/images/instagram.png"></a>
    <a href="https://www.youtube.com/@22_Yards_Proptech" title="Youtube" target="_blank"><img src="../../assets/images/youtube.png"></a>
    <a href="https://www.facebook.com/people/22Yards/61563374311398/" title="Facebook" target="_blank"><img src="../../assets/images/facebook.png"></a>
    <a [routerLink]="['/contact-us']" title="help" target="_blank"><img src="../../assets/images/help-desk.png"></a>
</div>
<section class="properties__filter section__space__bottom">
    <div class="container wow fadeInUp">
        <div class="properties__filter__wrapper">
            <!-- <a href="javascript:void(0)" ><h6>Back To Dashboard</h6></a> -->

            <h6 *ngIf="properties_list.length===0">Loading Properties</h6>
            <h6 *ngIf="properties_list.length!==0">Showing <span>{{properties_list.length}}</span> properties</h6>
            <div class="grid__wrapper">
                <!-- <select class="grid__select">
                    <option data-display="Sort By">Sort By</option>
                    <option value="grid">Date</option>
                    <option value="list">Price</option>
                </select> -->
                <a href="javascript:void(0)" >Back To Dashboard</a>
                <a href="javascript:void(0)" class="grid__btn grid__view grid__btn__active">
                    <i class="fa-solid fa-grip"></i>
                </a>
                <a href="javascript:void(0)" class="grid__btn grid__list">
                    <i class="fa-solid fa-bars"></i>
                </a>
            </div>
        </div>
        <div class="row property__grid__area__wrapper">
            <div *ngIf="properties_list && properties_list.length === 0" class="no-properties">
                <p>No Properties Found</p>
            </div>

            <div *ngFor="let property of properties_list" class="col-xl-4 col-md-6 property__grid__area__wrapper__inner">
                <div class="property__list__wrapper property__grid">
                    <div class="row d-flex align-items-center">
                        <div class="property__grid__area__wrapper__inner__two">
                            <div class="property__item__image column__space--secondary">
                                <div class="img__effect" *ngIf="!isImagesLoading">
                                    <!-- <p-carousel
                                      [value]="getPropertyImages(property)"
                                      [numVisible]="1" 
                                      [autoplayInterval]="3000"
                                        [numScroll]="1" 
                                        orientation="horizontal" 
                                      styleClass="custom-carousel">
                                      <ng-template pTemplate="item" let-item>
                                        <img [src]="item.url" [alt]="item.name" class="carousel-image" />
                                      </ng-template>
                                    </p-carousel> -->
                                    <ngb-carousel [interval]="5000" [wrap]="true" [keyboard]="true" [pauseOnHover]="false" [showNavigationArrows]="false">
                                        <ng-template ngbSlide *ngFor="let item of getPropertyImages(property)">
                                            <div class="carousel-img-wrapper">
                                                <img [src]="item.url" alt="Random first slide" />
                                            </div> 
                                        </ng-template>
                                    </ngb-carousel>
                                </div>
                            </div>
                        </div>
                        <div class="property__grid__area__wrapper__inner__three">
                            <div class="property__item__content" (click)="routeToDetails(property.property_id)">
                                <div class="item__head">
                                    <div class="item__head__left">
                                        <h4>{{ property.name }}</h4>
                                        <p><i class="fa-solid fa-location-dot"></i> {{ property.location }}</p>
                                    </div>
                                </div>
                                <div class="progress__type progress__type--two">
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" aria-valuenow="100"
                                            aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div class="project__info">
                                        <p class="project__has"><span class="project__has__investors">{{property.total_investors}}
                                                Investors</span> | <span class="project__has__investors__amount"><i
                                                    class="fa-solid fa-inr"></i> {{property.total_investment}}</span> <span
                                                class="project__has__investors__percent">({{property.investor_percentage}}%)</span></p>
                                        <p class="project__goal">
                                            <i class="fa-solid fa-inr"></i> {{ property.value }} Goal
                                        </p>
                                    </div>
                                </div>
                                <div class="item__info">
                                    <div class="item__info__single">
                                        <p>Annual Return</p>
                                        <h6>{{ property.ROI_min }}% -{{ property.ROI_max }}%</h6>
                                    </div>
                                    <div class="item__info__single">
                                        <p>Maximum Term</p>
                                        <h6>{{ property.tenure }} Months</h6>
                                    </div>
                                    <div class="item__info__single">
                                        <p>Property Type</p>
                                        <h6>{{ property.type }}</h6>
                                    </div>
                                    <div class="item__info__single">
                                        <p>Target IRR</p>
                                        <h6>{{ property.IRR_min }}% - {{ property.IRR_max }}%</h6>
                                    </div>
                                </div>
                                <div class="item__footer">
                                    <div class="item__cta__group">
                                        <a href="registration.html" class="button button--effect">Invest Now</a>
                                        <a href="property/{{property.property_id}}"
                                            class="button button--secondary button--effect">Details</a>
                                    </div>
                                </div>
                                <div class="invest__cta__wrapper">
                                    <div class="invest__cta">
                                        <a href="javascript:void(0)" (click)="onInvestNowClick($event, property)" class="button button--effect pointer">
                                            Invest Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- <div class="cta__btn">
            <a href="property-details.html" class="button button--effect">Load More</a>
        </div> -->
    </div>
</section>
<!-- ==== properties grid section end ==== -->

<!-- Investment Modal -->
<ng-template #investModal let-modal>
    <div class="modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="investModalLabel">Invest in Property - {{selectedProperty.name}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
        </div>
        <div class="modal-body">
          <!-- Form for number of shares -->
          <form (submit)="onSubmitInvestmentForm($event)">
            <div class="mb-3">
              <label for="numShares" class="form-label">Number of Shares</label>
              <input type="number" class="form-control" id="numShares" [(ngModel)]="numShares" (change)="onSharesChange()" name="numShares" required>
             <p class="error-text">** Maximum shares you can buy {{remainingShares}}</p>
            </div>
            <div class="mb-3">
              <label for="investmentValue" class="form-label">Investment Value</label>
              <input type="text" class="form-control" id="investmentValue" [value]="investmentValue" disabled>
            </div>
            <button type="submit" class="btn btn-primary" [disabled]="numShares <= 0">Confirm Investment</button>
          </form>
        </div>
      </div>
    </div>
  </ng-template>
  